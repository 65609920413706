import { TravelerForm } from '@travelity/web/src/components/booking-participants/booking-participants.types';
import {
    BookingStatus,
    type PaymentRequirementsData,
    type DiscountData,
    type FlatPricingData,
    type AgebandPricingData,
} from '../../requests';
import {
    Activity,
    Period,
    SourceType,
    Currency,
    PricingType,
    PaxData,
} from '../common.types';
import { ProductType, ProductOptionType } from '../product/product.types';
import { Customer } from '../customer/customer.types';

// re-exporting enums
export { BookingStatus };

export interface BookingProductPricing {
    id: string;
    type: PricingType;
    currency: Currency;
    cost?: FlatPricingData | AgebandPricingData;
    price: FlatPricingData | AgebandPricingData;
    paymentRequirements: PaymentRequirementsData;
    discount?: DiscountData;
}

export interface BookingProduct {
    id: string;
    type: ProductType;
    name: string;
    pricing: BookingProductPricing;
    options?: BookingProductOption[];
}

export interface BookingParticipants {
    pax: PaxData;
    customer: Customer;
    travelers?: TravelerForm[];
}

export interface BookingNote {
    id?: string;
    content: string;
}

export interface BookingProductOption {
    id: string;
    pax: PaxData;
    pricing?: BookingProductPricing;
    name: string;
    type: ProductOptionType;
}

export interface BookingFinancials {
    debt: number;
    cost: number;
    subtotal: number;
    total: number;
    paid: number;
    refunded: number;
    waived: number;
}

export interface Booking {
    id: string;
    status: BookingStatus;
    lifecycle: Activity[];
    reason?: string;
    notes: string[];
    orderId: string;
    orderNumber: string;
    eventId?: string;
    date: Period;

    transactions: Transaction[];
    source: {
        type: SourceType;
        name: string;
    };
    product: BookingProduct;
    financials: BookingFinancials;
    participants: BookingParticipants;
    permissions: {
        canEdit: boolean;
        canDelete: boolean;
    };
}

export interface Transaction {
    id: string;
    type: string;
    amount: number;
    method: string;
    createdDate: Date;
    discarded?: Activity;
}

export enum PaymentStatus {
    PAID = 'paid',
    NOT_PAID = 'not_paid',
}
