import { OrderItem, Price, PriceSummary } from './order.types';
import {
    type AgebandPricingData,
    ComputePriceBookingsResFinancialsPriceMainPrice1Dto,
    FlatPricingData,
    GetBookingsResItemDto,
    GetOrdersResItemDto,
    PaxData,
    PricingType,
    UpdateOrderReqDto,
} from '../../requests';
import { getBookingDtoToBooking } from '../booking/booking.converters';
import { convertNameDtoToFullName } from '../user/user.converters';
import { AgeBands } from '../common.types';
import { convertActivityDtoToActivity } from '../common.converters';
import { PaymentStatus } from '../booking/booking.types';

export const priceDtoToPrice = (
    price: FlatPricingData | AgebandPricingData,
    pax: PaxData
): Price => {
    if (price.type === FlatPricingData.type.PER_PRODUCT) {
        return {
            type: PricingType.PER_PRODUCT,
            count: 1,
            base: price.base_amount,
            unitPrice: price.unit_amount,
        };
    }

    if (price.type === FlatPricingData.type.PER_PERSON) {
        return {
            type: PricingType.PER_PERSON,
            count: Object.values(pax).reduce(
                (sum, ageBand) => sum + ageBand,
                0
            ),
            base: price.base_amount,
            unitPrice: price.unit_amount,
        };
    }

    const { base_amount: base, ...other } =
        price as ComputePriceBookingsResFinancialsPriceMainPrice1Dto;
    return {
        type: PricingType.PER_AGEBAND,
        base,
        ...(Object.keys(other) as (keyof typeof other)[]).reduce(
            (obj, ageBand) =>
                other[ageBand]
                    ? {
                          ...obj,
                          [ageBand]: {
                              count: (pax[ageBand as AgeBands] || 0) as number,
                              unitPrice: other[ageBand],
                          },
                      }
                    : obj,
            {}
        ),
    };
};

export const priceDtoToPriceSummary = (
    price: FlatPricingData | AgebandPricingData
): PriceSummary => {
    const { base_amount: base, ...other } = price.main_price;

    return {
        beforeDiscount: 0, // price.price_before_reduction,
        final: 0, // price.price_after_reduction,
        options: price.option_prices
            ?.filter(o => o.price)
            .map(option => ({
                name: option.name || '',
                type: option.type || '',
                price: priceDtoToPrice(option.price as PriceDto),
            })),
        // discount: price.discount
        //     ? {
        //           amount: price.discount.amount,
        //           type: price.discount.type,
        //       }
        //     : undefined,
        main: priceDtoToPrice(price.main_price),
    };
};

export const patchOrderToOrderDto = (
    order: Partial<OrderItem>
): UpdateOrderReqDto => ({
    financials: order.financials,
});

export const getOrderItemDtoToOrder = (
    order: GetOrdersResItemDto
): OrderItem => ({
    id: order.id,
    number: order.number,
    participants: {
        customer: {
            id: order.customer.id,
            fullName: order.customer.name
                ? convertNameDtoToFullName(order.customer.name)
                : '',
        },
    },
    permissions: {
        canEdit: !!order.access.permissions?.update,
        canDelete: !!order.access.permissions?.delete,
    },
    financials: order.price
        ? {
              status: order.bookings?.some(b => b.financials?.debt.original)
                  ? PaymentStatus.NOT_PAID
                  : PaymentStatus.PAID,
              total: order.price.total || 0,
              subtotal: order.price.subtotal || 0,
              discount: order.price.discount,
          }
        : undefined,
    status: order.status,
    bookingsCount: order.summary?.counts.bookings || 0,
    productsCount: order.summary?.counts.products || 0,
    travelersCount: order.summary?.counts.customers || 0,
    reason: order.cancelled?.message,
    bookings:
        order.bookings?.map(v =>
            getBookingDtoToBooking(v as GetBookingsResItemDto)
        ) || [],
    lifecycle: order.lifecycle?.map(convertActivityDtoToActivity) || [],
});

export const orderFilterToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    customerName: filters.customerName,
    orderStatus: '',
});
