import React from 'react';
import { xorBy } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ScheduleItem, useSchedulesLazy } from '@travelity/api';

import { Button, LoadingOverlay } from '@travelity/ui';
import ScheduleItemMinimal from './components/schedule-item-minimal';

export interface ScheduleModalProps {
    handleCancel: () => void;
    handleConfirm: (v: ScheduleItem[]) => void;
    open: boolean;
    isLoading?: boolean;
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({
    handleCancel,
    handleConfirm,
    open,
    isLoading,
}) => {
    const [selected, setSelected] = React.useState<ScheduleItem[]>([]);

    const { data: schedules, isFetching } = useSchedulesLazy();

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', width: '482px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                <Typography sx={{ fontWeight: 600 }}>
                    {schedules && schedules.length === 0
                        ? 'No schedules found'
                        : 'Select schedules'}
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                }}
            >
                <Box
                    sx={{
                        pr: 2,
                        height: 'calc(100% - 76px)',
                        '& > div': {
                            maxHeight: 'calc(100vh - 284px)',
                        },
                    }}
                >
                    <PerfectScrollbar>
                        <Stack
                            sx={{
                                pr: 0.5,
                                pb: 1,
                            }}
                            gap={1}
                        >
                            {schedules?.map(schedule => (
                                <ScheduleItemMinimal
                                    key={schedule.scheduleId}
                                    schedule={schedule}
                                    isSelected={
                                        !!selected.find(
                                            s =>
                                                s.scheduleId ===
                                                schedule.scheduleId
                                        )
                                    }
                                    onSelect={() =>
                                        setSelected(
                                            xorBy(selected, [schedule], 'id')
                                        )
                                    }
                                />
                            ))}
                        </Stack>
                    </PerfectScrollbar>
                </Box>
                {(isFetching || isLoading) && (
                    <LoadingOverlay
                        title="Please wait..."
                        subTitle="It’ll just take a moment."
                        sx={{
                            bgcolor: 'rgba(255, 255, 255, 1)',
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2, justifyContent: 'end' }}>
                <Stack direction="row" gap={1}>
                    <Button
                        color="neutral"
                        variant="contained"
                        onClick={handleCancel}
                        sx={{
                            px: '26px',
                            py: '10px',
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isFetching || isLoading || !selected.length}
                        onClick={() => {
                            handleConfirm(selected);
                        }}
                        sx={{
                            py: '10px',
                            px: 4,
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleModal;
