import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useIsChanged } from '@travelity/web/src/hooks';
import { formatValue } from '@travelity/form';
import {
    OrdersService,
    ComputePriceBookingsReqDto,
    DiscountType,
    GetOrderResDto,
    type GetOrderResBookingsItem0Dto,
    type GetOrderResBookingsItem1Dto,
} from '../../requests';
import { useComputePriceBookings } from '../../queries';
import {
    getOrderItemDtoToOrder,
    priceDtoToPriceSummary,
} from './order.converters';
import { PriceSummary } from './order.types';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
} from '../common.types';

export enum RequestTypes {
    EVENT = 'event',
    ORDER = 'order',
}

export const useCancelOrder = (
    options?: CustomMutationOptions<
        { orderId: string; reason?: string },
        ReturnType<typeof OrdersService.cancelOrders>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ orderId, reason }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.cancelOrders(orderId, authorization, {
                message: reason || '',
            });
        },
        ...options,
        onSuccess: (...args) => {
            // TODO update order in cache
            options?.onSuccess?.(...args);
        },
    });
};

// export const useDebouncedPrice = (
//     params: Partial<ComputePriceBookingsReqDto>,
//     debounce: number = 1000
// ) => {
//     const [state, setState] = useState<{
//         data?: PriceSummary;
//         rawData?: ComputePriceBookingsResFinancialsPriceDto;
//         isLoading: boolean;
//     }>({ isLoading: true });
//     const { mutate: getPrices } = useComputePriceBookings({
//         onSuccess: data => {
//             setState({
//                 data: priceDtoToPriceSummary(data.financials.price),
//                 rawData: data.financials.price,
//                 isLoading: false,
//             });
//         },
//     });
//
//     React.useEffect(() => {
//         setState({ isLoading: true });
//         const handler = setTimeout(() => {
//             if (params.participants?.pax && params.product) {
//                 getPrices({
//                     requestBody: params as ComputePriceBookingsReqDto,
//                 });
//             }
//         }, debounce);
//
//         return () => {
//             clearTimeout(handler);
//         };
//     }, [params, debounce]);
//
//     return {
//         data: state.data,
//         rawData: state.rawData,
//         isLoading: state.isLoading,
//     };
// };

export const useUpdatePrice = (
    params: any, // Partial<ComputePriceBookingsReqDto>,
    initialPrice?: any
) => {
    const [state, setState] = useState<{
        data?: PriceSummary;
        rawData?: any;
        isLoading: boolean;
        discount: number;
        discountType: DiscountType;
        options: string[];
    }>({
        isLoading: true,
        discount: 0,
        discountType: DiscountType.RELATIVE,
        options: [],
    });
    const { mutate: getPrices } = useComputePriceBookings({
        onSuccess: data => {
            setState(prevData => ({
                ...prevData,
                data: priceDtoToPriceSummary(data.financials.price),
                rawData: data.financials.price,
                isLoading: false,
            }));
        },
    });

    const needsUpdate = useMemo(() => {
        const paramsDiscount = params.financials?.price?.discount?.amount || 0;
        const paramsDiscountType =
            params.financials?.price?.discount?.type || DiscountType.RELATIVE;
        const paramsOptions =
            params.product?.options?.map(
                o =>
                    `${o.name}:${
                        o.participants?.pax
                            ? formatValue(o.participants?.pax)
                            : ''
                    }`
            ) || [];

        return (
            paramsDiscountType !== state.discountType ||
            paramsDiscount !== state.discount ||
            paramsOptions.join(',') !== state.options.join(',')
        );
    }, [state.discount, state.discountType, state.options, params]);

    const initialPriceChanged = useIsChanged(initialPrice);
    const paramsChanged = useIsChanged(params);
    React.useEffect(() => {
        if (initialPriceChanged && initialPrice) {
            setState({
                data: priceDtoToPriceSummary(initialPrice),
                rawData: initialPrice,
                isLoading: false,
                discount: 0,
                discountType: DiscountType.RELATIVE,
                options: [],
            });
        }
    }, [params, initialPrice, initialPriceChanged, paramsChanged]);

    const update = useCallback(() => {
        if (params.participants?.pax && params.product) {
            setState({
                isLoading: true,
                discount: params.financials?.price?.discount?.amount || 0,
                discountType:
                    params.financials?.price?.discount?.type ||
                    DiscountType.RELATIVE,
                options:
                    params.product.options?.map(
                        o =>
                            `${o.name}:${
                                o.participants?.pax
                                    ? formatValue(o.participants?.pax)
                                    : ''
                            }`
                    ) || [],
            });
            getPrices({
                requestBody: params as ComputePriceBookingsReqDto,
            });
        }
    }, [params, getPrices]);

    return {
        data: state.data,
        rawData: state.rawData,
        isLoading: state.isLoading,
        changed: needsUpdate,
        update,
    };
};

interface UseOrdersData {
    pageNumber?: number;
    pageSize?: number;
}

const useOrdersKey = 'useOrdersKey';
export const useOrders = (
    params: UseOrdersData = {},
    options: CustomQueryOptions<ReturnType<typeof OrdersService.getOrders>> = {}
) => {
    const newParams = { ...params, pageSize: 1000 };
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useOrdersKey, newParams],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                newParams.pageNumber,
                newParams.pageSize || 1000
            );
        },
        ...options,
    });

    const parsedData = useMemo(
        () => data?.items?.map(getOrderItemDtoToOrder),
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useOrdersLazyKey = 'useOrdersLazyKey';
export const useOrdersLazy = (
    params: UseOrdersData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof OrdersService.getOrders>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 20;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useOrdersLazyKey, params],
        queryFn: async ({ pageParam = 0 }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(authorization, pageParam, pageSize);
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage.items || lastPage.items.length < pageSize)
                return undefined;
            return allPages.length;
        },
        ...options,
    });

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getOrderItemDtoToOrder)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useOrderKey = 'useOrderKey';
export const useOrder = (
    id?: string,
    options: CustomQueryOptions<ReturnType<typeof OrdersService.getOrder>> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useOrderKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrder(id as string, authorization);
        },
        enabled: !!id,
        ...options,
    });

    return {
        data: useMemo(
            () => (data ? getOrderItemDtoToOrder(data) : data),
            [data]
        ),
        ...other,
    };
};

export const useUpdateOrderBooking = () => {
    const queryClient = useQueryClient();
    const queriesData = queryClient.getQueriesData({
        queryKey: ['OrdersServiceGetOrder'],
        exact: false,
    });

    const update = (
        bookingId: string,
        callback: (
            b: GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto
        ) => GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto
    ) => {
        queriesData.forEach(([queryKey]) => {
            queryClient.setQueryData<GetOrderResDto>(
                queryKey,
                // @ts-ignore
                order => {
                    if (!order) return undefined;
                    const bookings =
                        order.bookings?.map(b =>
                            b.id === bookingId ? callback(b) : b
                        ) || [];
                    return {
                        ...order,
                        bookings,
                    };
                }
            );
        });
    };

    return update;
};

export const useExport = (
    options?: CustomMutationOptions<
        {
            id: string;
        },
        ReturnType<typeof OrdersService.exportDetailsOrders>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        async ({ id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.exportDetailsOrders(id, authorization);
        },
        {
            onSuccess: data => {
                window.open(data.url, '_blank')?.focus();
            },
            ...options,
        }
    );
};
