import React, { useCallback } from 'react';
import { Box, Divider, FormLabel, Stack } from '@mui/material';
import { TextField, DurationField, CardList, Select } from '@travelity/form';
import { array, date, number, object, string } from 'yup';
import {
    ScheduleItem,
    RecurrenceTypes,
    Schedule,
    ScheduleOptionTypes,
    scheduleItemToSchedule,
    scheduleDtoToScheduleItem,
    useCreateSchedule,
} from '@travelity/api';
import { useFormContext } from 'react-hook-form';
import { ScheduleCardContent } from './components/schedule-card';
import { ScheduleModal } from '../schedule-modal';

export interface ProductScheduleFormProps {
    preview?: boolean;
    showFull?: boolean;
    showErrors?: boolean;
}

const swingOptions = [
    {
        label: 'No overtime',
        value: '',
    },
    {
        label: '30 minutes',
        value: '0.5',
    },
    {
        label: '1 hour',
        value: '1',
    },
    {
        label: '1 hour 30 minutes',
        value: '1.5',
    },
    {
        label: '2 hours',
        value: '2',
    },
    {
        label: '2 hours 30 minutes',
        value: '2.5',
    },
    {
        label: '3 hours',
        value: '3',
    },
    {
        label: '3 hours 30 minutes',
        value: '3.5',
    },
    {
        label: '4 hours',
        value: '4',
    },
    {
        label: '4 hours 30 minutes',
        value: '4.5',
    },
    {
        label: '5 hours',
        value: '5',
    },
    {
        label: '5 hours 30 minutes',
        value: '5.5',
    },
    {
        label: '6 hours',
        value: '6',
    },
    {
        label: '6 hours 30 minutes',
        value: '6.5',
    },
    {
        label: '7 hours',
        value: '7',
    },
    {
        label: '7 hours 30 minutes',
        value: '7.5',
    },
    {
        label: '8 hours',
        value: '8',
    },
    {
        label: '8 hours 30 minutes',
        value: '8.5',
    },
    {
        label: '9 hours',
        value: '9',
    },
    {
        label: '9 hours 30 minutes',
        value: '9.5',
    },
    {
        label: '10 hours',
        value: '10',
    },
    {
        label: '10 hours 30 minutes',
        value: '10.5',
    },
    {
        label: '11 hours',
        value: '11',
    },
    {
        label: '11 hours 30 minutes',
        value: '11.5',
    },
    {
        label: '12 hours',
        value: '12',
    },
];

const schema = object<Schedule>({
    name: string().required('Name is a required field').uniqName(),
    times: array().when('allDay', {
        is: true,
        otherwise: sch =>
            sch.of(number()).required().min(1, 'No start time is selected'),
    }),
}).when(['.type'], ([type], sch) => {
    if (type === ScheduleOptionTypes.SEASON) {
        return sch.shape({
            startDate: date().required('Start date is a required field'),
            endDate: date().required('End date is a required field'),
            weekDays: array().when('recurrenceType', {
                is: RecurrenceTypes.WEEKLY,
                then: s => s.of(string()).min(1, 'No days selected'),
            }),
            monthDays: array().when('recurrenceType', {
                is: RecurrenceTypes.MONTHLY,
                then: s => s.of(date()).min(1, 'No days selected'),
            }),
            dates: array().when('recurrenceType', {
                is: RecurrenceTypes.YEARLY,
                then: s => s.of(date()).min(1, 'No dates selected'),
            }),
        });
    }
    return sch.shape({
        startDates: array()
            .of(date())
            .required('Dates field is required')
            .min(1, 'No dates selected'),
    });
});

const ProductScheduleForm: React.FC<ProductScheduleFormProps> = ({
    preview,
    showFull,
    showErrors,
}) => {
    const { watch, setValue, trigger } = useFormContext();

    const [showBrowse, setShowBrowse] = React.useState(false);
    const browse = useCallback(() => setShowBrowse(true), []);

    const addSchedules = useCallback(
        (items: ScheduleItem[]) => {
            setValue('items', [...watch('items'), ...items]);
            trigger('items');
            setShowBrowse(false);
        },
        [watch, setValue]
    );

    const { mutateAsync: createSchedule } = useCreateSchedule();

    const onScheduleAdd = useCallback(async (schedule: Record<string, any>) => {
        const { items: [newSchedule] = [] } = await createSchedule(
            scheduleItemToSchedule(schedule as ScheduleItem)
        );
        return scheduleDtoToScheduleItem(newSchedule);
    }, []);

    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Schedule Settings
            </Divider>
            <DurationField
                name="duration"
                showErrors={showErrors}
                label="Select event duration"
                InputProps={{
                    disabled: preview,
                    sx: {
                        '&& .unit': { pr: '56px' },
                        '&& input': { pl: '12px' },
                    },
                }}
            />
            <FormLabel>Select potential overtime</FormLabel>
            <Select
                name="swing"
                label=""
                options={swingOptions}
                disabled={preview}
                formControlSx={{ mt: 1 }}
                size="small"
            />

            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Schedule Items
            </Divider>
            <CardList
                disabled={preview}
                showErrors={showErrors}
                maxHeight={showFull ? undefined : 500}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <TextField
                            sx={{ mx: '10px' }}
                            autoFocus
                            InputProps={{
                                sx: { fontSize: '14px', pl: 1.5 },
                            }}
                            placeholder="Type name"
                            name="name"
                            variant="standard"
                            showErrors
                            helperText=""
                            fullWidth
                        />
                    ) : (
                        <Box
                            component="span"
                            title={item.name}
                            sx={{
                                overflowX: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                lineHeight: 2,
                            }}
                        >
                            {item.name}
                        </Box>
                    )
                }
                renderContent={({ item, editing }) => (
                    <ScheduleCardContent item={item} editing={editing} />
                )}
                addButtonText="Add Schedule Item"
                onItemSave={onScheduleAdd}
                itemOptions={[
                    { label: 'Season', type: ScheduleOptionTypes.SEASON },
                    {
                        label: 'Custom dates',
                        type: ScheduleOptionTypes.CUSTOM,
                    },
                    {
                        label: 'Browse',
                        onClick: browse,
                    },
                ]}
                formParams={{ schema }}
                disableItemEdit={() => true}
                name="items"
            />
            {showBrowse && (
                <ScheduleModal
                    handleCancel={() => setShowBrowse(false)}
                    handleConfirm={addSchedules}
                    open={showBrowse}
                />
            )}
        </Stack>
    );
};

export default React.memo(ProductScheduleForm);
