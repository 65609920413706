import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useForm, WeekdayPicker, Weekdays } from '@travelity/form';
import { Button } from '@travelity/ui';
import { object } from 'yup';

import { useProject } from '@travelity/api';
import WeekdayRow from '../components/weekday-row';

const schema = object().shape({
    // weekdays: array().of(string()).min(1, 'At least 1 day required').required(),
});

export interface WorkweekStepProps {
    weekdays?: Weekdays[];
    weekdayHours?: Partial<{
        [v in Weekdays]: {
            start: number;
            end: number;
        }[];
    }>;
    back: () => void;
    next: (
        weekdays: Weekdays[],
        weekdayHours: Partial<{
            [v in Weekdays]: {
                start: number;
                end: number;
            }[];
        }>
    ) => void;
}

export const WorkweekStep: React.FC<WorkweekStepProps> = props => {
    const { weekdays, weekdayHours, next, back } = props;

    const [showError, setShowErrors] = React.useState(false);

    const { Form, watch, setValue, getValues } = useForm({
        schema,
        onSubmit: useCallback(
            (data: {
                weekdays: Weekdays[];
                weekdayHours: Partial<{
                    [v in Weekdays]: {
                        start: number;
                        end: number;
                    }[];
                }>;
            }) => {
                next(data.weekdays, data.weekdayHours);
            },
            [next]
        ),
        defaultValues: { weekdays, weekdayHours },
        mode: 'onChange',
        validateInitially: true,
    });

    const { data: project } = useProject();
    const disableWeekdays =
        project?.user?.calendar &&
        !project.user.calendar?.workweek?.customizable;

    const weekdaysValue = useMemo(
        () =>
            disableWeekdays
                ? [
                      Weekdays.MONDAY,
                      Weekdays.TUESDAY,
                      Weekdays.WEDNESDAY,
                      Weekdays.THURSDAY,
                      Weekdays.FRIDAY,
                      Weekdays.SATURDAY,
                      Weekdays.SUNDAY,
                  ]
                : watch('weekdays'),
        [disableWeekdays]
    );

    const weekdayHoursValue = watch('weekdayHours');
    useEffect(() => {
        if (weekdaysValue) {
            const values = getValues();
            const currentWeekdayHours = values.weekdayHours;
            const weekdayHoursNew: Partial<{
                [v in Weekdays]: { start: number; end: number }[];
            }> = {};

            weekdaysValue.forEach(w => {
                weekdayHoursNew[w] = currentWeekdayHours?.[w] || [
                    {
                        start: 0,
                        end: 1439,
                    },
                ];
            });

            setValue('weekdayHours', weekdayHoursNew);
        }
    }, [weekdaysValue]);

    return (
        <Form>
            <Stack
                sx={{
                    py: 5,
                    height: 1,
                    minHeight: 'calc(100vh - 84px)',
                }}
                gap={2}
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    sx={{
                        color: '#C0C4CE',
                        fontSize: '24px',
                    }}
                >
                    Setup Your Workweek
                </Typography>

                <Box
                    sx={{
                        width: '560px',
                        ...(disableWeekdays && {
                            pointerEvents: 'none',
                            opacity: 0.8,
                        }),
                    }}
                >
                    <WeekdayPicker
                        name="weekdays"
                        chipSx={{
                            py: '12px',
                            px: '16px',
                            borderRadius: '26px',
                            background: '#F4F6FA',
                            border: '1px solid transparent',
                            '&.MuiChip-colorPrimary': {
                                border: '1px solid #55B5CF',
                                background: '#DDF0F5',
                                color: '#2B395B',
                            },
                        }}
                        showErrors={showError}
                    />
                    <Divider sx={{ mx: 10, my: 3 }} />
                    {weekdayHoursValue &&
                        Object.keys(weekdayHoursValue).map(w => (
                            <WeekdayRow
                                key={w}
                                name={`weekdayHours.${w}`}
                                weekday={w as Weekdays}
                            />
                        ))}
                </Box>
                <Stack direction="row" spacing={1} sx={{ width: '388px' }}>
                    <Button
                        variant="contained"
                        color="neutral"
                        sx={{ flexGrow: 1 }}
                        onClick={back}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                        }}
                        doNotDisable
                        onIconClick={() => {
                            setShowErrors(true);
                        }}
                        requiredFields={
                            !weekdaysValue?.length ? ['Weekdays'] : undefined
                        }
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
