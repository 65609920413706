import { PaymentStatus } from '@travelity/api';
import React from 'react';
import { StatusTag, StatusTagStatuses } from '@travelity/ui';
import { useTranslation } from 'react-i18next';

export interface PaymentStatusProps {
    status: PaymentStatus;
}

const statusMap: Record<PaymentStatus, StatusTagStatuses> = {
    [PaymentStatus.NOT_PAID]: StatusTagStatuses.ERROR,
    [PaymentStatus.PAID]: StatusTagStatuses.SUCCESS,
};

export const PaymentStatusTag: React.FC<PaymentStatusProps> = props => {
    const { t } = useTranslation('order');
    const { status } = props;
    return (
        <StatusTag status={statusMap[status]} value={t(status).toUpperCase()} />
    );
};
