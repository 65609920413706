import React from 'react';
import { Chip } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { format } from 'date-fns';
import { Period } from '@travelity/api';

export interface BookingDateProps {
    date: Period;
}

export const BookingDate: React.FC<BookingDateProps> = props => {
    const { date } = props;

    return date.end ? (
        <Chip
            sx={{
                color: '#6B748C',
                bgcolor: '#FFF',
                borderRadius: '8px',
                px: 0.5,
                py: 0.5,
                boxShadow: '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                '& .MuiChip-label': {
                    pl: 1.5,
                    pr: 0.5,
                    lineHeight: '18px',
                },
            }}
            icon={
                <AccessTimeFilledIcon
                    sx={{ fontSize: '14px', color: '#6B748C' }}
                />
            }
            label={`Starts on ${format(date.start, 'MMM d')} at ${format(
                date.start,
                'HH:mm'
            )}`}
        />
    ) : null;
};
