import React, { useState } from 'react';
import { Box, Chip, Collapse, Divider, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    convertNameDtoToFullName,
    ProductType,
    Team,
    useRemoveTeam,
} from '@travelity/api';
import { Card, IconButton, Heading, IconButtonGroup, Tag } from '@travelity/ui';

import ExpandIcon from '@mui/icons-material/Expand';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import PersonIcon from '@mui/icons-material/Person';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { countBy, toPairs } from 'lodash';
import { useExpand } from '../../hooks';
import { ReasonModal } from '../booking-item/components/reason-modal';
import { TeamMemberItem } from '../team-member-item';
import { TeamModal } from '../team-modal';

export interface TeamItemProps {
    team: Team;
    isSelected: boolean;
    refetch: () => void;
}

const TeamItem: React.FC<TeamItemProps> = ({ team, isSelected, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [deletingTeam, setDeletingTeam] = useState<string>();
    const [expanded, toggleExpand] = useExpand<boolean>();
    const { mutate: removeTeam, isLoading: isRemoveTeamLoading } =
        useRemoveTeam({
            onSuccess: () => {
                setDeletingTeam(undefined);
                refetch();
                enqueueSnackbar(`Removed the team "${team.name}"`, {
                    variant: 'success',
                });
            },
            onError: () => {
                enqueueSnackbar(`Failed to remove the team "${team.name}"`, {
                    variant: 'error',
                });
            },
        });

    const [inviteMember, setInviteMember] = useState(false);
    // TODO Shared products new api
    // TODO remove member throws error
    // TODO permissions of teams
    // Manager redesign add info badge row

    const owner = convertNameDtoToFullName(team.createdBy);
    const occupations = toPairs(
        countBy(
            team.members.filter(({ occupation }) => occupation),
            'occupation'
        )
    );

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 1,
                    pt: 1,
                    mb: 1,
                    pr: 5,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        {team.name}
                    </Heading>
                </Stack>
                <Stack direction="row" gap={1}>
                    <Chip
                        sx={{
                            color: '#6B748C',
                            bgcolor: '#FFF',
                            borderRadius: '8px',
                            px: 0.5,
                            py: 0.5,
                            boxShadow:
                                '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                            '& .MuiChip-label': {
                                pl: 1.5,
                                pr: 0.5,
                                lineHeight: '18px',
                            },
                        }}
                        icon={
                            <PersonIcon
                                sx={{ fontSize: '14px', color: '#6B748C' }}
                            />
                        }
                        label={`Owner: ${owner}`}
                    />
                </Stack>
            </Stack>
            <Card
                isSelected={isSelected}
                sx={{
                    zIndex: 3,
                }}
                parentProps={{ alignItems: 'stretch' }}
                buttons={
                    <>
                        <IconButtonGroup>
                            <IconButton
                                onClick={() => setInviteMember(true)}
                                icon={<AddBoxIcon fontSize="small" />}
                                tooltip="Invite"
                                disabled={!team.permissions.update}
                            />
                            <Divider sx={{ mx: 0.75 }} />
                            <IconButton
                                hoverColor="error.main"
                                onClick={() => setDeletingTeam(team.id)}
                                disabled={!team.permissions.delete}
                                icon={<DeleteIcon fontSize="small" />}
                                tooltip="Delete"
                            />
                        </IconButtonGroup>
                        <IconButtonGroup>
                            <IconButton
                                onClick={() => toggleExpand(true)}
                                icon={<ExpandIcon fontSize="small" />}
                                tooltip={expanded ? 'Collapse' : 'Expand'}
                            />
                        </IconButtonGroup>
                    </>
                }
            >
                <Stack
                    sx={{
                        py: '10px',
                    }}
                    gap="10px"
                >
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{
                            px: 2,
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography sx={{ color: '#B7BCC7', fontSize: '12px' }}>
                            Info badges
                        </Typography>
                        <Tag
                            label="Members"
                            values={[`${team.members.length}`]}
                            valueProps={{
                                sx: {
                                    lineHeight: '12px',
                                    color: '#9198AA',
                                    border: '1px solid rgba(145, 152, 170, 0.40)',
                                    bgcolor: 'transparent',
                                },
                            }}
                        />
                    </Stack>
                    <Divider />
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{
                            px: 2,
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography sx={{ color: '#B7BCC7', fontSize: '12px' }}>
                            Shared products
                        </Typography>
                        <Stack direction="row" gap={1} alignItems="center">
                            {Object.values(ProductType).map(
                                type =>
                                    !!team?.productTypes?.[type] && (
                                        <Tag
                                            label={t(type, { ns: 'product' })}
                                            values={[
                                                team.productTypes[
                                                    type
                                                ] as number,
                                            ]}
                                        />
                                    )
                            )}
                        </Stack>
                    </Stack>

                    {!!occupations.length && (
                        <>
                            <Divider />
                            <Stack
                                direction="row"
                                gap={1}
                                sx={{
                                    px: 2,
                                }}
                                alignItems="center"
                            >
                                {occupations.map(([occupation, count]) => (
                                    <Tag
                                        label={t(occupation, { ns: 'common' })}
                                        values={[count]}
                                        valueProps={{
                                            sx: {
                                                lineHeight: '12px',
                                                color: '#9198AA',
                                                border: '1px solid rgba(145, 152, 170, 0.40)',
                                                bgcolor: 'transparent',
                                            },
                                        }}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Stack>
            </Card>
            <Collapse in={!!expanded}>
                <Stack gap={1} sx={{ mt: 1 }}>
                    {team.members.map(member => (
                        <TeamMemberItem
                            member={member}
                            disableRemove={!team.permissions.update}
                            // isSelected={member.id === memberId}
                            teamId={team.id}
                        />
                    ))}
                </Stack>
            </Collapse>
            {!expanded &&
                !!team.members.length &&
                team.members.slice(0, 3).map((_, i) => {
                    return (
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                width: 1,
                                alignItems: 'flex-start',
                            }}
                        >
                            <Stack flexGrow={2}>
                                <Box
                                    sx={{
                                        mx: i * 2 + 2,
                                        boxShadow:
                                            '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                                        height: '8px',
                                        borderRadius: '0px 0px 12px 12px',
                                    }}
                                />
                            </Stack>

                            <Stack
                                flexShrink={0}
                                direction="column"
                                gap={1}
                                sx={{
                                    width: '34px',
                                }}
                            />
                        </Stack>
                    );
                })}

            <ReasonModal
                title="Are you sure you want to remove this team?"
                content="Provide the reason for removing this team"
                confirmText="Confirm"
                open={!!deletingTeam}
                isLoading={isRemoveTeamLoading}
                handleCancel={() => setDeletingTeam(undefined)}
                handleConfirm={reason => {
                    removeTeam({
                        teamId: deletingTeam as string,
                        reason,
                    });
                }}
            />

            {inviteMember && (
                <TeamModal
                    open
                    handleCancel={() => {
                        setInviteMember(false);
                    }}
                    team={{
                        name: team.name,
                        id: team.id,
                        isolated: false,
                    }}
                />
            )}
        </Box>
    );
};

export default TeamItem;
