/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateBookingResSource1ReferenceDto } from './CreateBookingResSource1ReferenceDto';

export type CreateBookingResSource1Dto = {
    /**
     * type of the source
     */
    type: CreateBookingResSource1Dto.type;
    /**
     * List of customers
     */
    reference: CreateBookingResSource1ReferenceDto;
};

export namespace CreateBookingResSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
