import React from 'react';
import { Divider, Stack } from '@mui/material';
import { Period } from '@travelity/api';
import { format } from 'date-fns';
import { durationToString } from '@travelity/utils';

import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';

export interface BookingScheduleFormProps {
    date: Period;
    preview?: boolean;
}

const BookingScheduleForm: React.FC<BookingScheduleFormProps> = ({
    date,
    preview,
}) => {
    const { start, duration } = date;

    return preview ? (
        <Stack spacing={2}>
            {duration && (
                <SingleLineKeyValue
                    dark
                    label="Duration"
                    value={durationToString(duration)}
                />
            )}
            <Divider />
            <SingleLineKeyValue
                dark
                label="Date"
                value={format(start, 'dd MMM yyyy')}
            />
            <Divider />
            <SingleLineKeyValue
                dark
                label="Time"
                value={format(start, 'HH:mm')}
            />
        </Stack>
    ) : (
        <Stack spacing={3} />
    );
};

export default React.memo(BookingScheduleForm);
