import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from '../layouts/main-layout';
import AuthenticationGuard from './authentication-guard';
import UserProfile from '../views/user-profile';
import Availability from '../views/availability';
import AddProduct from '../views/add-product';
import Products from '../views/products';
import SelectType from '../views/select-type';
import Error404 from '../views/error-404';
import OrderPage from '../views/order-page';
import Orders from '../views/orders';
import Events from '../views/events';
import AcceptTeamInvitation from '../views/accept-team-invitation';
import Teams from '../views/teams';
import Bookings from '../views/bookings';

const MainRoutes = {
    path: '/',
    element: <AuthenticationGuard component={MainLayout} />,
    children: [
        // {
        //     path: '/',
        //     element: <Home />,
        // },
        {
            path: '/availability',
            element: <Availability />,
        },
        {
            path: '*',
            element: <Error404 />,
        },
        {
            path: '/products/add',
            element: <SelectType />,
        },
        {
            path: '/products/add/:productType',
            element: <AddProduct />,
        },
        {
            path: '/products/:productId',
            element: <Products />,
        },
        {
            path: '/products',
            element: <Products />,
        },
        {
            path: '/events/:eventId/:tabName?/:mode?',
            element: <Events />,
        },
        {
            path: '/events',
            element: <Events />,
        },
        {
            path: '/events/:eventId/booking/:bookingId?/:tabName?/:mode?',
            element: <Events />,
        },
        {
            path: '/teams',
            element: <Teams />,
        },
        {
            path: '/teams/:teamId/member/:memberId/:tabName?/:mode?',
            element: <Teams />,
        },
        {
            path: '/orders',
            element: <Orders />,
        },
        {
            path: '/orders/:orderId',
            element: <Orders />,
        },
        {
            path: '/order/:orderId/booking/:bookingId/:tabName?/:mode?',
            element: <OrderPage />,
        },
        {
            path: '/order/:orderId/:preview?/:tabName?/:mode?',
            element: <OrderPage />,
        },
        {
            path: '/bookings/:bookingId?/:tabName?/:mode?',
            element: <Bookings />,
        },
        {
            path: '/',
            element: <Navigate to="/events" />,
        },
        {
            path: '/me',
            element: <UserProfile />,
        },
        {
            path: '/me/*',
            element: <UserProfile />,
        },
        {
            path: '/accept_invitation/:teamId/:key',
            element: <AcceptTeamInvitation />,
        },
    ],
};

export default MainRoutes;
