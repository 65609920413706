/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamsResItemMembersItemMembership0RoleAccessDto } from './GetTeamsResItemMembersItemMembership0RoleAccessDto';

export type GetTeamsResItemMembersItemMembership1Role4Dto = {
    /**
     * Type of user's role
     */
    type: GetTeamsResItemMembersItemMembership1Role4Dto.type;
    /**
     * Access details of the current role
     */
    access: GetTeamsResItemMembersItemMembership0RoleAccessDto;
};

export namespace GetTeamsResItemMembersItemMembership1Role4Dto {

    /**
     * Type of user's role
     */
    export enum type {
        VIEWER = 'viewer',
    }


}
