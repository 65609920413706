/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateStaffEventsReqItemDto } from './UpdateStaffEventsReqItemDto';

export type UpdateStaffEventsReqDto = {
    /**
     * Staff members
     */
    items?: Array<UpdateStaffEventsReqItemDto>;
    type: UpdateStaffEventsReqDto.type;
};

export namespace UpdateStaffEventsReqDto {

    export enum type {
        TOUR = 'tour',
    }


}
