import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { TagDropdown } from '@travelity/form';
import { Tag } from '@travelity/ui';
import { useFormContext } from 'react-hook-form';
import { ProductCapacityItem, ProductScheduleItem } from '@travelity/api';
import { DependencyDropdown } from '../dependency-dropdown';
import { useSelectOptions } from '../../../../hooks';

export interface FinancingCardHeaderProps {
    item: Record<string, any>;
    editing: boolean;
    usedDependencies: { capacity?: string; schedule?: string }[];
    capacities: ProductCapacityItem[];
    schedules: ProductScheduleItem[];
    prefix?: string;
}

const possibleDependencyOptions = [
    { label: 'Capacity', value: 'capacity' },
    { label: 'Schedule', value: 'schedule' },
];

const FinancingCardHeader: React.FC<FinancingCardHeaderProps> = props => {
    const {
        item,
        editing = {},
        capacities,
        schedules,
        usedDependencies,
        prefix,
    } = props;
    const formPrefix = prefix ? `${prefix}.` : '';
    const { watch, setValue } = useFormContext();
    const capacity = watch(`${formPrefix}capacity`);
    const schedule = watch(`${formPrefix}schedule`);
    const dependencyOptions = useMemo(() => {
        return possibleDependencyOptions
            .filter(d => d.value !== 'capacity' || !capacity)
            .filter(d => d.value !== 'schedule' || !schedule);
    }, [capacity, schedule]);

    // Filter current item from used dependencies to have it in possible dependency options
    const reservedDependencies = useMemo(() => {
        return usedDependencies.filter(
            ({ capacity: c, schedule: s }) =>
                c !== item.capacity || s !== item.schedule
        );
    }, [item, usedDependencies]);

    const possibleCapacities = useMemo(() => {
        if (schedule) {
            return capacities.filter(
                c =>
                    !reservedDependencies.find(
                        ({ schedule: sc, capacity: ca }) =>
                            sc === schedule.scheduleId && ca === c.capacityId
                    )
            );
        }
        return capacities.filter(
            c =>
                !reservedDependencies.find(
                    ({ schedule: sc, capacity: ca }) =>
                        !sc && ca === c.capacityId
                )
        );
    }, [schedule, reservedDependencies]);
    const possibleCapacitiesWithCurrent = useMemo(
        () =>
            capacity?.capacityId &&
            !possibleCapacities.find(c => c.capacityId === capacity.capacityId)
                ? [capacity, ...possibleCapacities]
                : possibleCapacities,
        [capacity, possibleCapacities]
    );
    const capacityOptions = useSelectOptions(
        possibleCapacitiesWithCurrent,
        'name',
        'id',
        true
    );

    const possibleSchedules = useMemo(() => {
        if (capacity) {
            return schedules.filter(
                s =>
                    !reservedDependencies.find(
                        ({ schedule: sc, capacity: ca }) =>
                            sc === s.scheduleId && ca === capacity.capacityId
                    )
            );
        }
        return schedules.filter(
            s =>
                !reservedDependencies.find(
                    ({ schedule: sc, capacity: ca }) =>
                        !ca && sc === s.scheduleId
                )
        );
    }, [capacity, reservedDependencies]);
    const possibleSchedulesWithCurrent = useMemo(
        () =>
            schedule?.scheduleId &&
            !possibleSchedules.find(s => s.scheduleId === schedule.scheduleId)
                ? [schedule, ...possibleSchedules]
                : possibleSchedules,
        [schedule, possibleSchedules]
    );
    const scheduleOptions = useSelectOptions(
        possibleSchedulesWithCurrent,
        'name',
        'id',
        true
    );

    return (
        <Stack
            direction="row"
            sx={{ width: 1 }}
            alignItems="center"
            justifyContent="space-between"
        >
            <Stack direction="row" gap={1}>
                {editing ? (
                    <>
                        {capacity && (
                            <TagDropdown
                                label="Capacity"
                                name={`${formPrefix}capacity`}
                                options={capacityOptions}
                                valueAsObject
                                valueKey="capacityId"
                                optionsSx={{
                                    maxWidth: '88px',
                                }}
                                autoOpenEmpty
                                enableRemove
                            />
                        )}
                        {schedule && (
                            <TagDropdown
                                label="Schedule"
                                name={`${formPrefix}schedule`}
                                options={scheduleOptions}
                                valueAsObject
                                valueKey="scheduleId"
                                optionsSx={{
                                    maxWidth: '88px',
                                }}
                                autoOpenEmpty
                                enableRemove
                            />
                        )}
                    </>
                ) : (
                    <>
                        {item.capacity && (
                            <Tag
                                label="Capacity"
                                values={[item.capacity.name]}
                                valueProps={{
                                    sx: {
                                        maxWidth: '88px',
                                        display: 'inline-flex',
                                        '& span': {
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        },
                                    },
                                }}
                            />
                        )}
                        {item.schedule && (
                            <Tag
                                label="Schedule"
                                values={[item.schedule.name]}
                                valueProps={{
                                    sx: {
                                        maxWidth: '88px',
                                        display: 'inline-flex',
                                        '& span': {
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        },
                                    },
                                }}
                            />
                        )}
                        {!item.capacity && !item.schedule && (
                            <Tag label="Dependencies" values={['N/A']} />
                        )}
                    </>
                )}
            </Stack>
            {editing && !!dependencyOptions.length && (
                <DependencyDropdown
                    label="Dependencies"
                    options={dependencyOptions}
                    onSelect={v => {
                        setValue(`${formPrefix}${v}`, {});
                    }}
                />
            )}
        </Stack>
    );
};

export default FinancingCardHeader;
