/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnterpriseSubscriptionUserFeaturesData } from './EnterpriseSubscriptionUserFeaturesData';
import type { ResourceType } from './ResourceType';

export type EnterpriseSubscriptionData = {
    /**
     * Name of the subscription
     */
    name?: EnterpriseSubscriptionData.name;
    /**
     * Team subscription user configuration
     */
    user?: EnterpriseSubscriptionUserFeaturesData;
    /**
     * List of supported resources
     */
    resources?: Array<ResourceType>;
};

export namespace EnterpriseSubscriptionData {

    /**
     * Name of the subscription
     */
    export enum name {
        ENTERPRISE = 'enterprise',
    }


}
