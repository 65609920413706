import React from 'react';
import { Box, Stack } from '@mui/material';
import { Activity } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircleIcon from '@mui/icons-material/Circle';
import { User } from './components/user';
import { Action, supportedActions } from './components/action';
import { DateTime } from './components/date-time';

export interface ActivityHistoryProps {
    entity: string;
    lifecycle: Activity[];
}

export const ActivityHistory: React.FC<ActivityHistoryProps> = props => {
    const { entity, lifecycle } = props;
    const filteredLifecycle = lifecycle.filter(activity =>
        supportedActions.includes(activity.type)
    );

    return (
        <PerfectScrollbar>
            <Stack spacing={2} sx={{ width: '450px' }}>
                {filteredLifecycle.map(activity => (
                    <Stack
                        key={`${activity.date}-${activity.updates.join('-')}`}
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            color: '#6B748C',
                            fontSize: '12px',
                            textAlign: 'left',
                        }}
                    >
                        <Stack direction="row">
                            <CircleIcon
                                sx={{
                                    fontSize: '6px',
                                    color: '#6B748C',
                                    mr: 0.75,
                                    mt: 0.75,
                                }}
                            />
                            <Box>
                                <User name={activity.name} />{' '}
                                <Action
                                    action={activity.type}
                                    entity={entity}
                                    updates={activity.updates}
                                />
                            </Box>
                        </Stack>
                        <DateTime date={activity.date} />
                    </Stack>
                ))}
            </Stack>
        </PerfectScrollbar>
    );
};
