import React from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

export interface OverlayWithReasonProps {
    title?: string;
    reason?: string;
}

export const OverlayWithReason: React.FC<OverlayWithReasonProps> = props => {
    const { reason, title } = props;

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.5)',
                backdropFilter: 'blur(3px)',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                borderRadius: '12px',
                zIndex: 100,
            }}
        >
            {title && (
                <Stack
                    sx={{
                        borderRadius: '0px 0px 8px 8px',
                        border: '1px solid #E0E4EB',
                        background: '#FFF',
                        p: '10px',
                        pt: '-2px',
                        lineHeight: '14px',
                        boxShadow: '0px 0px 16px 0px rgba(178, 185, 205, 0.50)',
                    }}
                    direction="row"
                    alignItems="center"
                >
                    <Box
                        component="span"
                        sx={{
                            color: '#6B748C',
                            textAlign: 'center',
                            fontWeight: '700',
                            lineHeight: '100%',
                            textTransform: 'uppercase',
                        }}
                    >
                        {title}
                    </Box>
                    {reason && (
                        <Stack
                            alignItems="center"
                            sx={{
                                borderLeft: '1px solid #C9CEDC',
                                pl: 1,
                                ml: 1,
                            }}
                        >
                            <Tooltip
                                title={reason}
                                enterDelay={100}
                                enterNextDelay={0}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            p: 1,
                                            borderRadius: '4px',
                                            background: '#3B4D7D',
                                            boxShadow:
                                                '0px 0px 6px 0px rgba(59, 77, 125, 0.40)',
                                        },
                                    },
                                }}
                            >
                                <ChatOutlinedIcon
                                    sx={{ color: '#6B748C', fontSize: '15px' }}
                                />
                            </Tooltip>
                        </Stack>
                    )}
                </Stack>
            )}
        </Box>
    );
};
