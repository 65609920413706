/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserReqAccountMembershipsItem1Role2Dto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserReqAccountMembershipsItem1Role2Dto.type;
};

export namespace UpdateUserReqAccountMembershipsItem1Role2Dto {

    /**
     * Type of user's role
     */
    export enum type {
        CONTRIBUTOR = 'contributor',
    }


}
