/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetAssetResOwnerReferenceDto = {
    /**
     * User's occupation
     */
    type: GetAssetResOwnerReferenceDto.type;
    /**
     * ID of the referenced user
     */
    id?: string;
};

export namespace GetAssetResOwnerReferenceDto {

    /**
     * User's occupation
     */
    export enum type {
        HUMAN = 'human',
    }


}
