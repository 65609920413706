/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CancelEventsResBookingsItemSource1ReferenceDto } from './CancelEventsResBookingsItemSource1ReferenceDto';

export type CancelEventsResBookingsItemSource1Dto = {
    /**
     * type of the source
     */
    type?: CancelEventsResBookingsItemSource1Dto.type;
    /**
     * List of customers
     */
    reference?: CancelEventsResBookingsItemSource1ReferenceDto;
};

export namespace CancelEventsResBookingsItemSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
