/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ComputePriceBookingsReqSource1ReferenceContactDetailsDto } from './ComputePriceBookingsReqSource1ReferenceContactDetailsDto';
import type { ComputePriceBookingsReqSource1ReferenceOrganizationDto } from './ComputePriceBookingsReqSource1ReferenceOrganizationDto';
import type { Language } from './Language';
import type { PersonNameData } from './PersonNameData';

export type ComputePriceBookingsReqSource1ReferenceDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type: ComputePriceBookingsReqSource1ReferenceDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: ComputePriceBookingsReqSource1ReferenceContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: ComputePriceBookingsReqSource1ReferenceOrganizationDto;
};

export namespace ComputePriceBookingsReqSource1ReferenceDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        PERSON = 'person',
    }


}
