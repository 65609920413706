/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetUserResAccountMembershipsItem0RoleAccessDto } from './GetUserResAccountMembershipsItem0RoleAccessDto';

export type GetUserResAccountMembershipsItem1Role3Dto = {
    /**
     * Type of user's role
     */
    type: GetUserResAccountMembershipsItem1Role3Dto.type;
    /**
     * Access details of the current role
     */
    access: GetUserResAccountMembershipsItem0RoleAccessDto;
};

export namespace GetUserResAccountMembershipsItem1Role3Dto {

    /**
     * Type of user's role
     */
    export enum type {
        EDITOR = 'editor',
    }


}
