import { GetAssetsResItemDto } from '../../requests';
import { Asset } from './asset.types';

export const getAssetDtoToAsset = (
    asset: Omit<
        GetAssetsResItemDto,
        'created' | 'owner' | 'usage' | 'mileage' | 'resource_type' | 'access'
    >
): Asset => ({
    id: asset.id,
    type: asset.vehicle_type,
    licensePlate: asset.license_plate,
    make: asset.make,
    model: asset.model,
    year: asset.year,
    maxPassengers: asset.max_passengers,
    fuelType: asset.engine_type,
});
