/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProfessionalSubscriptionUserFeaturesData } from './ProfessionalSubscriptionUserFeaturesData';
import type { ResourceType } from './ResourceType';

export type ProfessionalSubscriptionData = {
    /**
     * Name of the subscription
     */
    name?: ProfessionalSubscriptionData.name;
    /**
     * Professional subscription user configuration
     */
    user?: ProfessionalSubscriptionUserFeaturesData;
    /**
     * List of supported resources
     */
    resources?: Array<ResourceType>;
};

export namespace ProfessionalSubscriptionData {

    /**
     * Name of the subscription
     */
    export enum name {
        PROFESSIONAL = 'professional',
    }


}
