import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTeams } from '@travelity/api';
import { TeamItem } from '../../components/team-item';

import { TeamItemSkeleton } from '../../components/team-item-skeleton';
import { TeamModal } from '../../components/team-modal';
import MemberPreview from './components/member-preview';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
    ListTitle,
} from '../../components/list-layout';
import { useUserContext } from '../../contexts/user';

function Teams() {
    const { teamId, memberId } = useParams();

    const { data: teams, isLoading, refetch } = useTeams();

    const [teamOpen, setTeamOpen] = useState(false);

    const team = useMemo(() => {
        if (!teams || !teamId) return undefined;
        return teams.find(t => teamId === t.id) || undefined;
    }, [teams, teamId, memberId]);

    const member = useMemo(() => {
        if (!team || !memberId) return undefined;
        return team.members.find(
            m => memberId === m.id || memberId === m.email
        );
    }, [team, memberId]);

    const { user } = useUserContext();
    const showCreate = user?.roleAccess.team?.create;

    return (
        <>
            <List>
                <ListMain
                    isLoading={isLoading}
                    SkeletonComponent={TeamItemSkeleton}
                >
                    <ListTitle title="My Teams">
                        {showCreate && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setTeamOpen(true)}
                            >
                                Create Team
                            </Button>
                        )}
                    </ListTitle>
                    <ListItems
                        noItemsText="No teams"
                        items={teams?.length}
                        isLoading={isLoading}
                        entityName="team"
                        entityNamePlural="teams"
                        subtractHeight={72}
                    >
                        {teams?.map(t => (
                            <TeamItem
                                key={t.id}
                                team={t}
                                refetch={refetch}
                                isSelected={t.id === teamId}
                            />
                        ))}
                    </ListItems>
                </ListMain>
                <ListSidebar
                    isEmpty={!teamId && !memberId}
                    placeholderText="No user is selected for preview"
                >
                    {member && team && (
                        <MemberPreview
                            member={member}
                            teamId={team.id as string}
                            isEditable={!!team?.permissions.update}
                        />
                    )}
                </ListSidebar>
            </List>
            {teamOpen && (
                <TeamModal
                    handleCancel={() => {
                        setTeamOpen(false);
                        refetch();
                    }}
                    open={teamOpen}
                />
            )}
        </>
    );
}

export default Teams;
