import React, { useCallback, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useForm } from '@travelity/form';
import { array, object, string } from 'yup';
import {
    convertFullNameToNameDto,
    memberToDetailsForm,
    TeamMember,
    useUpdateTeamMember,
} from '@travelity/api';
import { SideFormAccordion } from '../../../components/side-form-accordion';
import { useSideFormAccordion } from '../../../components/side-form-accordion/side-form-accordion.hooks';
import MemberPersonalDetailsForm from './member-personal-details';

export interface MemberPreviewProps {
    member: TeamMember;
    teamId: string;
    isEditable?: boolean;
}

const detailsSchema = object().shape({
    fullName: string().required(),
    numbers: array(),
});

export interface MemberDetailsForm {
    fullName: string;
    numbers: { value: string }[];
}

const MemberPreview: React.FC<MemberPreviewProps> = ({
    member,
    teamId,
    isEditable,
}) => {
    const { view, openTab, isEdit } = useSideFormAccordion();

    const { mutate: updateMember, isLoading } = useUpdateTeamMember({
        onSuccess: () => {
            view();
        },
    });
    const onSubmit = useCallback(
        (data: MemberDetailsForm) => {
            const oldData = memberToDetailsForm(member);
            if (
                oldData.numbers.toString() === data.numbers.toString() &&
                oldData.fullName === data.fullName
            ) {
                view();
                return;
            }
            updateMember({
                teamId,
                id: member.id,
                name: data.fullName
                    ? convertFullNameToNameDto(data.fullName)
                    : undefined,
                phone_numbers:
                    data.numbers
                        ?.map(({ value }) => value)
                        .map(number => ({
                            calling_code: parseInt(number.split(' ')[0], 10),
                            number: parseInt(number.split(' ')[1], 10),
                        })) || [],
            });
        },
        [member]
    );

    const {
        Form: DetailsForm,
        reset: resetDetailsForm,
        submit,
        // formState: { errors: travelersErrors },
    } = useForm({
        defaultValues: memberToDetailsForm(member),
        onSubmit,
        mode: 'onChange',
        schema: detailsSchema,
    });

    const onSave = () => {
        submit();
    };
    const onCancel = () => {
        resetDetailsForm(memberToDetailsForm(member));
        view();
    };

    useEffect(() => {
        resetDetailsForm(memberToDetailsForm(member));
    }, [member]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 30px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Member Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <SideFormAccordion
                        id="personal-details"
                        title="Personal Details"
                        isEditable={isEditable}
                        isSaving={isLoading}
                        onSave={onSave}
                        onCancel={onCancel}
                    >
                        <DetailsForm>
                            <MemberPersonalDetailsForm
                                preview={
                                    !isEdit || openTab !== 'personal-details'
                                }
                            />
                        </DetailsForm>
                    </SideFormAccordion>
                </Box>
            </PerfectScrollbar>
        </Box>
    );
};

export default React.memo(MemberPreview);
