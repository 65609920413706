/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateUserResAccountMembershipsItem0RoleAccessDto } from './UpdateUserResAccountMembershipsItem0RoleAccessDto';

export type UpdateUserResAccountMembershipsItem1Role4Dto = {
    /**
     * Type of user's role
     */
    type: UpdateUserResAccountMembershipsItem1Role4Dto.type;
    /**
     * Access details of the current role
     */
    access: UpdateUserResAccountMembershipsItem0RoleAccessDto;
};

export namespace UpdateUserResAccountMembershipsItem1Role4Dto {

    /**
     * Type of user's role
     */
    export enum type {
        VIEWER = 'viewer',
    }


}
