/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DirectSource } from './DirectSource';

export type UpdateBookingSourceBookingsReqDto = {
    /**
     * type of the source
     */
    type?: UpdateBookingSourceBookingsReqDto.type;
    /**
     * name of the source
     */
    name?: DirectSource;
};

export namespace UpdateBookingSourceBookingsReqDto {

    /**
     * type of the source
     */
    export enum type {
        DIRECT = 'direct',
    }


}
