/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateUserResProfileOrganizationContactDetailsDto } from './UpdateUserResProfileOrganizationContactDetailsDto';

export type UpdateUserResProfileOrganizationDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type?: UpdateUserResProfileOrganizationDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: UpdateUserResProfileOrganizationContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name?: string;
};

export namespace UpdateUserResProfileOrganizationDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        ORGANIZATION = 'organization',
    }


}
