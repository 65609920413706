/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FreeSubscriptionUserFeaturesData } from './FreeSubscriptionUserFeaturesData';
import type { ResourceType } from './ResourceType';

export type FreeSubscriptionData = {
    /**
     * Name of the subscription
     */
    name?: FreeSubscriptionData.name;
    /**
     * Free subscription user configuration
     */
    user?: FreeSubscriptionUserFeaturesData;
    /**
     * List of supported resources
     */
    resources?: Array<ResourceType>;
};

export namespace FreeSubscriptionData {

    /**
     * Name of the subscription
     */
    export enum name {
        FREE = 'free',
    }


}
