/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelOrdersReqDto } from '../models/CancelOrdersReqDto';
import type { CancelOrdersResDto } from '../models/CancelOrdersResDto';
import type { CreateOrderReqDto } from '../models/CreateOrderReqDto';
import type { CreateOrderResDto } from '../models/CreateOrderResDto';
import type { GetOrderResDto } from '../models/GetOrderResDto';
import type { GetOrdersResDto } from '../models/GetOrdersResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * List/Filter Orders
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetOrdersResDto Successful Response
     * @throws ApiError
     */
    public static getOrders(
authorization: string,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetOrdersResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/orders',
            headers: {
                'authorization': authorization,
            },
            query: {
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateOrderResDto Successful Response
     * @throws ApiError
     */
    public static createOrder(
authorization: string,
requestBody: CreateOrderReqDto,
): CancelablePromise<CreateOrderResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/orders',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch Order
     * @param orderId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetOrderResDto Successful Response
     * @throws ApiError
     */
    public static getOrder(
orderId: string,
authorization: string,
): CancelablePromise<GetOrderResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/orders/{order_id}',
            path: {
                'order_id': orderId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel the Order
     * @param orderId ID of the order being canceled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CancelOrdersResDto Successful Response
     * @throws ApiError
     */
    public static cancelOrders(
orderId: string,
authorization: string,
requestBody: CancelOrdersReqDto,
): CancelablePromise<CancelOrdersResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/orders/{order_id}/cancel',
            path: {
                'order_id': orderId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Order Details
     * @param orderId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportDetailsOrders(
orderId: string,
authorization: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/orders/{order_id}/export',
            path: {
                'order_id': orderId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
