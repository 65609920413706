import React, { useMemo, useState } from 'react';
import { Button, Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
    productFilterToRequestBodyConverter,
    useProductsLazy,
} from '@travelity/api';
import { ProductItem } from '../../components/product-item';

import ProductEditForm from './components/product-edit-form';
import { ProductItemSkeleton } from '../../components/product-item-skeleton';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import { productTypeOptions } from '../select-type/select-type';
import { Filters } from '../../components/filters';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
    ListTitle,
} from '../../components/list-layout';
import { useLoadOnScroll } from '../../hooks';
import { useUserContext } from '../../contexts/user';

const productFilters: FilterOption[] = [
    {
        name: 'productType',
        label: 'Product Type',
        type: FilterTypes.DROPDOWN,
        selectText: 'Please, select the value for product type',
        options: productTypeOptions,
    },
    {
        name: 'pricing',
        label: 'Pricing',
        type: FilterTypes.PRICE,
        selectText: 'Please, select the values for price filter',
    },
];

export interface ProductsProps {}

function Products() {
    const navigate = useNavigate();
    const { productId } = useParams();

    // filters
    const [filters, setFilters] = useState({});

    const {
        data: products,
        isLoading,
        refetch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useProductsLazy(
        useMemo(() => productFilterToRequestBodyConverter(filters), [filters])
    );

    const product = useMemo(() => {
        return productId
            ? products?.find(({ id }) => id === productId)
            : undefined;
    }, [products, productId]);

    const { onScroll } = useLoadOnScroll({
        hasNextPage: !!hasNextPage && !isFetchingNextPage,
        fetchNextPage,
    });

    const { user } = useUserContext();
    const showCreate = user?.roleAccess.product?.create;

    return (
        <List>
            <ListMain
                isLoading={isLoading}
                SkeletonComponent={ProductItemSkeleton}
            >
                <ListTitle title="All Products">
                    {showCreate && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate('/products/add')}
                        >
                            Create Product
                        </Button>
                    )}
                </ListTitle>
                <Divider sx={{ mb: 0.25 }} />
                <Filters
                    values={filters}
                    setValues={setFilters}
                    options={productFilters}
                />
                <ListItems
                    items={products?.length}
                    isLoading={isLoading}
                    scrollbarProps={{
                        onScrollY: onScroll,
                    }}
                    noItemsText="Filter results will be here"
                    entityName="product"
                    entityNamePlural="products"
                    subtractHeight={72 + 1 + 60}
                >
                    {products?.map(p => (
                        <ProductItem
                            key={p.id}
                            product={p}
                            isSelected={productId === p.id}
                            refetch={refetch}
                        />
                    ))}
                    {hasNextPage && <ProductItemSkeleton />}
                </ListItems>
            </ListMain>
            <ListSidebar
                placeholderText="No product is selected for preview"
                isEmpty={!product}
            >
                {product && <ProductEditForm product={product} />}
            </ListSidebar>
        </List>
    );
}

export default Products;
