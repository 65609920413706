/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserReqAccountMembershipsItem1Role1Dto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserReqAccountMembershipsItem1Role1Dto.type;
};

export namespace UpdateUserReqAccountMembershipsItem1Role1Dto {

    /**
     * Type of user's role
     */
    export enum type {
        ADMINISTRATOR = 'administrator',
    }


}
