import React from 'react';
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import { useMatch } from 'react-router-dom';
import { useNavigateAbsolute } from '../../../../hooks';

interface NavItemProps {
    title: string;
    url?: string;
    icon: React.ElementType;
    pattern: string;
    nativeIcon?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

function NavItem({
    title,
    url,
    icon,
    pattern,
    nativeIcon,
    disabled,
    onClick,
}: NavItemProps) {
    // const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigateAbsolute();
    const match = useMatch(pattern);
    const Icon = icon as React.FC<Record<string, any>>;
    const itemIcon = icon ? <Icon stroke={1.5} size="1.3rem" /> : <span />;

    const isActive = !!match;
    // const listItemProps = {
    //     component: forwardRef((props, ref) => (
    //         <Link ref={ref} {...props} to={url} />
    //     )),
    // };

    // const itemHandler = (id: string) => {
    //     // TODO xor
    //     setIsOpen((prev: string[]) => prev.filter((i: string) => i !== id));
    //     // TODO set menu state
    //     // if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    // };

    return (
        <ListItem
            secondaryAction={
                disabled ? (
                    <Tooltip title="Upgrade subscription to use this feature">
                        <LockIcon
                            sx={{ mt: 0.5, opacity: 0.38 }}
                            fontSize="small"
                        />
                    </Tooltip>
                ) : undefined
            }
            sx={{ p: 0 }}
        >
            <ListItemButton
                disabled={disabled}
                sx={{
                    mx: 2,
                    my: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: isActive ? '#F4F6FA' : 'inherit',
                    py: 1.5,
                    pl: 1,
                    pr: 0,
                    borderRadius: '4px',
                    ...(nativeIcon && {
                        '& svg': {
                            width: '18px',
                            height: '18px',
                            marginLeft: '2px',
                            marginTop: '3px',
                        },
                    }),
                }}
                onClick={() => {
                    // drawerToggle();
                    if (onClick) onClick();
                    if (url) navigate(url);
                }}
            >
                <ListItemIcon
                    sx={{
                        my: 0,
                        minWidth: '24px',
                        color: isActive ? '#55B5CF' : '#7682A4',
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
                <ListItemText
                    sx={{
                        '& .MuiListItemText-primary': {
                            color: isActive ? '#55B5CF' : '#7682A4',
                            pl: '8px',
                            fontSize: '14px',
                            lineHeight: '14px',
                            mt: '2px',
                        },
                    }}
                    primary={title}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default NavItem;
