/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserReqAccountMembershipsItem1Role3Dto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserReqAccountMembershipsItem1Role3Dto.type;
};

export namespace UpdateUserReqAccountMembershipsItem1Role3Dto {

    /**
     * Type of user's role
     */
    export enum type {
        EDITOR = 'editor',
    }


}
