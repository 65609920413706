/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { CancelEventsResBookingsItemAccessDto } from './CancelEventsResBookingsItemAccessDto';
import type { CancelEventsResBookingsItemFinancialsDto } from './CancelEventsResBookingsItemFinancialsDto';
import type { CancelEventsResBookingsItemNotesDto } from './CancelEventsResBookingsItemNotesDto';
import type { CancelEventsResBookingsItemOrderDto } from './CancelEventsResBookingsItemOrderDto';
import type { CancelEventsResBookingsItemSource0Dto } from './CancelEventsResBookingsItemSource0Dto';
import type { CancelEventsResBookingsItemSource1Dto } from './CancelEventsResBookingsItemSource1Dto';
import type { CancelEventsResBookingsItemTransactionsDto } from './CancelEventsResBookingsItemTransactionsDto';
import type { UpdateEventBookingsResEventDto } from './UpdateEventBookingsResEventDto';
import type { UpdateEventBookingsResProductDto } from './UpdateEventBookingsResProductDto';
import type { UpdateUserResLastUpdatedDto } from './UpdateUserResLastUpdatedDto';

export type UpdateEventBookingsResDto = {
    /**
     * Type of the resource
     */
    resource_type?: UpdateEventBookingsResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResLastUpdatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResLastUpdatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResLastUpdatedDto>;
    /**
     * Source of the booking
     */
    source?: (CancelEventsResBookingsItemSource0Dto | CancelEventsResBookingsItemSource1Dto);
    /**
     * Order reference
     */
    product?: UpdateEventBookingsResProductDto;
    /**
     * Booked event
     */
    event?: UpdateEventBookingsResEventDto;
    /**
     * Order reference
     */
    order?: CancelEventsResBookingsItemOrderDto;
    /**
     * List of transactions
     */
    transactions?: CancelEventsResBookingsItemTransactionsDto;
    /**
     * List of transactions
     */
    financials?: CancelEventsResBookingsItemFinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: CancelEventsResBookingsItemNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access?: CancelEventsResBookingsItemAccessDto;
};

export namespace UpdateEventBookingsResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
