/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateBookingReqSource1Dto = {
    /**
     * type of the source
     */
    type: CreateBookingReqSource1Dto.type;
};

export namespace CreateBookingReqSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
