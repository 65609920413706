/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnterpriseSubscriptionData } from './EnterpriseSubscriptionData';
import type { FreeSubscriptionData } from './FreeSubscriptionData';
import type { GetUserResAccessDto } from './GetUserResAccessDto';
import type { GetUserResAccountDto } from './GetUserResAccountDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { GetUserResProfileDto } from './GetUserResProfileDto';
import type { ProfessionalSubscriptionData } from './ProfessionalSubscriptionData';
import type { ResourceType } from './ResourceType';
import type { TeamSubscriptionData } from './TeamSubscriptionData';

export type GetUserResDto = {
    /**
     * Type of the resource
     */
    resource_type: ResourceType;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Type of the user
     */
    type: GetUserResDto.type;
    /**
     * Account details of the user
     */
    account: GetUserResAccountDto;
    /**
     * Access to the shared product
     */
    access: GetUserResAccessDto;
    /**
     * Profile details of the user
     */
    profile: GetUserResProfileDto;
    /**
     * Memberships of the user
     */
    subscription: (FreeSubscriptionData | ProfessionalSubscriptionData | TeamSubscriptionData | EnterpriseSubscriptionData);
};

export namespace GetUserResDto {

    /**
     * Type of the user
     */
    export enum type {
        HUMAN = 'human',
    }


}
