/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetUserResAccountMembershipsItem0RoleAccessDto } from './GetUserResAccountMembershipsItem0RoleAccessDto';

export type GetUserResAccountMembershipsItem0RoleDto = {
    /**
     * Type of user's role
     */
    type: GetUserResAccountMembershipsItem0RoleDto.type;
    /**
     * Access details of the current role
     */
    access: GetUserResAccountMembershipsItem0RoleAccessDto;
};

export namespace GetUserResAccountMembershipsItem0RoleDto {

    /**
     * Type of user's role
     */
    export enum type {
        OWNER = 'owner',
    }


}
