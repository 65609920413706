/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DirectSource } from './DirectSource';

export type CancelEventsResBookingsItemSource0Dto = {
    /**
     * type of the source
     */
    type?: CancelEventsResBookingsItemSource0Dto.type;
    /**
     * name of the source
     */
    name?: DirectSource;
};

export namespace CancelEventsResBookingsItemSource0Dto {

    /**
     * type of the source
     */
    export enum type {
        DIRECT = 'direct',
    }


}
