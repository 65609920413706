/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateAssetsEventsReqItemDto } from './UpdateAssetsEventsReqItemDto';

export type UpdateAssetsEventsReq1Dto = {
    /**
     * Assets
     */
    items?: Array<UpdateAssetsEventsReqItemDto>;
    type: UpdateAssetsEventsReq1Dto.type;
};

export namespace UpdateAssetsEventsReq1Dto {

    export enum type {
        TRANSFER = 'transfer',
    }


}
