/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RegisterUsersResAccountMembershipsItem1GroupDto = {
    /**
     * Type of the user group
     */
    type: RegisterUsersResAccountMembershipsItem1GroupDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
};

export namespace RegisterUsersResAccountMembershipsItem1GroupDto {

    /**
     * Type of the user group
     */
    export enum type {
        TEAM = 'team',
    }


}
