import React from 'react';
import { Stack } from '@mui/material';

export interface ListProps {
    children: React.ReactNode;
}

export const List: React.FC<ListProps> = props => {
    const { children } = props;

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            {children}
        </Stack>
    );
};
