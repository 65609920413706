import React, { ReactElement } from 'react';
import { Box, BoxProps, Button, Theme, Tooltip } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useNavigateAbsolute } from '@travelity/web/src/hooks';

export interface IconButtonProps extends BoxProps {
    icon: ReactElement;
    disabled?: boolean;
    pressed?: boolean;
    withShadow?: boolean;
    isSubmit?: boolean;
    onClick?: (e?: React.MouseEvent) => void;
    href?: string;
    color?: string;
    form?: string;
    hoverColor?: string;
    tooltip?: string;
    tooltipPlacement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    sx?: SystemStyleObject<Theme>;
}

export const IconButton: React.FC<IconButtonProps> = props => {
    const {
        icon,
        disabled,
        withShadow,
        isSubmit,
        onClick,
        href,
        color = '#C9CEDC',
        hoverColor = 'secondary.main',
        sx = {},
        tooltip,
        tooltipPlacement = 'right',
        pressed = false,
        ...rest
    } = props;
    const navigate = useNavigateAbsolute();

    const button = (
        <Box
            sx={{
                '&': sx,
                textAlign: 'center',
                p: 0.75,
                my: 0.25,
                lineHeight: '12px',
                color,
                '&:hover': {
                    color: hoverColor,
                },
                ...(withShadow
                    ? {
                          p: '5px',
                          borderRadius: '12px',
                          background: '#FFF',
                          boxShadow:
                              '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                      }
                    : {}),
                ...(disabled
                    ? {
                          '&:hover': {},
                          cursor: 'default',
                          pointerEvents: 'none',
                      }
                    : {}),
                ...(isSubmit
                    ? {
                          minWidth: 'auto',
                          '&&&&:hover': {
                              boxShadow:
                                  '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                          },
                      }
                    : {}),
                ...(pressed && {
                    color: '#FFF',
                    '&:hover': {
                        color: '#FFF',
                    },
                    bgcolor: 'secondary.main',
                }),
            }}
            href={isSubmit ? undefined : '#'}
            component={isSubmit ? Button : 'a'}
            type={isSubmit ? 'submit' : undefined}
            onClick={(e: React.MouseEvent) => {
                if (!isSubmit) {
                    e.preventDefault();
                }
                e.stopPropagation();
                onClick?.(e);
                if (href) navigate(href);
            }}
            {...rest}
        >
            {icon}
        </Box>
    );

    return tooltip ? (
        <Tooltip placement={tooltipPlacement} title={tooltip}>
            {button}
        </Tooltip>
    ) : (
        button
    );
};
