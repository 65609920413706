import { paxTotal } from '@travelity/web/src/hooks';
import {
    EventAsset,
    EventItem,
    EventNote,
    EventProduct,
    EventStaff,
} from './event.types';
import {
    GetEventRes1Dto,
    GetEventResDto,
    GetEventsResItem0Dto,
    type GetEventsResItem0OperationsAssetsItemDto,
    GetEventsResItem0OperationsStaffItemDto,
    type GetEventsResItem0ProductDto,
    GetEventsResItem1Dto,
    GetEventsResItem1OperationsAssetsItemDto,
    GetEventsResItem1OperationsStaffItemDto,
    ProductType,
    TransferEventAssetType,
    type UpdateAssetsEventsReqItemDto,
    UpdateStaffEventsReqItemDto,
} from '../../requests';
import {
    convertActivityDtoToActivity,
    convertPeriodDataToPeriod,
    phoneNumberDataToPhoneNumber,
} from '../common.converters';
import { convertNameDtoToFullName } from '../user/user.converters';
import { getBookingDtoToBooking } from '../booking/booking.converters';

// export const eventToGetEventItemDto = (
//     event: EventItem
// ): Omit<GetEventsResItemDto, 'created'> => ({
//     id: event.id,
//     product: event.product,
//     operations: {
//         capacity_selection: event.capacitySelection,
//     },
//     date: {
//         tz_name: Intl.DateTimeFormat().resolvedOptions()
//             .timeZone as TimezoneName,
//         start: Math.round(event.date.start.getTime() / 1000),
//         end: event.date.end
//             ? Math.round(event.date.end.getTime() / 1000)
//             : undefined,
//     },
//     bookings_summary: event.bookingsSummary,
//     notes: event.notes,
//     status: event.status,
//     // available_seats: event.availableSeats,
//     // ephemeral: event.ephemeral,
// });

export const eventAssetDtoToEventAsset = (
    asset:
        | GetEventsResItem0OperationsAssetsItemDto
        | GetEventsResItem1OperationsAssetsItemDto
): EventAsset => ({
    id: asset.id,
    pinned: asset.pinned,
    reference: asset.reference
        ? {
              id: asset.reference.id,
              type: asset.reference.vehicle_type,
              licensePlate: asset.reference.license_plate,
              make: asset.reference.make,
              model: asset.reference.model,
              maxPassengers: asset.reference.max_passengers,
          }
        : undefined,
});

export const eventAssetToEventAssetDto = (
    asset: EventAsset
): Omit<UpdateAssetsEventsReqItemDto, 'action'> => ({
    id: asset.id,
    pinned: asset.pinned,
    reference: asset.reference
        ? {
              type: TransferEventAssetType.VEHICLE,
              id: asset.reference.id,
              vehicle_type: asset.reference.type,
              license_plate: asset.reference.licensePlate,
              make: asset.reference.make,
              model: asset.reference.model,
              max_passengers: asset.reference.maxPassengers,
          }
        : undefined,
});

export const eventStaffDtoToEventStaff = (
    staff:
        | GetEventsResItem0OperationsStaffItemDto
        | GetEventsResItem1OperationsStaffItemDto
): EventStaff => ({
    id: staff.id,
    pinned: staff.pinned,
    position: staff.position,
    reference: staff.reference
        ? {
              id: staff.reference.id,
              fullName: staff.reference.name
                  ? convertNameDtoToFullName(staff.reference.name)
                  : undefined,
              email: staff.reference.contact_details?.emails?.[0] || '',
              phoneNumber: staff.reference.contact_details?.phone_numbers?.[0]
                  ? phoneNumberDataToPhoneNumber(
                        staff.reference.contact_details.phone_numbers[0]
                    )
                  : undefined,
          }
        : undefined,
});

export const eventStaffToEventStaffDto = (
    staff: EventStaff
): Omit<UpdateStaffEventsReqItemDto, 'action'> => ({
    id: staff.id || staff.reference?.id,
    pinned: false,
    position: staff.position,
    // reference: staff.reference
    //     ? {
    //           type: UpdateMembersTeamsReqItemReferenceDto.type.PERSON,
    //           id: staff.reference.id,
    //           name: staff.reference.fullName
    //               ? convertFullNameToNameDto(staff.reference.fullName)
    //               : undefined,
    //       }
    //     : undefined,
});

export const eventProductReferenceToProductMinimal = (
    product: GetEventsResItem0ProductDto
): EventProduct => ({
    id: product.id,
    type: product.type as unknown as ProductType,
    name: product.name,
    shared: product.configuration.shared,
});

type EventDto =
    | GetEventsResItem0Dto
    | GetEventsResItem1Dto
    | GetEventResDto
    | GetEventRes1Dto;
export const getEventItemDtoToEvent = (event: EventDto): EventItem => ({
    id: event.id,
    status: event.status,
    date: convertPeriodDataToPeriod(event.date),
    productOptions: event.operations?.product_options || [],
    reason: event.cancelled?.message,
    lifecycle: event.lifecycle?.map(convertActivityDtoToActivity) || [],
    bookings: event.bookings.map(getBookingDtoToBooking),
    assets:
        event.operations?.assets?.items?.map(eventAssetDtoToEventAsset) || [],
    staff: event.operations?.staff?.items.map(eventStaffDtoToEventStaff) || [],
    sharedTeams:
        event.access?.groups?.items.map(group => ({
            id: group.id,
            type: group.reference.type,
        })) || [],
    route: event.operations?.route,
    capacity: {
        id: event.product?.capacity.reference.id,
        name: event.product?.capacity.reference.name,
    },
    product: eventProductReferenceToProductMinimal(event.product),
    notes: event.bookings.reduce(
        (arr: EventNote[], cur) =>
            cur.notes?.items
                ? [
                      ...arr,
                      ...cur.notes.items.map(note => ({
                          id: note.id,
                          text: note.content,
                          bookingId: cur.id,
                      })),
                  ]
                : arr,
        []
    ),
    totalPax: paxTotal(event.operations?.pax),
    vacant: event.operations?.vacant,
    overbooked: event.operations?.overbooked,
    permissions: {
        canEdit: !!event.access.permissions.update,
        canDelete: !!event.access.permissions.delete,
    },
    // capacitySelection: event.product.capacity,
});

export const eventsFilterToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    startTs: filters?.dates?.startDate
        ? Math.round(filters.dates.startDate.getTime() / 1000)
        : undefined,
    endTs: filters?.dates?.endDate
        ? Math.round(filters.dates.endDate.getTime() / 1000)
        : undefined,
    productId: filters.products,
});
