/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { ComputePriceBookingsReqAccessDto } from './ComputePriceBookingsReqAccessDto';
import type { ComputePriceBookingsReqCreatedDto } from './ComputePriceBookingsReqCreatedDto';
import type { ComputePriceBookingsReqEventDto } from './ComputePriceBookingsReqEventDto';
import type { ComputePriceBookingsReqFinancialsDto } from './ComputePriceBookingsReqFinancialsDto';
import type { ComputePriceBookingsReqNotesDto } from './ComputePriceBookingsReqNotesDto';
import type { ComputePriceBookingsReqOrderDto } from './ComputePriceBookingsReqOrderDto';
import type { ComputePriceBookingsReqProductDto } from './ComputePriceBookingsReqProductDto';
import type { ComputePriceBookingsReqSource0Dto } from './ComputePriceBookingsReqSource0Dto';
import type { ComputePriceBookingsReqSource1Dto } from './ComputePriceBookingsReqSource1Dto';
import type { ComputePriceBookingsReqTransactionsDto } from './ComputePriceBookingsReqTransactionsDto';

export type ComputePriceBookingsReqDto = {
    /**
     * Type of the resource
     */
    resource_type: ComputePriceBookingsReqDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Details about who has created the database record and when.
     */
    created: ComputePriceBookingsReqCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: ComputePriceBookingsReqCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: ComputePriceBookingsReqCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: ComputePriceBookingsReqCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<ComputePriceBookingsReqCreatedDto>;
    /**
     * Source of the booking
     */
    source: (ComputePriceBookingsReqSource0Dto | ComputePriceBookingsReqSource1Dto);
    /**
     * Order reference
     */
    product: ComputePriceBookingsReqProductDto;
    /**
     * Booked event
     */
    event: ComputePriceBookingsReqEventDto;
    /**
     * Order reference
     */
    order: ComputePriceBookingsReqOrderDto;
    /**
     * List of transactions
     */
    transactions?: ComputePriceBookingsReqTransactionsDto;
    /**
     * List of transactions
     */
    financials: ComputePriceBookingsReqFinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: ComputePriceBookingsReqNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: ComputePriceBookingsReqAccessDto;
};

export namespace ComputePriceBookingsReqDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}
