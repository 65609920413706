import React, { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { useForm } from '@travelity/form';
import { Button } from '@travelity/ui';
import { object, string } from 'yup';

import SelectTimezone from '../../../components/select-timezone/select-timezone';

const schema = object().shape({
    timeZone: string().required(' Time zone is required'),
});

export interface TimeZoneStepProps {
    timeZone?: string;
    back: () => void;
    next: (v: string) => void;
}

export const TimeZoneStep: React.FC<TimeZoneStepProps> = props => {
    const { timeZone, next, back } = props;

    const [showError, setShowErrors] = React.useState(false);

    const { Form, watch } = useForm({
        schema,
        onSubmit: useCallback(
            (data: { timeZone: string }) => {
                next(data.timeZone);
            },
            [next]
        ),
        defaultValues: { timeZone },
        mode: 'onChange',
        validateInitially: true,
    });

    return (
        <Form>
            <Stack
                sx={{
                    py: 5,
                    height: 1,
                    minHeight: 'calc(100vh - 84px)',
                }}
                gap={2}
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    sx={{
                        color: '#C0C4CE',
                        fontSize: '24px',
                    }}
                >
                    Which time zone you are in?
                </Typography>
                <SelectTimezone
                    name="timeZone"
                    label=""
                    placeholder="Select time zone"
                    showErrors={showError}
                />
                <Stack direction="row" spacing={1} sx={{ width: '388px' }}>
                    <Button
                        variant="contained"
                        color="neutral"
                        sx={{ flexGrow: 1 }}
                        onClick={back}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                        }}
                        doNotDisable
                        onIconClick={() => {
                            setShowErrors(true);
                        }}
                        requiredFields={
                            !watch('timeZone')?.length
                                ? ['Time Zone']
                                : undefined
                        }
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
