import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Select, TextField, useForm } from '@travelity/form';
import { Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import {
    Asset,
    getAssetDtoToAsset,
    useCreateAsset,
    VehicleEngineType,
} from '@travelity/api';

import { useSnackbar } from 'notistack';
import { convertEnumToList } from '@travelity/utils';
import { VehicleType } from '@travelity/api/src/requests';
import schema from './create-asset.schema';
import { useIsChanged } from '../../../hooks';

const formId = 'create-asset-modal';

export interface CreateAssetModalProps {
    handleCancel: () => void;
    handleConfirm: (v: Asset) => void;
    open: boolean;
}

type CreateAsset = Omit<Asset, 'id'>;

const fuelOptions = [
    { value: VehicleEngineType.DIESEL, label: 'Diesel' },
    { value: VehicleEngineType.PETROL, label: 'Petrol' },
    { value: VehicleEngineType.CNG, label: 'CNG' },
];

const CreateAssetModal: React.FC<CreateAssetModalProps> = ({
    handleCancel,
    handleConfirm,
    open,
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: createAsset } = useCreateAsset({
        onError: () => {
            enqueueSnackbar('Failed to create the asset', {
                variant: 'error',
            });
        },
    });

    const assetTypes = useMemo(
        () => convertEnumToList<VehicleType>(VehicleType),
        []
    );

    const {
        Form,
        reset,
        formState: { errors },
    } = useForm<CreateAsset>({
        schema,
        isModal: true,
        mode: 'onChange',
        validateInitially: true,
        onSubmit: useCallback(
            (data: CreateAsset) => {
                createAsset(
                    {
                        vehicle_type: data.type,
                        make: data.make,
                        model: data.model,
                        year: data.year,
                        license_plate: data.licensePlate,
                        mileage: {
                            km: data.mileageStart as number,
                        },
                        max_passengers: data.maxPassengers,
                        engine_type: data.fuelType,
                    },
                    {
                        onSuccess: asset => {
                            handleConfirm(getAssetDtoToAsset(asset));
                        },
                    }
                );
            },
            [navigate]
        ),
    });

    const openChange = useIsChanged(open);
    useEffect(() => {
        if (openChange) reset({});
    }, [reset, openChange]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            sx={{
                '& .MuiDialog-container': { justifyContent: 'end' },
            }}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', maxWidth: '388px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                Add Asset
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    '& > div': {
                        pr: 2,
                        maxHeight: 'calc(100vh - 212px)',
                    },
                }}
            >
                <PerfectScrollbar>
                    <Form id={formId}>
                        <Stack
                            direction="column"
                            gap={2}
                            sx={{ pt: 2, width: '340px' }}
                        >
                            <Select
                                name="type"
                                label="Vehicle Type"
                                placeholder="Select Vehicle Type"
                                options={assetTypes}
                                showErrors
                                width="100%"
                            />
                            <TextField
                                placeholder="Type Make"
                                name="make"
                                label="Make"
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type Model"
                                name="model"
                                label="Model"
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type Year"
                                name="year"
                                label="Year"
                                regExp={/^\+?([0-9 ]+)?$/}
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type License Plate"
                                name="licensePlate"
                                label="License Plate"
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type Mileage Start Value"
                                name="mileageStart"
                                label="Mileage Start"
                                regExp={/^\+?([0-9 ]+)?$/}
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type Max Passengers"
                                name="maxPassengers"
                                label="Max Passengers"
                                regExp={/^\+?([0-9 ]+)?$/}
                                showErrors
                                fullWidth
                            />
                            <Select
                                name="fuelType"
                                label="Fuel Type"
                                placeholder="Select Fuel Type"
                                options={fuelOptions}
                                showErrors
                                width="100%"
                            />
                        </Stack>
                    </Form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Discard
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={!!errorFields.length}
                    sx={{
                        py: '10px',
                        '&&&': { pointerEvents: 'initial' },
                    }}
                >
                    Add Asset
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateAssetModal;
