/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetEventsResItem0BookingsItemSource1ReferenceDto } from './GetEventsResItem0BookingsItemSource1ReferenceDto';

export type GetEventsResItem0BookingsItemSource1Dto = {
    /**
     * type of the source
     */
    type: GetEventsResItem0BookingsItemSource1Dto.type;
    /**
     * List of customers
     */
    reference: GetEventsResItem0BookingsItemSource1ReferenceDto;
};

export namespace GetEventsResItem0BookingsItemSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
