import { travelerToTravelerForm } from '@travelity/web/src/components/booking-participants/booking-participants.converters';
import { TravelerForm } from '@travelity/web/src/components/booking-participants/booking-participants.types';
import {
    Booking,
    BookingProductOption,
    BookingProductPricing,
    BookingStatus,
    Transaction,
} from './booking.types';
import {
    type AgebandPricingData,
    type FlatPricingData,
    type GetBookingsResItem0Dto,
    type GetBookingsResItem1Dto,
    type GetEventsResItem0BookingsItemProductOptionsItemDto,
    GetEventsResItem0BookingsItemProductPricingDto,
    GetEventsResItem0BookingsItemSource0Dto,
    GetEventsResItem0BookingsItemTransactionsItemDto,
    ListItemAction,
    PersonNameData,
    UpdateCustomersBookingsReqItemDto,
    UpdateProductOptionsBookingsReqItemDto,
} from '../../requests';
import { convertNameDtoToFullName } from '../user/user.converters';
import {
    convertActivityDtoToActivity,
    convertDateToTimestamp,
    convertPeriodDataToPeriod,
    getStatusChangeReason,
} from '../common.converters';
import { SourceType } from '../common.types';
import { getCustomerDtoToCustomer } from '../customer/customer.converters';

export const convertBookingOptionToBookingOptionDto = (
    option: BookingProductOption
): Omit<UpdateProductOptionsBookingsReqItemDto, 'action'> => ({
    id: option.id,
    customers: {
        pax: option.pax,
    },
});

export const convertPricingDtoToPricing = (
    pricing: GetEventsResItem0BookingsItemProductPricingDto
): BookingProductPricing => ({
    id: pricing.id,
    type: pricing.type,
    currency: pricing.currency,
    paymentRequirements: pricing.payment_requirements,
    cost: pricing.cost?.original as FlatPricingData | AgebandPricingData,
    price: pricing.price.original as FlatPricingData | AgebandPricingData,
    discount: pricing.discount,
});

export const convertBookingOptionDtoToBookingOption = (
    option: GetEventsResItem0BookingsItemProductOptionsItemDto
): BookingProductOption => ({
    id: option.id,
    pax: option.customers.pax,
    pricing: option.pricing
        ? convertPricingDtoToPricing(option.pricing)
        : undefined,
    name: option.name,
    type: option.type,
});

type GetBookingsResItemDto = GetBookingsResItem0Dto | GetBookingsResItem1Dto;

export const convertTransactionDtoToTransaction = (
    transaction: GetEventsResItem0BookingsItemTransactionsItemDto
): Transaction => ({
    id: transaction.id,
    type: transaction.type,
    amount: transaction.amount.original as number,
    createdDate: new Date(transaction.created.at * 1000),
    method: transaction.method,
    discarded: transaction.discarded
        ? convertActivityDtoToActivity(transaction.discarded)
        : undefined,
});

export const getBookingDtoToBooking = (
    booking: GetBookingsResItemDto
): Booking => {
    let sourceName: string;
    if (
        booking.source.type ===
        GetEventsResItem0BookingsItemSource0Dto.type.DIRECT
    ) {
        sourceName = booking.source.name;
    } else {
        const name = booking.source.reference.name
            ? convertNameDtoToFullName(
                  booking.source.reference.name as PersonNameData
              )
            : undefined;
        sourceName =
            booking.source.reference.organization?.name ||
            name ||
            booking.source.reference.contact_details?.emails?.[0] ||
            '';
    }

    return {
        id: booking.id,
        status: booking.status,
        // TODO move to separate common converter
        reason:
            booking.status === BookingStatus.CANCELLED
                ? getStatusChangeReason(
                      booking.lifecycle,
                      BookingStatus.CANCELLED
                  )
                : undefined,
        lifecycle: booking.lifecycle?.map(convertActivityDtoToActivity) || [],
        notes: booking.notes?.items.map(v => v.content) || [],

        orderId: booking.order.id,
        orderNumber: booking.order.number,
        eventId: booking.event.id,
        date: convertPeriodDataToPeriod(booking.event.date),
        source: {
            type: booking.source.type as unknown as SourceType,
            name: sourceName,
        },
        product: {
            id: booking.product.id,
            type: booking.product.type,
            name: booking.product.name,
            pricing: convertPricingDtoToPricing(booking.product.pricing),
            options:
                booking.product.options?.items.map(o =>
                    convertBookingOptionDtoToBookingOption(o)
                ) || [],
        },
        financials: {
            debt: booking.financials.debt.original as number,
            cost: (booking.financials.cost?.original || 0) as number,
            subtotal: booking.financials.subtotal.original as number,
            total: booking.financials.total.original as number,
            paid: (booking.transactions?.paid?.original || 0) as number,
            waived: (booking.transactions?.waived?.original || 0) as number,
            refunded: (booking.transactions?.refunded?.original || 0) as number,
        },
        participants: {
            customer: getCustomerDtoToCustomer(booking.order.customer),
            pax: booking.product.customers.pax,
            travelers:
                booking.product.customers.items.map(travelerToTravelerForm) ||
                [],
        },
        permissions: {
            canEdit: !!booking.access.permissions?.update,
            canDelete: !!booking.access.permissions?.delete,
        },
        transactions:
            booking.transactions?.items?.map(
                convertTransactionDtoToTransaction
            ) || [],
    };
};

export const bookingFiltersToRequestBodyConverter = (
    filters: Record<string, any>
) => ({
    customerName: filters.customerName,
});

export const convertBookingTravelerToBookingTravelerDto = (
    traveler: TravelerForm
): Omit<UpdateCustomersBookingsReqItemDto, 'action'> => ({
    id: traveler.travelerId,
    pickup: traveler.pickUp
        ? {
              action: traveler.pickUp?.id
                  ? ListItemAction.UPDATE
                  : ListItemAction.ADD,
              id: traveler.pickUp.id,
              location: {
                  name: traveler.pickUp.name,
              },
              schedule: {
                  date: {
                      start: convertDateToTimestamp(traveler.pickUp.time),
                  },
              },
          }
        : undefined,
    plus_x: traveler.guestCount,
    reference: {
        id: traveler.customerId,
    },
});
