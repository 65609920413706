import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getAssetDtoToAsset } from './asset.converters';
import { CustomMutationOptions } from '../common.types';
import { AssetsService, CreateAssetReqDto } from '../../requests';

interface Filter {
    type?: string;
    byOwner?: boolean;
    byAssignee?: boolean;
    id?: string;
    email?: string;
    name?: string;
    usagePeriodStart?: number;
    usagePeriodEnd?: number;
    vehicleLicensePlate?: string;
    vehicleMake?: string;
    vehicleModel?: string;
    vehicleMinYear?: string;
    vehicleMaxYear?: string;
}

export const useDebouncedAssets = (search: string, debounce: number = 1000) => {
    const [debouncedParams, setDebouncedParams] = React.useState<
        Filter | undefined
    >();

    React.useEffect(() => {
        const handler = setTimeout(() => {
            const filter: Filter = {
                type: 'vehicle',
            };
            if (search.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                filter.email = search;
            else if (search) {
                filter.vehicleMake = search;
                filter.vehicleModel = search;
                filter.vehicleLicensePlate = search;
            }
            setDebouncedParams(
                Object.keys(filter).length > 1 ? filter : undefined
            );
        }, debounce);

        return () => {
            clearTimeout(handler);
        };
    }, [search, debounce]);

    const { getAccessTokenSilently } = useAuth0();

    const { data, ...other } = useQuery({
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const params = debouncedParams as Filter;

            return AssetsService.getAssets(
                authorization
                // TODO update this when back-end is ready
                // params.type,
                // params.byOwner,
                // params.byAssignee,
                // params.id,
                // params.email,
                // params.name,
                // params.usagePeriodStart,
                // params.usagePeriodEnd,
                // params.vehicleLicensePlate,
                // params.vehicleMake,
                // params.vehicleModel,
                // params.vehicleMinYear,
                // params.vehicleMaxYear
            );
        },
        queryKey: [debouncedParams],
        keepPreviousData: true,
        staleTime: debounce,
        enabled: !!debouncedParams,
    });

    const parsedData = useMemo(
        () =>
            debouncedParams && data?.items
                ? data?.items.map(getAssetDtoToAsset)
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
        isLoading: other.isFetching,
    };
};

export const useCreateAsset = (
    options?: CustomMutationOptions<
        Omit<CreateAssetReqDto, 'type'>,
        ReturnType<typeof AssetsService.createAsset>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async data => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return AssetsService.createAsset(authorization, {
                ...data,
                type: CreateAssetReqDto.type.VEHICLE,
            });
        },
        ...options,
        onSuccess: (...args) => {
            // Invalidate assets list
            options?.onSuccess?.(...args);
        },
    });
};
