/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserReqAccountMembershipsItem1Role5Dto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserReqAccountMembershipsItem1Role5Dto.type;
};

export namespace UpdateUserReqAccountMembershipsItem1Role5Dto {

    /**
     * Type of user's role
     */
    export enum type {
        EVENT_STAFF = 'event_staff',
    }


}
