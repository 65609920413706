/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateAssetReqAccessDto } from './CreateAssetReqAccessDto';
import type { CreateAssetReqMileageDto } from './CreateAssetReqMileageDto';
import type { CreateAssetReqOwnerDto } from './CreateAssetReqOwnerDto';
import type { VehicleEngineType } from './VehicleEngineType';
import type { VehicleType } from './VehicleType';

export type CreateAssetReqDto = {
    /**
     * type of the asset
     */
    type: CreateAssetReqDto.type;
    /**
     * Asset owner data
     */
    owner?: CreateAssetReqOwnerDto;
    /**
     * Access to the shared product
     */
    access?: CreateAssetReqAccessDto;
    /**
     * type of the asset
     */
    vehicle_type: VehicleType;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    engine_type: VehicleEngineType;
    /**
     * Vehicle mileage
     */
    mileage: CreateAssetReqMileageDto;
};

export namespace CreateAssetReqDto {

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
