import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { OrderItem, useCustomer, useUpdateCustomer } from '@travelity/api';
import { useForm } from '@travelity/form';

import { useSnackbar } from 'notistack';

import AccordionButtons from './accordion-buttons';
import { OrderFinancialInformationForm } from '../../../components/order-financial-information';
import financialInformationSchema from '../../../components/order-financial-information/order-financial-information.schema';
import { OrderParticipantsForm } from '../../../components/order-participants';
import participantsSchema from '../../../components/order-participants/order-participants.schema';
import { OrderAttachmentsForm } from '../../../components/order-attachments';
import attachmentsSchema from '../../../components/order-attachments/order-attachments.schema';
import { customerFormToCustomer } from '../../../components/booking-participants/booking-participants.converters';
import { OrderStatus } from '../../../components/order-status';
import { PaymentStatusTag } from '../../../components/payment-status';
import { ActivityHistory } from '../../../components/activity-history';

export interface BookingEditFormProps {
    order: OrderItem;
    isLoading: boolean;
}

const OrderPreview: React.FC<BookingEditFormProps> = ({ order, isLoading }) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>();

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    const { enqueueSnackbar } = useSnackbar();
    const { mutate: updateCustomer, isLoading: isCustomerSaving } =
        useUpdateCustomer(order.participants.customer, {
            onSuccess: () => {
                setEditing(false);
            },
            onError: (error: any) => {
                if (error?.status === 409) {
                    enqueueSnackbar('Nothing to save', { variant: 'error' });
                } else if (error?.body) {
                    enqueueSnackbar(error.body, { variant: 'error' });
                }
            },
        });
    const onSubmit = useCallback(
        (data: any, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            const key = e?.target.id;
            if (key === 'participants') {
                updateCustomer({
                    id: order.participants.customer.id,
                    customer: customerFormToCustomer(data.customers[0]),
                });
            }
        },
        [order]
    );

    const {
        Form: FinancialInformationForm,
        reset: resetFinancialInformation,
        // formState: { errors: detailsErrors },
    } = useForm({
        defaultValues: order.financials,
        onSubmit,
        mode: 'onChange',
        schema: financialInformationSchema,
    });
    const {
        Form: ParticipantsForm,
        reset: resetParticipants,
        formState: { errors: participantsErrors },
    } = useForm({
        defaultValues: {},
        onSubmit,
        mode: 'onChange',
        schema: participantsSchema,
    });
    const {
        Form: AttachmentsForm,
        reset: resetAttachments,
        formState: { errors: attachmentsErrors },
    } = useForm({
        defaultValues: { values: [] },
        onSubmit,
        mode: 'onChange',
        schema: attachmentsSchema,
    });

    const { data: customer } = useCustomer(order.participants.customer.id);
    useEffect(() => {
        if (customer) {
            resetParticipants({
                // customers: [travelerToTravelerForm(customer)],
            });
            setEditing(false);
        }
    }, [customer]);

    const reset = useCallback(() => {
        switch (expanded) {
            case 0:
                resetFinancialInformation(order.financials);
                break;
            case 1:
                if (customer) {
                    resetParticipants({
                        // customers: [travelerToTravelerForm(customer)],
                    });
                }
                break;
            case 2:
                resetAttachments({ values: [] });
                break;
            default:
        }
    }, [expanded, order]);

    const onCancel = useCallback(() => {
        reset();
        setEditing(false);
    }, [reset]);

    useEffect(() => {
        reset();
        setEditing(false);
    }, [order]);

    // const { setMainIsLoading } = useLayoutContext();
    // useEffect(() => {
    //     setMainIsLoading(isSaving);
    // }, [isSaving]);

    const { canEdit } = order.permissions;

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 30px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Order Preview
                </Box>
            </Stack>
            {!isLoading && (
                <PerfectScrollbar>
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            borderRadius: '0px 12px 0px 0px',
                        }}
                    >
                        <OrderStatus status={order.status} />

                        {order.financials?.status && (
                            <PaymentStatusTag
                                status={order.financials?.status}
                            />
                        )}
                    </Stack>
                    <Box
                        sx={{
                            '&&& .MuiAccordionSummary-root': {
                                borderBottom: '1px solid #DFE1ED',
                            },
                            '&&& .MuiAccordionDetails-root': {
                                py: 3,
                            },
                        }}
                    >
                        <FinancialInformationForm id="financialInformation">
                            <Accordion
                                disableGutters
                                disabled
                                expanded={expanded === 0}
                                onChange={() => toggleExpanded(0)}
                            >
                                <AccordionSummary>
                                    Pricing and Financials
                                </AccordionSummary>
                                <AccordionDetails>
                                    <OrderFinancialInformationForm
                                        preview={!editing || expanded !== 0}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </FinancialInformationForm>
                        <ParticipantsForm id="participants">
                            <Accordion
                                disableGutters
                                disabled
                                expanded={expanded === 1}
                                onChange={() => toggleExpanded(1)}
                            >
                                <AccordionSummary>
                                    Participants
                                    {expanded === 1 && canEdit && (
                                        <AccordionButtons
                                            editing={editing}
                                            errors={participantsErrors}
                                            onEditCancel={onCancel}
                                            onEdit={() => setEditing(true)}
                                        />
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <OrderParticipantsForm
                                        preview={!editing || expanded !== 1}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </ParticipantsForm>
                        <AttachmentsForm id="attachments">
                            <Accordion
                                disableGutters
                                disabled
                                expanded={expanded === 2}
                                onChange={() => toggleExpanded(2)}
                            >
                                <AccordionSummary>
                                    Attachments
                                    {expanded === 2 && canEdit && (
                                        <AccordionButtons
                                            editing={editing}
                                            saving={isCustomerSaving}
                                            errors={attachmentsErrors}
                                            onEditCancel={onCancel}
                                            onEdit={() => setEditing(true)}
                                        />
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <OrderAttachmentsForm
                                        preview={!editing || expanded !== 2}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </AttachmentsForm>
                        <Accordion
                            disableGutters
                            expanded={expanded === 3}
                            onChange={() => toggleExpanded(3)}
                        >
                            <AccordionSummary>Activity Log</AccordionSummary>
                            <AccordionDetails>
                                <ActivityHistory
                                    entity="order"
                                    lifecycle={order.lifecycle}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </PerfectScrollbar>
            )}
        </Box>
    );
};

export default React.memo(OrderPreview);
