/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateBookingResSource1ReferenceDto } from './CreateBookingResSource1ReferenceDto';
import type { CreateOrderResAccessDto } from './CreateOrderResAccessDto';
import type { OrderStatus } from './OrderStatus';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';

export type CreateOrderResDto = {
    /**
     * Type of the resource
     */
    resource_type: CreateOrderResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * Order number which will be displayed to the customer
     */
    number: string;
    /**
     * Information about the customer who booked the event.
     */
    customer: CreateBookingResSource1ReferenceDto;
    /**
     * Status of the order
     */
    status: OrderStatus;
    /**
     * Access to the shared product
     */
    access: CreateOrderResAccessDto;
};

export namespace CreateOrderResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ORDER = 'order',
    }


}
