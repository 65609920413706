import React, { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { useForm } from '@travelity/form';
import { Button } from '@travelity/ui';
import { object, string } from 'yup';

import OccupationSelect from '../components/occupation-select';

const schema = object().shape({
    occupation: string().required(),
});

export interface OccupationStepProps {
    occupation?: string;
    next: (v: string) => void;
}

export const OccupationStep: React.FC<OccupationStepProps> = props => {
    const { occupation, next } = props;

    const [showError, setShowErrors] = React.useState(false);

    const { Form, watch } = useForm({
        schema,
        onSubmit: useCallback(
            (data: { occupation: string }) => {
                next(data.occupation);
            },
            [next]
        ),
        defaultValues: {
            occupation,
        },
        mode: 'onChange',
        validateInitially: true,
    });

    return (
        <Form>
            <Stack
                sx={{
                    py: 5,
                    height: 1,
                    minHeight: 'calc(100vh - 84px)',
                }}
                gap={2}
                alignItems="center"
                justifyContent="center"
            >
                <Typography sx={{ color: '#C0C4CE', fontSize: '24px' }}>
                    What do you do?
                </Typography>
                <Stack>
                    <OccupationSelect
                        name="occupation"
                        showErrors={showError}
                    />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                        }}
                        requiredFields={
                            !watch('occupation') ? ['Occupation'] : undefined
                        }
                        doNotDisable
                        onIconClick={() => {
                            setShowErrors(true);
                        }}
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
