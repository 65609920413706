import React, { useCallback, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useForm } from '@travelity/form';
import {
    Language,
    User,
    userDetailsToUserDetailsDto,
    useRegister,
    UserOccupation,
    useUpdateUser,
} from '@travelity/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { RegisterUsersReqDto } from '@travelity/api/src/requests';
import { getLastCompletedStep } from './register-steps.utils';
import { PersonalInfo } from './components/personal-info';
import { OccupationStep } from './steps/occupation-step';
import { FullNameStep } from './steps/full-name-step';
import { LanguageStep } from './steps/language-step';
import { CurrencyStep } from './steps/currency-step';
import { TimeZoneStep } from './steps/timezone-step';
import { WorkweekStep } from './steps/workweek-step';

export interface RegisterStepsProps {
    user?: User;
}

export const RegisterSteps: React.FC<RegisterStepsProps> = props => {
    const { user } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { user: auth0User } = useAuth0();
    const [currentStep, setCurrentStep] = React.useState(
        getLastCompletedStep(user) + 1
    );

    const { mutate: create } = useRegister({
        onSuccess: () => {
            setCurrentStep(currentStep + 1);
        },
        onError: () => {
            enqueueSnackbar('Failed to register', {
                variant: 'error',
            });
        },
    });
    const { mutate: update } = useUpdateUser({
        onSuccess: () => {
            setCurrentStep(currentStep + 1);
        },
        onError: () => {
            enqueueSnackbar('Failed to update user', {
                variant: 'error',
            });
        },
    });

    const navigate = useNavigate();

    const onBack = useCallback(() => {
        setCurrentStep(prev => (prev === 7 ? prev - 2 : prev - 1));
    }, []);

    const { Form, getValues, setValue, watch, submit } = useForm({
        onSubmit: useCallback(
            (data: Partial<User>) => {
                if (currentStep === 7 && auth0User?.email) {
                    create(
                        userDetailsToUserDetailsDto({
                            ...data,
                            subscription: 'free',
                        }) as RegisterUsersReqDto
                    );
                } else if (currentStep === 8) {
                    update(
                        userDetailsToUserDetailsDto({
                            ...data,
                            subscription: 'free',
                        })
                    );
                }
            },
            [currentStep, auth0User]
        ),
        defaultValues: user || { subscription: 'free' },
        mode: 'onChange',
        validateInitially: true,
    });

    useEffect(() => {
        if (auth0User) {
            const userForm = getValues();
            if (!userForm.emails?.length) {
                setValue('emails', [{ value: auth0User.email as string }]);
            }
        }
    }, [auth0User]);

    return (
        <Box
            sx={{
                bgcolor: '#fff',
                mt: 2,
                height: 'calc(100% - 16px)',
                '> form': { height: 1 },
            }}
        >
            <PerfectScrollbar>
                {currentStep === 1 && (
                    <OccupationStep
                        occupation={watch('occupation')}
                        next={occupation => {
                            setValue(
                                'occupation',
                                occupation as UserOccupation
                            );
                            setCurrentStep(currentStep + 1);
                        }}
                    />
                )}
                {currentStep === 2 && (
                    <FullNameStep
                        fullName={watch('fullName')}
                        back={onBack}
                        next={fullName => {
                            setValue('fullName', fullName);
                            setCurrentStep(currentStep + 1);
                        }}
                    />
                )}
                {currentStep === 3 && (
                    <LanguageStep
                        languages={watch('languages')}
                        back={onBack}
                        next={l => {
                            setValue('languages', l as Language[]);
                            setCurrentStep(currentStep + 1);
                        }}
                    />
                )}
                {currentStep === 4 && (
                    <CurrencyStep
                        currency={watch('currency')}
                        back={onBack}
                        next={c => {
                            setValue('currency', c);
                            setCurrentStep(currentStep + 1);
                        }}
                    />
                )}
                {currentStep === 5 && (
                    <TimeZoneStep
                        timeZone={watch('timeZone')}
                        back={onBack}
                        next={t => {
                            setValue('timeZone', t);
                            setCurrentStep(currentStep + 2);
                        }}
                    />
                )}
                {currentStep === 7 && (
                    <WorkweekStep
                        weekdays={watch('weekdays')}
                        weekdayHours={watch('weekdayHours')}
                        back={onBack}
                        next={(workdays, weekdayHours) => {
                            setValue('weekdays', workdays);
                            setValue('weekdayHours', weekdayHours);
                            submit();
                        }}
                    />
                )}
                {currentStep > 7 && (
                    <Form>
                        <Stack
                            sx={{
                                py: 5,
                                height: 1,
                                minHeight: 'calc(100vh - 84px)',
                            }}
                            gap={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography
                                sx={{
                                    color: '#C0C4CE',
                                    fontSize: '24px',
                                }}
                            >
                                Personal Information
                            </Typography>
                            <PersonalInfo
                                onSkip={() => {
                                    navigate(0);
                                }}
                            />
                        </Stack>
                    </Form>
                )}
            </PerfectScrollbar>
        </Box>
    );
};
