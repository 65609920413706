/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ComputePriceBookingsReqSource1ReferenceDto } from './ComputePriceBookingsReqSource1ReferenceDto';

export type ComputePriceBookingsReqSource1Dto = {
    /**
     * type of the source
     */
    type: ComputePriceBookingsReqSource1Dto.type;
    /**
     * List of customers
     */
    reference: ComputePriceBookingsReqSource1ReferenceDto;
};

export namespace ComputePriceBookingsReqSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
