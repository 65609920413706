import React from 'react';
import { Box, Stack } from '@mui/material';
import AccessTime from '@mui/icons-material/AccessTime';
import {
    TextField,
    DurationField,
    CardList,
    requiredDuration,
    ToggleButton,
} from '@travelity/form';
import { object, string } from 'yup';
import { durationToString } from '@travelity/utils';
import { Tag } from '@travelity/ui';
import { ReactComponent as RotateLocation } from '../../assets/rotate-location.svg';

export interface ProductRouteFormProps {
    preview?: boolean;
    showFull?: boolean;
    showErrors?: boolean;
}

const schema = object({
    name: string().required('Name is a required field').uniqName(),
    arrivalOffset: object()
        .when(['$items', 'id'], ([items, id], sch) => {
            const currentIndex = items.findIndex((i: any) => i.id === id);
            return currentIndex > 0 ? requiredDuration : sch;
        })
        .when(['$items', 'id'], ([items, id], sch, { value }) => {
            const offsetExists = items.find(
                (i: any) =>
                    i.id !== id &&
                    durationToString(i.arrivalOffset) ===
                        durationToString(value)
            );
            return offsetExists
                ? sch.test('unique', 'Duplicate offset', () => false)
                : sch;
        }),
});

const ProductRouteForm: React.FC<ProductRouteFormProps> = ({
    preview,
    showFull,
    showErrors,
}) => {
    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <CardList
                showErrors={showErrors}
                formParams={{ schema }}
                disabled={preview}
                maxHeight={showFull ? undefined : 356}
                disableReorder
                renderHeader={({ editing, item }, index) =>
                    editing ? (
                        <>
                            <TextField
                                sx={{ mx: '10px' }}
                                autoFocus
                                InputProps={{
                                    sx: { fontSize: '14px', pl: 1.5 },
                                }}
                                placeholder="Type location name"
                                name="name"
                                variant="standard"
                                showErrors
                                helperText=""
                                fullWidth
                            />
                            {index === 0 && (
                                <ToggleButton name="repeatLocation">
                                    <RotateLocation />
                                </ToggleButton>
                            )}
                        </>
                    ) : (
                        <Stack
                            sx={{ pl: 1, width: 1 }}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                component="span"
                                title={item.name}
                                sx={{
                                    overflowX: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    lineHeight: 2,
                                }}
                            >
                                {item.name}
                            </Box>
                            {item.repeatLocation && <Tag value="Round Trip" />}
                        </Stack>
                    )
                }
                renderContent={({ item, editing }) => (
                    <Stack
                        sx={{
                            p: 1,
                        }}
                        direction="row"
                        gap={1}
                    >
                        <Box
                            sx={{
                                width: '36px',
                                height: '36px',
                                color: 'primary.contrastText',
                                bgcolor: 'primary.main',
                                borderRadius: '12px',
                                p: '6px',
                                mb: 1,
                                ml: 1,
                                mt: editing ? 2 : 0.5,
                            }}
                        >
                            <AccessTime />
                        </Box>
                        <Stack flexGrow={2} sx={{ p: 0.5, pr: 1 }} gap={0.5}>
                            {!editing && (
                                <>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Arrival Offset
                                        </Box>
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#2B395B',
                                            }}
                                        >
                                            {durationToString(
                                                item.arrivalOffset
                                            )}
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Stop Duration
                                        </Box>
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#2B395B',
                                            }}
                                        >
                                            {durationToString(item.duration)}
                                        </Box>
                                    </Stack>
                                </>
                            )}
                            {editing && (
                                <>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Arrival Offset
                                        </Box>
                                        <Box>
                                            <DurationField
                                                name="arrivalOffset"
                                                showErrors
                                            />
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Stop Duration
                                        </Box>
                                        <Box>
                                            <DurationField name="duration" />
                                        </Box>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </Stack>
                )}
                addButtonText="Add Stop"
                name="stops"
            />
        </Stack>
    );
};

export default React.memo(ProductRouteForm);
