/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ResourceType } from './ResourceType';
import type { TeamSubscriptionUserFeaturesData } from './TeamSubscriptionUserFeaturesData';

export type TeamSubscriptionData = {
    /**
     * Name of the subscription
     */
    name?: TeamSubscriptionData.name;
    /**
     * Team subscription user configuration
     */
    user?: TeamSubscriptionUserFeaturesData;
    /**
     * List of supported resources
     */
    resources?: Array<ResourceType>;
};

export namespace TeamSubscriptionData {

    /**
     * Name of the subscription
     */
    export enum name {
        TEAM = 'team',
    }


}
