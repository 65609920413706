import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Traveler } from '@travelity/api';
import { NumberField } from '@travelity/form';
import { DataRow } from '../../data-row';

export interface BookingTravelerCardProps {
    item: Partial<Traveler>;
    editing: boolean;
    travelerCap?: number;
}

const BookingTravelerCard: React.FC<BookingTravelerCardProps> = ({
    item,
    editing,
    travelerCap = 0,
}) => {
    return (
        <Stack gap={0.5} p={2}>
            {editing ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    sx={{ mt: -0.75 }}
                >
                    <Typography
                        sx={{
                            color: '#949BAC',
                            fontSize: '14px',
                        }}
                    >
                        Accompanying Travelers
                    </Typography>
                    <NumberField
                        size="small"
                        name="guestCount"
                        label=""
                        showZero
                        max={
                            item.travelerId
                                ? travelerCap
                                : Math.max(0, travelerCap - 1)
                        }
                    />
                </Stack>
            ) : (
                <DataRow
                    label="Accompanying Travelers"
                    value={`${item.guestCount || 0}`}
                    darkValue
                    tagValue
                    sx={{ mb: 1, mt: '3px' }}
                />
            )}
        </Stack>
    );
};

export default React.memo(BookingTravelerCard);
