/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PersonNameData } from './PersonNameData';

export type UpdateUserResLastUpdatedByDto = {
    /**
     * User's occupation
     */
    type: UpdateUserResLastUpdatedByDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * name of the referenced user
     */
    name?: PersonNameData;
};

export namespace UpdateUserResLastUpdatedByDto {

    /**
     * User's occupation
     */
    export enum type {
        HUMAN = 'human',
    }


}
