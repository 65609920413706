import React from 'react';
import { Box, Typography } from '@mui/material';

import { ReactComponent as PreviewSvg } from './assets/preview.svg';

export interface ListSidebarProps {
    children?: React.ReactNode;
    isEmpty?: boolean;
    placeholderText?: string;
}

export const ListSidebar: React.FC<ListSidebarProps> = React.memo(
    (props: ListSidebarProps) => {
        const { children, isEmpty, placeholderText } = props;

        return (
            <Box
                sx={{
                    bgcolor: '#FFF',
                    my: 2,
                    ml: 2,
                    width: '483px',
                    minWidth: '483px',
                    position: 'relative',
                    height: 'calc(100% - 32px)',
                    ...(isEmpty && {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                    }),
                }}
            >
                {isEmpty ? (
                    <>
                        <PreviewSvg />
                        <Typography sx={{ color: '#949BAC' }}>
                            {placeholderText}
                        </Typography>
                    </>
                ) : (
                    children
                )}
            </Box>
        );
    }
);
