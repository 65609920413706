import React, { memo, useEffect, useMemo } from 'react';
import { Badge, Box, Button, Stack, Typography } from '@mui/material';
import { useForm } from '@travelity/form';
import { useWatch } from 'react-hook-form';
import { useProducts } from '@travelity/api';
import { FilterItem } from './components/filter-item';
import { FilterOption, FilterValue } from './filters.types';
import { FilterButton } from './components/filter-button';
import { useSelectOptions } from '../../hooks';

export interface FiltersProps {
    values: Record<string, FilterValue> | {};
    setValues: (v: Record<string, FilterValue>) => void;
    options: FilterOption[];
}

export const Filters: React.FC<FiltersProps> = memo((props: FiltersProps) => {
    const { values: appliedValues, setValues: applyValues, options } = props;
    const { Form, reset, control, getValues } = useForm({
        defaultValues: appliedValues,
        mode: 'onChange',
    });

    // Fill products
    const { data: products } = useProducts(undefined, {
        enabled: !options.find(o => o.name === 'products')?.options?.length,
    });
    const productFilterOptions = useSelectOptions(products, 'name', 'id', true);
    const filledOptions = useMemo(() => {
        return options.map(filter =>
            filter.name === 'products'
                ? {
                      ...filter,
                      options: productFilterOptions,
                  }
                : filter
        );
    }, [options, productFilterOptions]);

    useWatch({ control });

    const values = getValues();
    const changed = useMemo(() => {
        const appliedString = JSON.stringify(appliedValues);
        const valuesString = JSON.stringify(values);

        return appliedString !== valuesString;
    }, [appliedValues, values]);

    useEffect(() => {
        if (appliedValues) reset(appliedValues);
    }, [appliedValues, reset]);

    const valuesArray = Object.keys(values)
        .map(name => ({
            name,
            value: values[name],
        }))
        .filter(
            ({ value, name }) =>
                value && filledOptions.find(o => name === o.name)
        );

    useEffect(() => {
        if (changed && !valuesArray.length) applyValues({});
    }, [changed, valuesArray, applyValues]);

    return (
        <Box sx={{ mb: 0.5 }}>
            <Stack
                direction="row"
                justifyContent={!valuesArray.length ? 'left' : 'space-between'}
                gap={1}
                alignItems="center"
                sx={{
                    py: 1,
                    height: '56px',
                }}
            >
                {!valuesArray.length && (
                    <Typography
                        sx={{
                            color: '#B2B9CD',
                            fontSize: '20px',
                            fontWeight: '700',
                        }}
                    >
                        Configure Filtering
                    </Typography>
                )}
                {!!valuesArray.length && (
                    <Stack
                        sx={{ flexGrow: 2, borderRight: '#DFE2EC 1px solid' }}
                    >
                        <Form>
                            <Stack direction="row" gap={1}>
                                {valuesArray.map(filter => (
                                    <FilterItem
                                        key={filter.name}
                                        value={filter.value}
                                        filter={
                                            filledOptions.find(
                                                ({ name }) =>
                                                    name === filter.name
                                            ) as FilterOption
                                        }
                                    />
                                ))}
                            </Stack>
                        </Form>
                    </Stack>
                )}
                {!!valuesArray.length &&
                    (changed ? (
                        <Badge
                            color="warning"
                            variant="dot"
                            badgeContent=" "
                            sx={{
                                '.MuiBadge-badge': { top: '4px', right: '4px' },
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => applyValues(values)}
                            >
                                Filter
                            </Button>
                        </Badge>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => applyValues(values)}
                        >
                            Filter
                        </Button>
                    ))}

                <FilterButton
                    selectedFilters={values}
                    updateSelectedFilters={reset}
                    options={filledOptions}
                />
            </Stack>
        </Box>
    );
});
