import React, { useMemo, useState } from 'react';
import {
    Booking,
    BookingStatus,
    SourceType,
    TransactionType,
    useAddBookingTransaction,
    useCancelBooking,
    useHoldBookingAvailability,
    useReserveBooking,
} from '@travelity/api';
import { Box, Divider, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { durationToHumanReadable, formatMoney } from '@travelity/utils';
import {
    Card,
    ConfirmationDialog,
    IconButton,
    IconButtonGroup,
    SquareCard,
    StatusTag,
    StatusTagStatuses,
    Tag,
} from '@travelity/ui';
import { formatValue } from '@travelity/form';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TransactionModal } from './components/transaction-modal';
import { ReasonModal } from './components/reason-modal';
import BookingItemSkeleton from './components/booking-item-skeleton';
import { OverlayWithReason } from '../overlay-with-reason';
import HoldAvailabilityDialog from './components/hold-availability-dialog';

export interface BookingItemProps {
    booking: Booking;
    isSelected: boolean;
    refetch: () => void;
}

const BookingItem: React.FC<BookingItemProps> = ({
    booking,
    refetch,
    isSelected,
}) => {
    const { t: pt } = useTranslation('product');
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        id,
        status,
        reason,
        orderId,
        date,
        source,

        participants,
        financials,
        product,
        transactions,
    } = booking;

    const { pricing } = product;

    const [deletingBooking, setDeletingBooking] = useState<string>();

    const [payDraft, setPayDraft] = useState(false);
    const [holdBooking, setHoldBooking] = useState(false);
    const { mutate: holdAvailability, isLoading: isHoldBookingLoading } =
        useHoldBookingAvailability({
            onSuccess: () => {
                enqueueSnackbar('The booking is temporarily held', {
                    variant: 'success',
                });
                setHoldBooking(false);
                refetch();
            },
            onError: () => {
                enqueueSnackbar('Failed to temporarily hold the booking', {
                    variant: 'error',
                });
            },
        });
    const { mutate: reserve, isLoading: isReserveLoading } = useReserveBooking({
        onSuccess: () => {
            enqueueSnackbar('The booking is reserved', {
                variant: 'success',
            });
            setHoldBooking(false);
            refetch();
        },
        onError: () => {
            enqueueSnackbar('Failed to reserve the booking', {
                variant: 'error',
            });
        },
    });
    const [refundBooking, setRefundBooking] = useState(false);
    const [payBooking, setPayBooking] = useState(false);
    const { mutate: addTransaction, isLoading: isAddTransactionLoading } =
        useAddBookingTransaction({
            onSuccess: (data, transaction) => {
                setRefundBooking(false);
                setPayBooking(false);
                refetch();
                enqueueSnackbar(
                    `${
                        transaction.type === TransactionType.PAYMENT
                            ? 'Payment'
                            : 'Refund'
                    } of ${transaction.amount} ${
                        pricing.currency.abbr
                    } has been added`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: (err, transaction) => {
                enqueueSnackbar(
                    `Failed to add the ${
                        transaction.type === TransactionType.PAYMENT
                            ? 'payment'
                            : 'refund'
                    } of ${transaction.amount} ${pricing.currency.abbr}`,
                    {
                        variant: 'error',
                    }
                );
            },
        });
    const { mutate: removeBooking, isLoading: isRemoveBookingLoading } =
        useCancelBooking({
            onSuccess: () => {
                setDeletingBooking(undefined);
                refetch();
                enqueueSnackbar(
                    `Cancelled the booking for the product "${product.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: () => {
                enqueueSnackbar(
                    `Failed to cancel the booking for the product "${product.name}"`,
                    {
                        variant: 'error',
                    }
                );
            },
        });

    const duration = date.duration
        ? durationToHumanReadable(date.duration)
        : '';

    const discountFormatted =
        pricing.discount?.type === 'absolute'
            ? formatMoney(pricing.discount?.amount, pricing.currency.abbr)
            : `${pricing.discount?.amount}%`;

    const finalPrice =
        pricing && financials
            ? formatMoney(financials.total, pricing.currency.abbr)
            : '';

    const sourceValue = useMemo(() => {
        // @ts-ignore
        return source.type === SourceType.DIRECT
            ? // @ts-ignore
              t(source.name, { ns: 'team' })
            : source.name;
    }, [source]);

    return (
        <>
            <Card
                flexGrow={2}
                sx={{
                    height: '192px',
                }}
                isSelected={isSelected}
                direction="row"
                justifyContent="space-evenly"
                buttons={
                    status !== BookingStatus.CANCELLED ? (
                        <>
                            {booking.permissions.canEdit && (
                                <IconButtonGroup>
                                    <IconButton
                                        color="#37C16E"
                                        hoverColor="#164d2c"
                                        onClick={() => {
                                            if (
                                                status === BookingStatus.DRAFT
                                            ) {
                                                setPayDraft(true);
                                            } else {
                                                setPayBooking(true);
                                            }
                                        }}
                                        disabled={!financials.debt}
                                        icon={
                                            <PaymentsOutlinedIcon fontSize="small" />
                                        }
                                        tooltip="Pay"
                                    />
                                    <Divider sx={{ mx: 0.75 }} />
                                    <IconButton
                                        onClick={() => setRefundBooking(true)}
                                        icon={<ReplayIcon fontSize="small" />}
                                        disabled={!transactions?.length}
                                        tooltip="Refund"
                                    />
                                </IconButtonGroup>
                            )}
                            <IconButtonGroup>
                                {booking.permissions.canEdit &&
                                    status !== BookingStatus.CONFIRMED && (
                                        <>
                                            <IconButton
                                                onClick={() =>
                                                    setHoldBooking(true)
                                                }
                                                icon={
                                                    <CheckCircleIcon fontSize="small" />
                                                }
                                                color={
                                                    status ===
                                                    BookingStatus.HOLD
                                                        ? 'warning.main'
                                                        : undefined
                                                }
                                                hoverColor={
                                                    status ===
                                                    BookingStatus.HOLD
                                                        ? 'warning.dark'
                                                        : undefined
                                                }
                                                disabled={
                                                    status !==
                                                        BookingStatus.DRAFT &&
                                                    status !==
                                                        BookingStatus.HOLD
                                                }
                                                tooltip="Hold Availability"
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                        </>
                                    )}
                                <IconButton
                                    href={`/order/${orderId}/booking/${id}`}
                                    icon={<VisibilityIcon fontSize="small" />}
                                    tooltip="Preview"
                                />
                                {booking.permissions.canDelete && (
                                    <>
                                        <Divider sx={{ mx: 0.75 }} />
                                        <IconButton
                                            hoverColor="error.main"
                                            onClick={() =>
                                                setDeletingBooking(id)
                                            }
                                            icon={
                                                <DeleteIcon fontSize="small" />
                                            }
                                            tooltip="Cancel"
                                        />
                                    </>
                                )}
                            </IconButtonGroup>
                        </>
                    ) : (
                        <IconButtonGroup>
                            <IconButton
                                href={`/order/${orderId}/${id}`}
                                icon={<VisibilityIcon fontSize="small" />}
                                tooltip="Preview"
                            />
                        </IconButtonGroup>
                    )
                }
            >
                <Stack
                    sx={{
                        width: '50%',
                        filter:
                            status === BookingStatus.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '12px 0px 0px 0px',
                        }}
                    >
                        <Box
                            component="span"
                            sx={{
                                color: '#6B748C',
                                pr: 1,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {product.name}
                        </Box>
                        <Tag label="Type" value={pt(product.type)} />
                    </Stack>
                    <Stack
                        sx={{
                            grow: 2,
                            pl: 2,
                            pr: 2 + 2,
                            py: '10px',
                            height: '144px',
                        }}
                        direction="row"
                        justifyContent="space-evenly"
                        gap={1}
                    >
                        <SquareCard
                            title="Start Date"
                            value={format(date.start, 'dd MMM yyyy')}
                            Icon={CalendarMonthIcon}
                        />
                        <SquareCard
                            title="Start Time"
                            value={format(date.start, 'HH:mm')}
                            Icon={AccessTimeIcon}
                        />
                        <SquareCard
                            title="Duration"
                            value={duration}
                            Icon={AccessTimeIcon}
                        />
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: '50%',
                        borderLeft: '2px solid #C9CEDC',
                        position: 'relative',
                        filter:
                            status === BookingStatus.CANCELLED
                                ? 'blur(2px)'
                                : undefined,
                    }}
                >
                    <Box
                        component={PerfectScrollbar}
                        sx={{
                            '&&': { height: '46px' },
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                        option={{
                            suppressScrollY: true,
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={2}
                            sx={{
                                px: 2,
                                height: '46px',
                                py: '10px',
                                bgcolor: '#F4F6FA',
                                borderRadius: '0px 12px 0px 0px',
                            }}
                        >
                            <Box
                                component="span"
                                title="Booking Summary"
                                sx={{
                                    color: '#6B748C',
                                    pr: 1,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                Booking Summary
                            </Box>
                            <Stack direction="row" gap={1}>
                                {sourceValue && (
                                    <Tag label="Source" value={sourceValue} />
                                )}
                                {financials.debt !== undefined && (
                                    <StatusTag
                                        status={StatusTagStatuses.NEUTRAL}
                                        label="Debt"
                                        value={formatMoney(
                                            financials.debt,
                                            pricing.currency.abbr
                                        )}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Box>
                    <Stack
                        sx={{
                            height: '144px',
                            overflow: 'hidden',
                            p: 2,
                            py: '10px',
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}
                        >
                            <Box
                                sx={{
                                    color: '#6B748C',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                }}
                            >
                                PAX
                            </Box>
                            <Box
                                sx={{
                                    color: '#2B395B',
                                    textAlign: 'right',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                }}
                            >
                                {formatValue(participants.pax)}
                            </Box>
                        </Stack>
                        <Divider sx={{ mb: 1 }} />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}
                        >
                            <Box
                                sx={{
                                    color: '#6B748C',
                                    fontSize: '13px',
                                    fontWeight: '500',
                                }}
                            >
                                Total Before Discount
                            </Box>
                            <Box
                                sx={{
                                    color: '#6B758D',
                                    textAlign: 'right',
                                    fontSize: '13px',
                                    fontWeight: '500',
                                }}
                            >
                                {formatMoney(
                                    financials.subtotal,
                                    pricing.currency.abbr
                                )}
                            </Box>
                        </Stack>
                        {!!pricing.discount?.amount && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mb: 1 }}
                            >
                                <Box
                                    sx={{
                                        color: '#6B748C',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                    }}
                                >
                                    Discount
                                </Box>
                                <Box
                                    sx={{
                                        color: '#6B758D',
                                        textAlign: 'right',
                                        fontSize: '13px',
                                        fontWeight: '400',
                                    }}
                                >
                                    {discountFormatted}
                                </Box>
                            </Stack>
                        )}
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mt: 1, mb: 1.5 }}
                        >
                            <Box
                                sx={{
                                    color: '#6B748C',
                                    fontSize: '15px',
                                    fontWeight: '500',
                                }}
                            >
                                {pricing.discount?.amount
                                    ? 'Total after discount'
                                    : 'Total'}
                            </Box>
                            <Box
                                sx={{
                                    color: '#2B395B',
                                    textAlign: 'right',
                                    fontSize: '15px',
                                    fontWeight: '500',
                                }}
                            >
                                {finalPrice}
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
                {status === BookingStatus.CANCELLED && (
                    <OverlayWithReason title="Cancelled" reason={reason} />
                )}
            </Card>
            <ReasonModal
                title="Are you sure you want to cancel this booking?"
                content="Provide the reason for cancelling this booking"
                confirmText="Confirm"
                open={!!deletingBooking}
                isLoading={isRemoveBookingLoading}
                handleCancel={() => setDeletingBooking(undefined)}
                handleConfirm={message => {
                    removeBooking({
                        bookingId: deletingBooking as string,
                        reason: message,
                    });
                }}
            />
            <HoldAvailabilityDialog
                open={holdBooking}
                isLoading={isHoldBookingLoading || isReserveLoading}
                handleCancel={() => setHoldBooking(false)}
                handleReserve={() => {
                    reserve({ bookingId: id as string });
                }}
                handleHold={() => {
                    holdAvailability({ bookingId: id as string });
                }}
                disableHold={status === BookingStatus.HOLD}
                disableReserve={!participants.travelers?.length}
            />
            <ConfirmationDialog
                title="Cannot accept a payment for a draft booking"
                content="Please select one of the following actions"
                confirmText="Hold Availability"
                open={payDraft}
                handleCancel={() => setPayDraft(false)}
                handleConfirm={() => {
                    holdAvailability(
                        { bookingId: id as string },
                        {
                            onSuccess: () => {
                                setPayDraft(false);
                                setPayBooking(true);
                            },
                        }
                    );
                }}
            />
            <TransactionModal
                currency={pricing.currency.abbr}
                title="Refund a Booking"
                content=""
                confirmText="Refund"
                open={refundBooking}
                isLoading={isAddTransactionLoading}
                handleCancel={() => setRefundBooking(false)}
                handleConfirm={({ method, amount }) => {
                    addTransaction({
                        bookingId: id as string,
                        type: TransactionType.REFUND,
                        method,
                        amount,
                    });
                }}
            />
            <TransactionModal
                currency={pricing.currency.abbr}
                title="Pay For a Booking"
                content=""
                isLoading={isAddTransactionLoading}
                confirmText="Pay"
                open={payBooking}
                availableMethods={pricing.paymentRequirements.methods}
                handleCancel={() => setPayBooking(false)}
                handleConfirm={({ method, amount }) => {
                    addTransaction({
                        bookingId: id as string,
                        type: TransactionType.PAYMENT,
                        method,
                        amount,
                    });
                }}
            />
        </>
    );
};

export { BookingItemSkeleton };

export default React.memo(BookingItem);
