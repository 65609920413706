/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetTeamsResItemMembersItemMembership1GroupDto = {
    /**
     * Type of the user group
     */
    type: GetTeamsResItemMembersItemMembership1GroupDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
};

export namespace GetTeamsResItemMembersItemMembership1GroupDto {

    /**
     * Type of the user group
     */
    export enum type {
        TEAM = 'team',
    }


}
