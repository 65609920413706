import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
} from '../common.types';
import { scheduleDtoToScheduleItem } from './schedule.converters';
import {
    CreateManySchedulesReqItemDto,
    SchedulesService,
} from '../../requests';

// Get all products lazy
interface UseSchedulesLazyKeyData {
    ids?: Array<string>;
    pageNumber?: number;
    pageSize?: number;
}

export const useSchedulesLazyKey = 'useSchedulesLazyKey';

export const useSchedulesLazy = (
    params: UseSchedulesLazyKeyData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof SchedulesService.getSchedules>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 20;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useSchedulesLazyKey, params],
        queryFn: async ({ pageParam = 0 }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return SchedulesService.getSchedules(
                authorization,
                params.ids,
                pageParam,
                params.pageSize
            );
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage.items || lastPage.items.length < pageSize)
                return undefined;
            return allPages.length;
        },
        ...options,
    });

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(scheduleDtoToScheduleItem)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useSchedulesKey = 'useSchedulesKey';

export const useSchedules = (
    ids: string[] = [],
    options: CustomQueryOptions<
        ReturnType<typeof SchedulesService.getSchedules>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: [useSchedulesKey, ids],
        enabled: !!ids.length,
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return SchedulesService.getSchedules(authorization, ids, 0, 100);
        },
        ...options,
    });
};

export const useCreateSchedule = (
    options: CustomMutationOptions<
        CreateManySchedulesReqItemDto,
        ReturnType<typeof SchedulesService.createManySchedules>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async schedule => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return SchedulesService.createManySchedules(authorization, {
                items: [schedule],
            });
        },
        ...options,
        onSuccess: (...args) => {
            // TODO invalidate schedule list
            options?.onSuccess?.(...args);
        },
    });
};
