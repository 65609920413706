import {
    InfiniteData,
    useInfiniteQuery,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TravelerForm } from '@travelity/web/src/components/booking-participants/booking-participants.types';
import {
    BookingNote,
    BookingProductOption,
    BookingStatus,
} from './booking.types';
import {
    BookingsService,
    EventsService,
    ListItemAction,
    ProductOptionType,
    ProductType,
    type GetBookingsResItem0Dto,
    type GetBookingsResItem1Dto,
    type UpdateEventBookingsResDto,
    type UpdateEventBookingsRes1Dto,
    type GetBookingResDto,
    type GetBookingRes1Dto,
    type GetBookingsResDto,
    CreateBookingReqSource0Dto,
    CreateBookingReqSource1Dto,
    AttendanceType,
    GetEventsResDto,
    GetEventsResItem0Dto,
    GetEventsResItem1Dto,
    GetEventResDto,
    GetEventRes1Dto,
} from '../../requests';
import {
    convertBookingOptionToBookingOptionDto,
    convertBookingTravelerToBookingTravelerDto,
    getBookingDtoToBooking,
} from './booking.converters';
import { useUpdateOrderBooking } from '../order/order.hooks';
import {
    useEventKey,
    useEventsLazyKey,
    useUpdateEventBooking,
} from '../event/event.hooks';
import { EventItem } from '../event/event.types';
import { Customer } from '../customer/customer.types';
import { AvailableEvent } from '../availability/availability.types';
import { Referral } from '../team/team.types';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
    SourceType,
    PaxData,
    TransactionType,
    DirectSource,
    TransactionMethod,
} from '../common.types';
import {
    convertItemsToActionItemsDto,
    convertPeriodToPeriodData,
} from '../common.converters';
import { customerToOrderCustomerDto } from '../customer/customer.converters';

const mergeItems = (
    item: GetBookingsResItem0Dto | GetBookingsResItem1Dto,
    newItem: UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto
): GetBookingsResItem0Dto | GetBookingsResItem1Dto =>
    ({
        ...item,
        ...newItem,
    } as GetBookingsResItem0Dto | GetBookingsResItem1Dto);

const updateBooking = (
    item: EventDto,
    newItem: UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto
    // @ts-ignore-next-line
): EventDto => ({
    ...item,
    bookings: item.bookings.map(b =>
        b.id === newItem.id ? mergeItems(b, newItem) : b
    ),
});

export const useBookingsLazyKey = 'useBookingsLazyKey';
const useBookingKey = 'useBookingKey';

type EventDto =
    | GetEventsResItem0Dto
    | GetEventsResItem1Dto
    | GetEventResDto
    | GetEventRes1Dto;

export const useBookingCache = () => {
    const queryClient = useQueryClient();

    const update = (
        id: string,
        newItem: UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto
    ) => {
        queryClient
            .getQueriesData({
                queryKey: [useBookingsLazyKey],
                exact: false,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<InfiniteData<GetBookingsResDto>>(
                    queryKey,
                    data => {
                        if (!data?.pages) return undefined;
                        const pages = data.pages.map(page => {
                            const items =
                                page.items?.map(item =>
                                    item.id === id
                                        ? mergeItems(item, newItem)
                                        : item
                                ) || [];
                            return { ...data, items };
                        });
                        return { ...data, pages };
                    }
                );
            });
        queryClient
            .getQueriesData({
                queryKey: [useBookingKey, id],
                exact: true,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<GetBookingResDto | GetBookingRes1Dto>(
                    queryKey,
                    (data?: GetBookingResDto | GetBookingRes1Dto) => {
                        return data
                            ? (mergeItems(data, newItem) as GetBookingResDto)
                            : undefined;
                    }
                );
            });

        // Bookings under event
        if (newItem.event?.id) {
            queryClient
                .getQueriesData({
                    queryKey: [useEventsLazyKey],
                    exact: false,
                })
                .forEach(([queryKey]) => {
                    queryClient.setQueryData<InfiniteData<GetEventsResDto>>(
                        queryKey,
                        data => {
                            if (!data?.pages) return undefined;
                            const pages = data.pages.map(page => {
                                const items =
                                    page.items?.map(item =>
                                        item.id === newItem.event?.id
                                            ? (updateBooking(item, newItem) as
                                                  | GetEventsResItem0Dto
                                                  | GetEventsResItem1Dto)
                                            : item
                                    ) || [];
                                return { ...data, items };
                            });
                            return { ...data, pages };
                        }
                    );
                });
            queryClient
                .getQueriesData({
                    queryKey: [useEventKey],
                    exact: false,
                })
                .forEach(([queryKey]) => {
                    queryClient.setQueryData<EventDto>(queryKey, data => {
                        return data
                            ? (updateBooking(data, newItem) as
                                  | GetEventResDto
                                  | GetEventRes1Dto)
                            : undefined;
                    });
                });
        }
    };

    return {
        update,
    };
};

export const useBooking = (
    bookingId?: string,
    options: CustomQueryOptions<
        ReturnType<typeof BookingsService.getBooking>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryKey = [useBookingKey, bookingId];

    const { data, ...other } = useQuery({
        queryKey,
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBooking(
                bookingId as string,
                authorization
            );
        },
        enabled: !!bookingId,
        ...options,
    });

    const parsedData = useMemo(
        () => (data ? getBookingDtoToBooking(data) : data),
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

// Get all products lazy
interface UseBookingsLazyData {
    orderNumber?: string;
    eventId?: string;
    customerName?: string;
    customerEmail?: string;
    customerPhoneNumber?: string;
    productType?: ProductType;
    productName?: string;
    startTs?: number;
    endTs?: number;
    location?: string;
    pageNumber?: number;
    pageSize?: number;
}

export const useBookingsLazy = (
    params: UseBookingsLazyData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof BookingsService.getBookings>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 20;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useBookingsLazyKey, params],
        queryFn: async ({ pageParam = 0 }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBookings(
                authorization,
                params.orderNumber,
                params.eventId,
                params.customerName,
                params.customerEmail,
                params.customerPhoneNumber,
                params.productType,
                params.productName,
                params.startTs,
                params.endTs,
                params.location,
                pageParam,
                pageSize
            );
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage.items || lastPage.items.length < pageSize)
                return undefined;
            return allPages.length;
        },
        ...options,
    });

    // TODO move this too common converters
    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getBookingDtoToBooking)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useHoldBookingAvailability = (
    options?: CustomMutationOptions<
        { bookingId: string },
        ReturnType<typeof BookingsService.updateStatusBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateStatusBookings(
                bookingId,
                authorization,
                {
                    status: BookingStatus.HOLD,
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useReserveBooking = (
    options?: CustomMutationOptions<
        { bookingId: string },
        ReturnType<typeof BookingsService.updateStatusBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateStatusBookings(
                bookingId,
                authorization,
                {
                    status: BookingStatus.CONFIRMED,
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useCancelBooking = (
    options?: CustomMutationOptions<
        { bookingId: string; reason?: string },
        ReturnType<typeof BookingsService.updateStatusBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, reason }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateStatusBookings(
                bookingId,
                authorization,
                {
                    status: BookingStatus.CANCELLED,
                    message: reason,
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateBookingPax = (
    options?: CustomMutationOptions<
        {
            bookingId: string;
            pax: PaxData;
        },
        ReturnType<typeof BookingsService.updateCustomersBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, pax }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateCustomersBookings(
                bookingId,
                authorization,
                {
                    pax,
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateBookingParticipants = (
    oldItems: TravelerForm[],
    options?: CustomMutationOptions<
        {
            bookingId: string;
            items: TravelerForm[];
            pax: PaxData;
        },
        ReturnType<typeof BookingsService.updateCustomersBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, items, pax }) => {
            const requestItems = convertItemsToActionItemsDto(
                oldItems.map(convertBookingTravelerToBookingTravelerDto),
                items.map(convertBookingTravelerToBookingTravelerDto),
                'id',
                (oldItem, newItem) =>
                    JSON.stringify(oldItem) !== JSON.stringify(newItem)
            );

            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateCustomersBookings(
                bookingId,
                authorization,
                {
                    pax,
                    items: requestItems,
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
        onError: (error, ...args) => {
            // @ts-ignore
            if (error?.status === 409) {
                options?.onSuccess?.({} as UpdateEventBookingsResDto, ...args);
            } else {
                options?.onError?.(error, ...args);
            }
        },
    });
};

export const useAddBookingTransaction = (
    options?: CustomMutationOptions<
        {
            bookingId: string;
            type: TransactionType;
            method: TransactionMethod;
            amount: number;
        },
        ReturnType<typeof BookingsService.updateTransactionsBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, type, method, amount }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateTransactionsBookings(
                bookingId,
                authorization,
                {
                    action: ListItemAction.ADD,
                    type,
                    method,
                    amount: {
                        original: amount,
                    },
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useDiscardBookingTransaction = (
    options?: CustomMutationOptions<
        {
            bookingId: string;
            transactionId: string;
            message?: string;
        },
        ReturnType<typeof BookingsService.updateTransactionsBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, transactionId, message }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateTransactionsBookings(
                bookingId,
                authorization,
                {
                    action: ListItemAction.UPDATE,
                    id: transactionId,
                    discard: { message: message || '' },
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateBookingOptions = (
    oldItems: BookingProductOption[],
    options: CustomMutationOptions<
        {
            bookingId: string;
            items: BookingProductOption[];
        },
        ReturnType<typeof BookingsService.updateProductOptionsBookings> | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, items }) => {
            const requestItems = convertItemsToActionItemsDto(
                oldItems.map(convertBookingOptionToBookingOptionDto),
                items.map(convertBookingOptionToBookingOptionDto),
                'id',
                (oldItem, newItem) =>
                    JSON.stringify(oldItem) !== JSON.stringify(newItem)
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return BookingsService.updateProductOptionsBookings(
                    bookingId,
                    authorization,
                    {
                        items: requestItems,
                    }
                );
            }
            return null;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateBookingNotes = (
    options?: CustomMutationOptions<
        { bookingId: string; notes: BookingNote[] },
        ReturnType<typeof BookingsService.updateBookingNotesBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, notes }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateBookingNotesBookings(
                bookingId,
                authorization,
                {
                    // TODO change this
                    items: notes.map(note => ({
                        action: ListItemAction.REORDER,
                        ...note,
                    })),
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

/// OLD HOOKS

export const useBookingMutate = (
    options: CustomMutationOptions<
        {
            id: string;
        },
        ReturnType<typeof BookingsService.getBooking>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const updateOrderBooking = useUpdateOrderBooking();
    const updateEventBooking = useUpdateEventBooking();

    return useMutation(
        async ({ id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBooking(id, authorization);
        },
        {
            onSuccess: booking => {
                updateOrderBooking(booking.id, () => booking);
                if (booking.event.id) {
                    updateEventBooking(
                        booking.event.id,
                        booking.id,
                        () => booking
                    );
                }
            },
            ...options,
        }
    );
};

export const useMoveBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateEventBookings>>,
            unknown,
            {
                bookingId: string;
                event: EventItem;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, event }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;

        let eventId = event.id;
        if (!eventId) {
            const newEvent = await EventsService.createEvent(authorization, {
                date: convertPeriodToPeriodData(event.date),
                product: {
                    id: event.product.id,
                    capacity_id: '',
                },
                status: event.status,
            });
            eventId = newEvent.id;
        }

        return BookingsService.updateEventBookings(
            bookingId,
            eventId,
            authorization
        );
    }, options);
};

export const useCreateBooking = (
    options?: CustomMutationOptions<{
        customer: Customer;
        productOptions: { id: string; name: string; type: ProductOptionType }[];
        event: AvailableEvent;
        isDraft?: boolean;
        pax: PaxData;
        notes: string[];
        source: {
            type: SourceType;
            name?: DirectSource;
            referral?: Referral;
        };
    }>
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation(async params => {
        const { customer, event, isDraft, pax, productOptions, notes, source } =
            params;
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;

        const newBooking = await BookingsService.createBooking(authorization, {
            product: {
                id: event.rawDto.product.id,
                options: { items: productOptions },
                customers: {
                    items: [customerToOrderCustomerDto(customer)],
                    pax,
                },
            },
            // TODO either event id or date and capacity
            // TODO fix this after date is not required anymore
            // @ts-ignore
            event: event.id
                ? {
                      id: event.id,
                  }
                : undefined,
            source:
                source.type === SourceType.DIRECT
                    ? {
                          type: CreateBookingReqSource0Dto.type.DIRECT,
                          name: source.name as DirectSource,
                      }
                    : {
                          type: CreateBookingReqSource1Dto.type.REFERRAL,
                          // TODO Referral request object missing
                          // id: source.referral?.id,
                          // name: source.referral?.name,
                          // email: source.referral?.email as string,
                          // company: source.referral?.company,
                      },
            notes,
            status: isDraft ? BookingStatus.DRAFT : BookingStatus.HOLD,
        });

        queryClient.invalidateQueries({
            queryKey: ['OrdersServiceGetOrders', { pageSize: 1000 }],
            exact: true,
            refetchType: 'active',
        });

        return newBooking;
    }, options);
};

// TODO review this
export const useSetTravelerParticipation = (
    options?: CustomMutationOptions<
        {
            bookingId: string;
            travelerId: string;
            attendance: AttendanceType;
        },
        ReturnType<typeof BookingsService.updateCustomersBookings>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useBookingCache();

    return useMutation({
        mutationFn: async ({ bookingId, travelerId, attendance }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.updateCustomersBookings(
                bookingId,
                authorization,
                {
                    items: [
                        {
                            id: travelerId,
                            attendance: { type: attendance },
                            action: ListItemAction.UPDATE,
                        },
                    ],
                }
            );
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].bookingId, args[0]);
            options?.onSuccess?.(...args);
        },
        onError: (error, ...args) => {
            // @ts-ignore
            if (error?.status === 409) {
                options?.onSuccess?.({} as UpdateEventBookingsResDto, ...args);
            } else {
                options?.onError?.(error, ...args);
            }
        },
    });
};
