/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateStaffEventsReqItemDto } from './UpdateStaffEventsReqItemDto';

export type UpdateStaffEventsReq1Dto = {
    /**
     * Staff members
     */
    items?: Array<UpdateStaffEventsReqItemDto>;
    type: UpdateStaffEventsReq1Dto.type;
};

export namespace UpdateStaffEventsReq1Dto {

    export enum type {
        TRANSFER = 'transfer',
    }


}
