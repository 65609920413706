import { AgebandName } from '../common.types';

export interface CapacityItem {
    capacityId: string;
    name: string;
    type: CapacityOptionTypes;
    limit: FlatCapacity | AgebandCapacity;
}

export interface FlatCapacity {
    min: number;
    max: number;
}

export enum CapacityOptionTypes {
    FLAT = 'flat',
    AGE_BAND = 'ageBand',
}

export type AgebandCapacity = Partial<Record<AgebandName, number>>;
