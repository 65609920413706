/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserResAccountMembershipsItem0GroupDto = {
    /**
     * Type of the user group
     */
    type?: UpdateUserResAccountMembershipsItem0GroupDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
};

export namespace UpdateUserResAccountMembershipsItem0GroupDto {

    /**
     * Type of the user group
     */
    export enum type {
        INDIVIDUAL = 'individual',
    }


}
