export enum RecurrenceTypes {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export enum Weekdays {
    MONDAY = 'mon',
    TUESDAY = 'tue',
    WEDNESDAY = 'wed',
    THURSDAY = 'thu',
    FRIDAY = 'fri',
    SATURDAY = 'sat',
    SUNDAY = 'sun',
}

export interface SeasonSchedule {
    type: ScheduleOptionTypes.SEASON;
    timeZone: string;
    name: string;
    startDate: Date;
    endDate: Date;
    recurrenceType: RecurrenceTypes;
    weekDays?: Weekdays[];
    monthDays?: Date[];
    dates?: Date[];
    allDay?: boolean;
    times?: number[];
}

export enum ScheduleOptionTypes {
    SEASON = 'season',
    CUSTOM = 'custom',
}

export interface CustomSchedule {
    type: ScheduleOptionTypes.CUSTOM;
    timeZone: string;
    name: string;
    startDates: Date[];
    allDay?: boolean;
    times: number[];
}

export type Schedule = SeasonSchedule | CustomSchedule;

export type ScheduleItem = Schedule & {
    scheduleId: string;
};
