import {
    PersonNameData,
    ProductType,
    TeamPermissionsData,
    UserMembershipStatus,
    type UserOccupation,
} from '../../requests';
import { Activity } from '../common.types';

// re-exporting enums
export { UserMembershipStatus } from '../../requests';

export enum MemberRole {
    EVENT_STAFF = 'event_staff',
    PARTNER = 'partner',
    VIEWER = 'viewer',
    EDITOR = 'editor',
    CONTRIBUTOR = 'contributor',
    ADMINISTRATOR = 'administrator',
    OWNER = 'owner',
}

export enum UserGroupType {
    INDIVIDUAL = 'individual',
    TEAM = 'team',
    DEPARTMENT = 'department',
    ORGANIZATION = 'organization',
}

export interface TeamMember {
    id: string;
    name?: PersonNameData;
    email?: string;
    company?: string;
    phone_numbers: string[];
    role: MemberRole;
    membershipStatus: UserMembershipStatus;
    occupation?: UserOccupation;
    joinedAt?: Date;
}

export interface Team {
    id: string;
    createdBy: PersonNameData;
    createdAt: Date;
    lifecycle?: Activity[];
    isolated: boolean;
    permissions: TeamPermissionsData;
    name: string;
    description?: string;
    members: TeamMember[];
    productTypes: Partial<Record<ProductType, number>>;
}

export interface Staff {
    id?: string;
    fullName?: string;
    email: string;
    phoneNumber?: string;
}

export interface Referral {
    id?: string;
    name?: PersonNameData;
    email: string;
    company?: string;
}
