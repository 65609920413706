import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Skeleton, Stack } from '@mui/material';
import {
    AgebandName,
    AgeBands,
    AvailableProductOption,
    Booking,
    PricingType,
    Product,
    ProductOptionType,
    useUpdatePrice,
} from '@travelity/api';
import { CardList } from '@travelity/form';
import { Tag } from '@travelity/ui';
import { useFormContext } from 'react-hook-form';
import { useSelectOptions } from '../../hooks';
import { OptionModal } from '../option-modal';

export interface BookingProductOptionsFormProps {
    booking: Booking;
    product: Product;
    preview?: boolean;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

const BookingProductOptionsForm: React.FC<
    BookingProductOptionsFormProps
> = props => {
    const { t } = useTranslation('product');
    const { product, booking, preview } = props;

    const productOptions = useMemo(() => {
        return (
            product.options?.items.map(o => ({
                id: o.id as string,
                name: o.name as string,
                type: o.optionType as ProductOptionType,
                pax: {},
                hasPrice: !!o.prices,
                pricing: o.prices,
            })) || []
        );
    }, []);

    const options = useSelectOptions(productOptions, 'name', 'name', true);

    const { watch } = useFormContext();
    const selectedOptionsArray: AvailableProductOption[] =
        watch('options') || [];
    const selectedOptions =
        selectedOptionsArray && selectedOptionsArray.length
            ? selectedOptionsArray
            : undefined;

    const priceRequestParams = useMemo(() => {
        return {
            product: {
                id: product.id,
                financials: booking.product.financials,
                options: selectedOptions
                    ?.map(option =>
                        option
                            ? {
                                  name: option.name,
                                  type: option.type,
                                  participants: { pax: option.pax },
                                  financials: option.pricing
                                      ? {
                                            pricing: option.pricing,
                                        }
                                      : undefined,
                              }
                            : undefined
                    )
                    .filter(v => v) as {
                    name: string;
                    type: ProductOptionType;
                }[],
            },
            participants: {
                pax: booking.participants.pax,
            },
            financials: booking.financials,
        };
    }, [product.id, selectedOptions]);

    const {
        data: price,
        isLoading: isPriceLoading,
        changed: priceChanged,
        update: updatePrice,
    } = useUpdatePrice(priceRequestParams);
    useEffect(() => {
        if (priceChanged) {
            updatePrice();
        }
    }, [priceChanged]);

    // Handle option selection
    const [optionModal, setOptionModal] = useState<{
        item?: AvailableProductOption;
        callback: (item: AvailableProductOption) => void;
    } | null>(null);

    const onOptionAdd = useCallback(
        (callback: (item: AvailableProductOption) => void) => {
            setOptionModal({ callback });
        },
        []
    );

    const onOptionEdit = useCallback(
        (
            item: Record<string, any>,
            callback: (item: Record<string, any>) => void
        ) => {
            setOptionModal({ item: item as AvailableProductOption, callback });
        },
        []
    );

    const handleOptionSave = useCallback(
        (newItem: AvailableProductOption) => {
            optionModal?.callback(newItem);
            setOptionModal(null);
        },
        [optionModal]
    );

    const availableOptions = useMemo(() => {
        return options.filter(
            option =>
                optionModal?.item?.name === option.name ||
                !selectedOptions?.find(({ name }) => name === option.name)
        );
    }, [options, selectedOptions, optionModal]);

    const getOptionPrice = (n: string) => {
        const option = price?.options?.find(({ name }) => name === n);
        if (option) {
            const prices: { count: number; price: number; label: string }[] =
                [];
            if (option.price.type === PricingType.PER_PERSON) {
                prices.push({
                    count: option.price.count,
                    price: option.price.unitPrice,
                    label: '',
                });
            } else if (option.price.type === PricingType.PER_PRODUCT) {
                prices.push({
                    count: 1,
                    price: option.price.unitPrice,
                    label: '',
                });
            } else {
                Object.values(AgebandName).forEach((k: AgebandName) => {
                    // @ts-ignore
                    const p = option.price[k];
                    if (p) {
                        prices.push({
                            count: p.count,
                            price: p.unitPrice,
                            label: k,
                        });
                    }
                });
            }

            if (option.price.base) {
                prices.push({
                    count: 1,
                    price: option.price.base,
                    label: '',
                });
            }

            const totalPrice = prices.reduce(
                (s, p) => s + p.count * p.price,
                0
            );
            return `${numberWithSpaces(totalPrice)} ${price?.currency}`;
        }
        return 'Free';
    };

    const hasOptionPrice = (n: string) => {
        return options.find(o => o.name === n && !!o.hasPrice);
    };

    return (
        <>
            <Box
                sx={{
                    minHeight: 0,
                    flexGrow: 2,
                }}
            >
                {!!options?.length && (
                    <CardList
                        disabled={preview}
                        renderHeader={({ item }) => (
                            <Box component="span">
                                {item.name} ({item.type})
                            </Box>
                        )}
                        renderContent={({ item }) => (
                            <Stack direction="row">
                                <Stack
                                    direction="row"
                                    gap={1}
                                    px={1}
                                    py={1}
                                    flexWrap="wrap"
                                    flexGrow={2}
                                >
                                    {Object.values(AgeBands).map(
                                        name =>
                                            !!item.pax[name] && (
                                                <Tag
                                                    label={t(name)}
                                                    values={[item.pax[name]]}
                                                />
                                            )
                                    )}
                                </Stack>
                                <Stack pr={1}>
                                    {!hasOptionPrice(item.name) && (
                                        <Chip
                                            size="small"
                                            label="Free"
                                            sx={{
                                                mt: 1,
                                                color: 'success.main',
                                                border: '1px solid',
                                                borderColor: 'success.main',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                '& > .MuiChip-label': {
                                                    px: '6px',
                                                    py: '4px',
                                                },
                                                bgcolor:
                                                    'rgba(44, 172, 96, 0.20)',
                                            }}
                                        />
                                    )}
                                    {hasOptionPrice(item.name) &&
                                        (isPriceLoading ? (
                                            <Skeleton
                                                width={60}
                                                sx={{
                                                    fontSize: '32px',
                                                }}
                                            />
                                        ) : (
                                            <Chip
                                                size="small"
                                                label={getOptionPrice(
                                                    item.name
                                                )}
                                                sx={{
                                                    mt: 1,
                                                    color: 'secondary.main',
                                                    border: '1px solid',
                                                    borderColor:
                                                        'secondary.main',
                                                    fontWeight: 400,
                                                    fontSize: '12px',
                                                    '& > .MuiChip-label': {
                                                        px: '6px',
                                                        py: '4px',
                                                    },
                                                    bgcolor:
                                                        'rgba(85, 181, 207, 0.2)',
                                                }}
                                            />
                                        ))}
                                </Stack>
                            </Stack>
                        )}
                        disableAdding={
                            options.length <= (selectedOptions?.length || 0)
                        }
                        customAdd={onOptionAdd}
                        customEdit={onOptionEdit}
                        // noEmptyState
                        name="options"
                        addButtonText="Add Product Option"
                    />
                )}
            </Box>
            <OptionModal
                handleCancel={() => setOptionModal(null)}
                handleConfirm={handleOptionSave}
                open={!!optionModal}
                options={availableOptions}
                item={optionModal?.item}
            />
        </>
    );
};

export default React.memo(BookingProductOptionsForm);
