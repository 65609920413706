import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { DailyViewHeader } from './daily-view-header';

export interface EventsHeaderProps {
    firstVisibleDate?: number;
}

export const EventsHeader: React.FC<EventsHeaderProps> = ({
    firstVisibleDate,
}) => {
    const [currentDate, setCurrentDate] = useState<string>(
        format(new Date(), 'HH:mm')
    );
    useEffect(() => {
        setInterval(() => {
            setCurrentDate(format(new Date(), 'HH:mm'));
        }, 1000);
    }, []);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                py: 1.5,
                px: 2,
                height: '72px',
            }}
        >
            <Box>
                {firstVisibleDate && (
                    <DailyViewHeader date={new Date(firstVisibleDate)} />
                )}
            </Box>
            <Stack>
                <Typography
                    sx={{
                        color: '#9198AA',
                        fontSize: '12px',
                    }}
                >
                    Current time
                </Typography>
                <Typography
                    sx={{
                        color: '#B2B9CD',
                        fontSize: '20px',
                        fontWeight: '700',
                        textAlign: 'right',
                    }}
                >
                    {currentDate}
                </Typography>
            </Stack>
        </Stack>
    );
};
