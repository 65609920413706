import React, { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { TextField, useForm } from '@travelity/form';
import { Button } from '@travelity/ui';
import { object, string } from 'yup';

const schema = object().shape({
    fullName: string()
        .required('Full Name is required')
        .matches(/\s[a-zA-Z]/, 'Full Name is required'),
});

export interface FullNameStepProps {
    fullName?: string;
    back: () => void;
    next: (v: string) => void;
}

export const FullNameStep: React.FC<FullNameStepProps> = props => {
    const { fullName, next, back } = props;

    const [showError, setShowErrors] = React.useState(false);

    const { Form, watch } = useForm({
        schema,
        onSubmit: useCallback(
            (data: { fullName: string }) => {
                next(data.fullName);
            },
            [next]
        ),
        defaultValues: { fullName },
        mode: 'onChange',
        validateInitially: true,
    });

    return (
        <Form>
            <Stack
                sx={{
                    py: 5,
                    height: 1,
                    minHeight: 'calc(100vh - 84px)',
                }}
                gap={2}
                alignItems="center"
                justifyContent="center"
            >
                <Typography sx={{ color: '#C0C4CE', fontSize: '24px' }}>
                    What is your full name?
                </Typography>
                <TextField
                    name="fullName"
                    label=""
                    placeholder="Type Full Name"
                    showErrors={showError}
                />
                <Stack direction="row" spacing={1} sx={{ width: '388px' }}>
                    <Button
                        variant="contained"
                        color="neutral"
                        sx={{ flexGrow: 1 }}
                        onClick={back}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                        }}
                        doNotDisable
                        onIconClick={() => {
                            setShowErrors(true);
                        }}
                        requiredFields={
                            !watch('fullName')?.match(/\s[a-zA-Z]/)
                                ? ['Full Name']
                                : undefined
                        }
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
