import React from 'react';
import { Stack, Typography } from '@mui/material';

export interface ListTitleProps {
    children?: React.ReactNode;
    title?: string;
}

export const ListTitle: React.FC<ListTitleProps> = React.memo(
    (props: ListTitleProps) => {
        const { children, title } = props;

        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    py: 1.5,
                    height: '72px',
                }}
            >
                {title && (
                    <Typography variant="h5" sx={{ color: '#C0C4CE' }}>
                        {title}
                    </Typography>
                )}

                {children}
            </Stack>
        );
    }
);
