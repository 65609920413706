/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetAssetResAccessDto } from './GetAssetResAccessDto';
import type { GetAssetResOwnerDto } from './GetAssetResOwnerDto';
import type { GetAssetResUsageItemDto } from './GetAssetResUsageItemDto';
import type { GetAssetResUsageItemMileageDto } from './GetAssetResUsageItemMileageDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';
import type { VehicleEngineType } from './VehicleEngineType';
import type { VehicleType } from './VehicleType';

export type GetAssetResDto = {
    /**
     * Type of the resource
     */
    resource_type: GetAssetResDto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * type of the asset
     */
    type: GetAssetResDto.type;
    /**
     * Asset owner data
     */
    owner?: GetAssetResOwnerDto;
    /**
     * Usage history of the asset
     */
    usage?: Array<GetAssetResUsageItemDto>;
    /**
     * Access to the shared product
     */
    access: GetAssetResAccessDto;
    /**
     * type of the asset
     */
    vehicle_type: VehicleType;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    engine_type: VehicleEngineType;
    /**
     * Vehicle mileage
     */
    mileage: GetAssetResUsageItemMileageDto;
};

export namespace GetAssetResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ASSET = 'asset',
    }

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
