/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductReq1Dto } from '../models/CreateProductReq1Dto';
import type { CreateProductReqDto } from '../models/CreateProductReqDto';
import type { CreateProductRes1Dto } from '../models/CreateProductRes1Dto';
import type { CreateProductResDto } from '../models/CreateProductResDto';
import type { DeleteProductRes1Dto } from '../models/DeleteProductRes1Dto';
import type { DeleteProductResDto } from '../models/DeleteProductResDto';
import type { GetProductRes1Dto } from '../models/GetProductRes1Dto';
import type { GetProductResDto } from '../models/GetProductResDto';
import type { GetProductsResDto } from '../models/GetProductsResDto';
import type { ProductType } from '../models/ProductType';
import type { UpdateCapacityProducts1ReqDto } from '../models/UpdateCapacityProducts1ReqDto';
import type { UpdateCapacityProductsReqDto } from '../models/UpdateCapacityProductsReqDto';
import type { UpdateProduct1111ReqDto } from '../models/UpdateProduct1111ReqDto';
import type { UpdateProduct111ReqDto } from '../models/UpdateProduct111ReqDto';
import type { UpdateProduct11ReqDto } from '../models/UpdateProduct11ReqDto';
import type { UpdateProduct1Req1Dto } from '../models/UpdateProduct1Req1Dto';
import type { UpdateProduct1ReqDto } from '../models/UpdateProduct1ReqDto';
import type { UpdateProductReq1Dto } from '../models/UpdateProductReq1Dto';
import type { UpdateProductReqDto } from '../models/UpdateProductReqDto';
import type { UpdateProductRes1Dto } from '../models/UpdateProductRes1Dto';
import type { UpdateProductResDto } from '../models/UpdateProductResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * List/Filter Products
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param type Type of the product(s) being searched
     * @param name name of the product(s) being searched
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param currency Currency of the pricing
     * @param minPrice Minimum price to match products with.
     * @param maxPrice Maximum price to match products with.
     * @param location location that is in the route of the search product(s)
     * @param groupId Id of groups where the product is shared
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetProductsResDto Successful Response
     * @throws ApiError
     */
    public static getProducts(
authorization: string,
type?: ProductType,
name?: string,
startTs?: number,
endTs?: number,
currency?: string,
minPrice?: number,
maxPrice?: number,
location?: string,
groupId?: Array<string>,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetProductsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products',
            headers: {
                'authorization': authorization,
            },
            query: {
                'type': type,
                'name': name,
                'start_ts': startTs,
                'end_ts': endTs,
                'currency': currency,
                'min_price': minPrice,
                'max_price': maxPrice,
                'location': location,
                'group_id': groupId,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Product
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createProduct(
authorization: string,
requestBody: (CreateProductReqDto | CreateProductReq1Dto),
): CancelablePromise<(CreateProductResDto | CreateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/products',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List/Filter Product References
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getProducts1(
authorization: string,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products/references',
            headers: {
                'authorization': authorization,
            },
            query: {
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product details by ID
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct(
productId: string,
authorization: string,
requestBody: (UpdateProductReqDto | UpdateProductReq1Dto),
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/details',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product route
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct1(
productId: string,
authorization: string,
requestBody: (UpdateProduct1ReqDto | UpdateProduct1Req1Dto),
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/route',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product Capacity
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCapacityProducts(
productId: string,
authorization: string,
requestBody: UpdateCapacityProductsReqDto,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/capacity',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product Schedule
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCapacityProducts1(
productId: string,
authorization: string,
requestBody: UpdateCapacityProducts1ReqDto,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/schedule',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product pricing by ID
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct11(
productId: string,
authorization: string,
requestBody: UpdateProduct11ReqDto,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/pricing',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product options by ID
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct111(
productId: string,
authorization: string,
requestBody: UpdateProduct111ReqDto,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/options',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product configuration by ID
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct1111(
productId: string,
authorization: string,
requestBody: UpdateProduct1111ReqDto,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/configuration',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Activate Product by ID
     * @param productId ID of the product being activated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static activateProducts(
productId: string,
authorization: string,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/activate',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deactivate Product by ID
     * @param productId ID of the product being deactivated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deactivateProducts(
productId: string,
authorization: string,
): CancelablePromise<(UpdateProductResDto | UpdateProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/deactivate',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch Product by ID
     * @param productId ID of the product being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getProduct(
productId: string,
authorization: string,
): CancelablePromise<(GetProductResDto | GetProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products/{product_id}',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Product by ID
     * @param productId ID of the product being deleted
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteProduct(
productId: string,
authorization: string,
): CancelablePromise<(DeleteProductResDto | DeleteProductRes1Dto)> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/products/{product_id}',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get generated product name
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateNameProducts(
authorization: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products/generate_name',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product sharing within teams
     * @param productId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProduct11111(
productId: string,
authorization: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/products/{product_id}/update_sharing',
            path: {
                'product_id': productId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
