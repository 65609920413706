import {
    GetTeamsResItemDto,
    type GetTeamsResItemMembersItemDto,
    ProductType,
} from '../../requests';
import { MemberRole, Referral, Staff, Team, TeamMember } from './team.types';
import { convertNameDtoToFullName } from '../user/user.converters';
import {
    convertActivityDtoToActivity,
    phoneNumberDataToPhoneNumber,
} from '../common.converters';

export const teamMemberDtoToTeamMember = (
    member: GetTeamsResItemMembersItemDto
): TeamMember => ({
    id: member.id,
    name: member.reference?.name,
    email: member.reference?.contact_details?.emails?.[0],
    company: member.reference?.organization?.name,
    phone_numbers:
        member.reference?.contact_details?.phone_numbers?.map(
            number => `${number.calling_code} ${number.number}`
        ) || [],
    role: member.membership.role.type as unknown as MemberRole,
    membershipStatus: member.membership.status,
    occupation: member.occupation,
    joinedAt: member?.membership.joined?.at
        ? new Date(member.membership.joined.at * 1000)
        : undefined,
});

export const teamDtoToTeam = (team: GetTeamsResItemDto): Team => ({
    id: team.id,
    createdBy: team.created.by.name,
    createdAt: new Date(team.created.at),
    name: team.name,
    description: team.description,
    lifecycle: team.lifecycle?.map(convertActivityDtoToActivity) || [],
    isolated: false,
    permissions: team.access?.permissions || {},
    members: team.members.items.map(teamMemberDtoToTeamMember) || [],
    productTypes: {
        [ProductType.TRANSFER]:
            team.insight?.product?.shared?.count?.transfers || 0,
        [ProductType.TOUR]: team.insight?.product?.shared?.count?.tours || 0,
    },
});

export const memberToStaff = (
    member: GetTeamsResItemMembersItemDto
): Staff => ({
    id: member.id,
    fullName: member.reference?.name
        ? convertNameDtoToFullName(member.reference?.name)
        : undefined,
    email: member.reference?.contact_details?.emails?.[0] || '',
    phoneNumber: member.reference?.contact_details?.phone_numbers?.[0]
        ? phoneNumberDataToPhoneNumber(
              member.reference.contact_details.phone_numbers[0]
          )
        : undefined,
});

export const memberToReferral = (
    member: GetTeamsResItemMembersItemDto
): Referral => ({
    id: member.id,
    name: member.reference?.name,
    email: member.reference?.contact_details?.emails?.[0] || '',
    company: member.reference?.organization?.name || '',
});

export const memberToDetailsForm = (member: TeamMember) => ({
    numbers: member.phone_numbers.map(value => ({ value })) || [],
    fullName: member?.name ? convertNameDtoToFullName(member?.name) : undefined,
});
