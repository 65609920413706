/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserReqAccountMembershipsItem1Role6Dto = {
    /**
     * Type of user's role
     */
    type?: UpdateUserReqAccountMembershipsItem1Role6Dto.type;
};

export namespace UpdateUserReqAccountMembershipsItem1Role6Dto {

    /**
     * Type of user's role
     */
    export enum type {
        PARTNER = 'partner',
    }


}
