import React, {
    forwardRef,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { Box, Divider } from '@mui/material';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import Edit from '@mui/icons-material/Edit';
import DoNotDisturbOn from '@mui/icons-material/DoNotDisturbOn';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { string, addMethod, array } from 'yup';
import { useForm, UseFormParams } from '../../use-form';
import { Card, IconButton, IconButtonGroup } from '@travelity/ui';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RenderContentProps } from './card-list';

interface CardItemProp {
    index: number;
    onDelete: (i: number) => void;
    onEdit: (i: number) => void;
    onEditSave: (i: number, data: any) => void;
    onEditDiscard: (i: number) => void;
    items: any[];
    item: any;
    placeholder?: boolean;
    listeners?: SyntheticListenerMap[];
    style?: Record<string, string>;
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    editing?: boolean;
    disableReorder?: boolean;
    disableEdit?: boolean;
    disableItemEdit?: (v: Record<string, any>) => boolean;
    disableRemove?: boolean;
    renderPreComponent?: (p: RenderContentProps) => ReactElement | null;
    renderHeader?: (
        p: RenderContentProps,
        index: number
    ) => ReactElement | null;
    renderContent: (p: RenderContentProps) => ReactElement | null;
    renderCustomButtons?: (p: RenderContentProps) => ReactElement | null;
    onNextStep?: (p: RenderContentProps) => void;
    headerSx?: Record<string, any>;
    formParams?: Partial<UseFormParams<Record<string, any>>>;
}

addMethod(array, 'notInEdit', function (message) {
    return this.when('$items', (_, sch) => {
        return sch.test('notInEdit', message, function (list) {
            return !list?.filter(obj => obj.isNew || obj.isEdit).length;
        });
    });
});

addMethod(string, 'uniqName', function (message = 'Name already in use') {
    return this.when(['$items', 'id'], ([items, id], sch) => {
        const otherItems = items.filter((it: any) => it.id !== id);
        return sch.test('uniqName', message, function (name = '') {
            return !otherItems.find(
                (it: any) => it.name?.trim() === name.trim()
            );
        });
    });
});

const CardItem = forwardRef<HTMLDivElement, CardItemProp>(
    (
        {
            index,
            onDelete,
            onEdit,
            onEditSave,
            onEditDiscard,
            listeners,
            style = {},
            items,
            item,
            placeholder,
            setActivatorNodeRef,
            editing,
            renderPreComponent,
            renderHeader,
            renderContent,
            renderCustomButtons,
            onNextStep,
            headerSx = {},
            formParams = {},
            disableReorder = false,
            disableEdit = false,
            disableItemEdit,
            disableRemove = false,
        },
        ref
    ) => {
        const onSubmit = useCallback(
            (data: any) => {
                onEditSave(index, data);
            },
            [index, onEditSave]
        );

        const form = useForm({
            onSubmit,
            ...formParams,
            context: { items, ...(formParams.context || {}) },
        });

        const { Form, handleSubmit, reset, watch, setValue } = form;

        const submit = handleSubmit(data => {
            onEditSave(index, data);
        });

        useEffect(() => {
            reset(item);
        }, [item, reset, watch]);

        const headerRef = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (editing) {
                headerRef.current?.querySelector('input')?.focus();
            }
        }, [editing]);

        const currentStep = watch('step');
        const totalSteps = watch('totalSteps');

        const hasSteps = totalSteps && totalSteps > 1 && !!currentStep;
        // TODO change this
        const next = handleSubmit(newItem => {
            if (onNextStep) {
                onNextStep?.({
                    item: newItem,
                    editing: true,
                    form,
                    // @ts-ignore
                    oldItem: item,
                });
            } else {
                setValue('step', currentStep + 1);
            }
        });
        const prev = handleSubmit(() => {
            setValue('step', currentStep - 1);
        });
        const headerContent = renderHeader?.(
            {
                item,
                editing: !!editing,
                form,
            },
            index
        );
        const disableEditButton = disableItemEdit?.(item) || disableEdit;

        // TODO add prevent default on all onClicks
        return (
            <>
                {renderPreComponent?.({
                    item,
                    editing: !!editing,
                    form,
                })}
                <Card
                    ref={ref}
                    loadingOverlay={item.isFetching}
                    parentProps={{
                        style: { ...style, opacity: placeholder ? 0.2 : 1 },
                    }}
                    buttons={
                        <>
                            <IconButtonGroup flexShrink={0}>
                                {!editing && (
                                    <>
                                        {!disableEditButton && (
                                            <IconButton
                                                icon={<Edit fontSize="small" />}
                                                onClick={() => onEdit(index)}
                                                tooltip="Edit"
                                                tooltipPlacement={
                                                    disableRemove
                                                        ? 'bottom'
                                                        : 'right'
                                                }
                                            />
                                        )}
                                        {!disableEditButton &&
                                            !disableRemove && (
                                                <Divider sx={{ mx: 1 }} />
                                            )}
                                        {!disableRemove && (
                                            <IconButton
                                                icon={
                                                    <DoNotDisturbOn fontSize="small" />
                                                }
                                                hoverColor="error.main"
                                                onClick={() => onDelete(index)}
                                                tooltip="Remove"
                                            />
                                        )}
                                    </>
                                )}
                                {editing && (
                                    <>
                                        {!hasSteps ||
                                        currentStep === totalSteps ? (
                                            <IconButton
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={submit}
                                                tooltip="Save"
                                                data-testid="save"
                                            />
                                        ) : (
                                            <IconButton
                                                icon={
                                                    <ArrowForwardIcon fontSize="small" />
                                                }
                                                color="primary.main"
                                                hoverColor="primary.dark"
                                                onClick={next}
                                                tooltip="Next"
                                                data-testid="next"
                                            />
                                        )}
                                        <Divider sx={{ mx: 1 }} />
                                        {!hasSteps || currentStep === 1 ? (
                                            <IconButton
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    onEditDiscard(index)
                                                }
                                                tooltip="Cancel"
                                                data-testid="cancel"
                                            />
                                        ) : (
                                            <IconButton
                                                icon={
                                                    <ArrowBackIcon fontSize="small" />
                                                }
                                                color="primary.main"
                                                hoverColor="primary.dark"
                                                onClick={prev}
                                                tooltip="Back"
                                                data-testid="prev"
                                            />
                                        )}
                                    </>
                                )}
                            </IconButtonGroup>
                            {renderCustomButtons?.({
                                item,
                                editing: !!editing,
                                form,
                            })}
                        </>
                    }
                >
                    <Form>
                        {(!disableReorder || !!headerContent) && (
                            <Box
                                ref={headerRef}
                                sx={{
                                    p: 0.75,
                                    color: '#9298ab',
                                    bgcolor: '#f7f8fa',
                                    height: '36px',
                                    fontWeight: 600,
                                    borderRadius: '12px 12px 0px 0px',
                                    lineHeight: '10px',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    ...headerSx,
                                }}
                            >
                                {!editing && !disableReorder && (
                                    <Box
                                        component="span"
                                        ref={setActivatorNodeRef}
                                        sx={{
                                            cursor: 'grab',
                                        }}
                                        {...(listeners || {})}
                                    >
                                        <DragIndicator
                                            sx={{ color: '#cacedc' }}
                                        />
                                    </Box>
                                )}
                                {headerContent}
                            </Box>
                        )}
                        {renderContent({
                            item,
                            editing: !!editing,
                            form,
                        })}
                    </Form>
                </Card>
            </>
        );
    }
);

export default CardItem;
