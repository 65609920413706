import React from 'react';
import { convertNameDtoToFullName, type PersonNameData } from '@travelity/api';
import { Box } from '@mui/material';

export interface UserProps {
    name: PersonNameData;
}

export const User: React.FC<UserProps> = ({ name }) => {
    return <Box component="span">{convertNameDtoToFullName(name)}</Box>;
};
