/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RegisterUsersReqProfileOrganizationContactDetailsDto } from './RegisterUsersReqProfileOrganizationContactDetailsDto';

export type RegisterUsersReqProfileOrganizationDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type?: RegisterUsersReqProfileOrganizationDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: RegisterUsersReqProfileOrganizationContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: string;
};

export namespace RegisterUsersReqProfileOrganizationDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        ORGANIZATION = 'organization',
    }


}
