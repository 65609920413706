/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetUserResAccountMembershipsItem0GroupDto = {
    /**
     * Type of the user group
     */
    type: GetUserResAccountMembershipsItem0GroupDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
};

export namespace GetUserResAccountMembershipsItem0GroupDto {

    /**
     * Type of the user group
     */
    export enum type {
        INDIVIDUAL = 'individual',
    }


}
