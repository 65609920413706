/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateUserReqProfileOrganizationContactDetailsDto } from './UpdateUserReqProfileOrganizationContactDetailsDto';

export type UpdateUserReqProfileOrganizationDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type?: UpdateUserReqProfileOrganizationDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: UpdateUserReqProfileOrganizationContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name?: string;
};

export namespace UpdateUserReqProfileOrganizationDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        ORGANIZATION = 'organization',
    }


}
