/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RegisterUsersResAccountMembershipsItem0RoleAccessDto } from './RegisterUsersResAccountMembershipsItem0RoleAccessDto';

export type RegisterUsersResAccountMembershipsItem1Role1Dto = {
    /**
     * Type of user's role
     */
    type: RegisterUsersResAccountMembershipsItem1Role1Dto.type;
    /**
     * Access details of the current role
     */
    access: RegisterUsersResAccountMembershipsItem0RoleAccessDto;
};

export namespace RegisterUsersResAccountMembershipsItem1Role1Dto {

    /**
     * Type of user's role
     */
    export enum type {
        ADMINISTRATOR = 'administrator',
    }


}
