/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Language } from './Language';
import type { PersonNameData } from './PersonNameData';
import type { RegisterUsersResProfileContactDetailsDto } from './RegisterUsersResProfileContactDetailsDto';
import type { RegisterUsersResProfileOrganizationDto } from './RegisterUsersResProfileOrganizationDto';

export type CreateTeamResMembersItemReferenceDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type: CreateTeamResMembersItemReferenceDto.type;
    /**
     * Type of the capacity of the product
     */
    id?: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: RegisterUsersResProfileContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name?: PersonNameData;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages?: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: RegisterUsersResProfileOrganizationDto;
};

export namespace CreateTeamResMembersItemReferenceDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        PERSON = 'person',
    }


}
