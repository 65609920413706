import React from 'react';
import { Box, Divider, Skeleton, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

export interface SidebarContentProps {
    children?: React.ReactNode;
    title?: string;
    isLoading?: boolean;
}

export const SidebarContent: React.FC<SidebarContentProps> = props => {
    const { children, title, isLoading } = props;

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: title ? 'calc(100% - 38px)' : 1,
                },
            }}
        >
            {title && (
                <Stack direction="row" justifyContent="space-between">
                    <Box
                        sx={{
                            display: 'inline-flex',
                            padding: '8px 18px',
                            marginBottom: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            borderRadius: '0px 0px 12px 0px',
                            background: '#6B748C',
                            color: '#FFF',
                            fontFamily: 'Lexend',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '100%',
                            textTransform: 'capitalize',
                        }}
                    >
                        {title}
                    </Box>
                </Stack>
            )}
            {!isLoading && <PerfectScrollbar>{children}</PerfectScrollbar>}
            {isLoading && (
                <Stack sx={{ py: 2 }} gap={1}>
                    <Skeleton sx={{ mx: 2 }} width={120} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={100} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={140} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={80} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={180} />
                    <Divider />
                    <Skeleton sx={{ mx: 2 }} width={120} />
                    <Divider />
                </Stack>
            )}
        </Box>
    );
};
