import {
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import {
    _UpdatedField,
    ActivityType,
    type PersonNameData,
    RegisterUsersResCreatedByDto,
    TimezoneName,
} from '../requests';

// re-exporting enums
export {
    TransactionType,
    ActivityType,
    ContentType,
    Country,
    Nationality,
    TimezoneName,
    PricingType,
    DiscountType,
    DirectSource,
    Language,
    TransactionMethod,
} from '../requests';

export type {
    Currency,
    PaxData,
    PeriodData, // TODO create converter for period
    PersonNameData,
    PhoneNumberData,
} from '../requests';

enum DurationUnits {
    DAY = 'day',
    HOUR = 'hour',
    MINUTE = 'minute',
}

export type DurationValue = Record<DurationUnits, number>;

export interface Activity {
    type: ActivityType;
    name: PersonNameData;
    date: Date;
    updates: string[];
    message?: string;
}

export interface Period {
    timezone?: TimezoneName;
    duration: DurationValue;
    start: Date;
    end?: Date;
}

export type CustomMutationOptions<
    RequestParameters,
    Response extends Record<string, any> | null = {}
> = Omit<
    UseMutationOptions<Awaited<Response>, unknown, RequestParameters, unknown>,
    'mutationFn'
>;

export type CustomQueryOptions<Response extends Record<string, any> = {}> =
    Omit<
        UseQueryOptions<
            Awaited<Response>,
            unknown,
            Awaited<Response>,
            unknown[]
        >,
        'queryFn' | 'initialData'
    >;

export type CustomInfiniteQueryOptions<
    Response extends Record<string, any> = {}
> = Omit<
    UseInfiniteQueryOptions<
        Awaited<Response>,
        unknown,
        Awaited<Response>,
        Awaited<Response>,
        any[]
    >,
    'queryFn' | 'initialData'
>;

export enum AgebandName {
    ADULTS = 'adults',
    CHILDREN = 'children',
    INFANTS = 'infants',
    YOUTH = 'youth',
    SENIORS = 'seniors',
}

export enum AgeBands {
    ADULTS = 'adults',
    CHILDREN = 'children',
    INFANTS = 'infants',
    YOUTH = 'youth',
    SENIORS = 'seniors',
}

export const currencies = [
    { name: 'United States dollar', abbr: 'USD' },
    { name: 'Euro', abbr: 'EUR' },
    { name: 'Russian ruble', abbr: 'RUB' },
    { name: 'Armenian dram', abbr: 'AMD' },
    { name: 'Afghan afghani', abbr: 'AFN' },
    { name: 'Albanian lek', abbr: 'ALL' },
    { name: 'Algerian dinar', abbr: 'DZD' },
    { name: 'Angolan kwanza', abbr: 'AOA' },
    { name: 'Argentine peso', abbr: 'ARS' },
    { name: 'Australian dollar', abbr: 'AUD' },
    { name: 'Azerbaijani manat', abbr: 'AZN' },
    { name: 'Bahamian dollar', abbr: 'BSD' },
    { name: 'Bahraini dinar', abbr: 'BHD' },
    { name: 'Bangladeshi taka', abbr: 'BDT' },
    { name: 'Barbadian dollar', abbr: 'BBD' },
    { name: 'Belarusian ruble', abbr: 'BYN' },
    { name: 'Belize dollar', abbr: 'BZD' },
    { name: 'Bhutanese ngultrum', abbr: 'BTN' },
    { name: 'Bolivian boliviano', abbr: 'BOB' },
    { name: 'Bosnia and Herzegovina convertible mark', abbr: 'BAM' },
    { name: 'Botswana pula', abbr: 'BWP' },
    { name: 'Brazilian real', abbr: 'BRL' },
    { name: 'British pound', abbr: 'GBP' },
    { name: 'Brunei dollar', abbr: 'BND' },
    { name: 'Bulgarian lev', abbr: 'BGN' },
    { name: 'Burmese kyat', abbr: 'MMK' },
    { name: 'Burundian franc', abbr: 'BIF' },
    { name: 'Cambodian riel', abbr: 'KHR' },
    { name: 'Canadian dollar', abbr: 'CAD' },
    { name: 'Cape Verdean escudo', abbr: 'CVE' },
    { name: 'Central African CFA franc', abbr: 'XAF' },
    { name: 'Chilean peso', abbr: 'CLP' },
    { name: 'Chinese yuan', abbr: 'CNY' },
    { name: 'Colombian peso', abbr: 'COP' },
    { name: 'Comorian franc', abbr: 'KMF' },
    { name: 'Congolese franc', abbr: 'CDF' },
    { name: 'Costa Rican colón', abbr: 'CRC' },
    { name: 'Cuban peso', abbr: 'CUP' },
    { name: 'Czech koruna', abbr: 'CZK' },
    { name: 'Danish krone', abbr: 'DKK' },
    { name: 'Djiboutian franc', abbr: 'DJF' },
    { name: 'Dominican peso', abbr: 'DOP' },
    { name: 'East Caribbean dollar', abbr: 'XCD' },
    { name: 'Egyptian pound', abbr: 'EGP' },
    { name: 'Eritrean nakfa', abbr: 'ERN' },
    { name: 'Ethiopian birr', abbr: 'ETB' },
    { name: 'Fijian dollar', abbr: 'FJD' },
    { name: 'Gambian dalasi', abbr: 'GMD' },
    { name: 'Georgian lari', abbr: 'GEL' },
    { name: 'Ghanaian cedi', abbr: 'GHS' },
    { name: 'Guatemalan quetzal', abbr: 'GTQ' },
    { name: 'Guinean franc', abbr: 'GNF' },
    { name: 'Guyanese dollar', abbr: 'GYD' },
    { name: 'Haitian gourde', abbr: 'HTG' },
    { name: 'Honduran lempira', abbr: 'HNL' },
    { name: 'Hungarian forint', abbr: 'HUF' },
    { name: 'Icelandic króna', abbr: 'ISK' },
    { name: 'Indian rupee', abbr: 'INR' },
    { name: 'Indonesian rupiah', abbr: 'IDR' },
    { name: 'Iranian rial', abbr: 'IRR' },
    { name: 'Iraqi dinar', abbr: 'IQD' },
    { name: 'Israeli new shekel', abbr: 'ILS' },
    { name: 'Jamaican dollar', abbr: 'JMD' },
    { name: 'Japanese yen', abbr: 'JPY' },
    { name: 'Jordanian dinar', abbr: 'JOD' },
    { name: 'Kazakhstani tenge', abbr: 'KZT' },
    { name: 'Kenyan shilling', abbr: 'KES' },
    { name: 'Kuwaiti dinar', abbr: 'KWD' },
    { name: 'Kyrgyzstani som', abbr: 'KGS' },
    { name: 'Lao kip', abbr: 'LAK' },
    { name: 'Lebanese pound', abbr: 'LBP' },
    { name: 'Lesotho loti', abbr: 'LSL' },
    { name: 'Liberian dollar', abbr: 'LRD' },
    { name: 'Libyan dinar', abbr: 'LYD' },
    { name: 'Macedonian denar', abbr: 'MKD' },
    { name: 'Malagasy ariary', abbr: 'MGA' },
    { name: 'Malawian kwacha', abbr: 'MWK' },
    { name: 'Malaysian ringgit', abbr: 'MYR' },
    { name: 'Maldivian rufiyaa', abbr: 'MVR' },
    { name: 'Mauritanian ouguiya', abbr: 'MRO' },
    { name: 'Mauritian rupee', abbr: 'MUR' },
    { name: 'Mexican peso', abbr: 'MXN' },
    { name: 'Moldovan leu', abbr: 'MDL' },
    { name: 'Mongolian tögrög', abbr: 'MNT' },
    { name: 'Moroccan dirham', abbr: 'MAD' },
    { name: 'Mozambican metical', abbr: 'MZN' },
    { name: 'Namibian dollar', abbr: 'NAD' },
    { name: 'Nepalese rupee', abbr: 'NPR' },
    { name: 'New Taiwan dollar', abbr: 'TWD' },
    { name: 'New Zealand dollar', abbr: 'NZD' },
    { name: 'Nicaraguan córdoba', abbr: 'NIO' },
    { name: 'Nigerian naira', abbr: 'NGN' },
    { name: 'North Korean won', abbr: 'KPW' },
    { name: 'Norwegian krone', abbr: 'NOK' },
    { name: 'Omani rial', abbr: 'OMR' },
    { name: 'Pakistani rupee', abbr: 'PKR' },
    { name: 'Panamanian balboa', abbr: 'PAB' },
    { name: 'Papua New Guinean kina', abbr: 'PGK' },
    { name: 'Paraguayan guaraní', abbr: 'PYG' },
    { name: 'Peruvian sol', abbr: 'PEN' },
    { name: 'Philippine peso', abbr: 'PHP' },
    { name: 'Polish zloty', abbr: 'PLN' },
    { name: 'Qatari riyal', abbr: 'QAR' },
    { name: 'Romanian leu', abbr: 'RON' },
    { name: 'Rwandan franc', abbr: 'RWF' },
    { name: 'Samoan tala', abbr: 'WST' },
    { name: 'Saudi riyal', abbr: 'SAR' },
    { name: 'Serbian dinar', abbr: 'RSD' },
    { name: 'Seychellois rupee', abbr: 'SCR' },
    { name: 'Sierra Leonean leone', abbr: 'SLL' },
    { name: 'Singapore dollar', abbr: 'SGD' },
    { name: 'Solomon Islands dollar', abbr: 'SBD' },
    { name: 'Somali shilling', abbr: 'SOS' },
    { name: 'South African rand', abbr: 'ZAR' },
    { name: 'South Korean won', abbr: 'KRW' },
    { name: 'South Sudanese pound', abbr: 'SSP' },
    { name: 'Sri Lankan rupee', abbr: 'LKR' },
    { name: 'Sudanese pound', abbr: 'SDG' },
    { name: 'Surinamese dollar', abbr: 'SRD' },
    { name: 'Swazi lilangeni', abbr: 'SZL' },
    { name: 'Swedish krona', abbr: 'SEK' },
    { name: 'Swiss franc', abbr: 'CHF' },
    { name: 'Syrian pound', abbr: 'SYP' },
    { name: 'São Tomé and Príncipe dobra', abbr: 'STD' },
    { name: 'Tajikistani somoni', abbr: 'TJS' },
    { name: 'Tanzanian shilling', abbr: 'TZS' },
    { name: 'Thai baht', abbr: 'THB' },
    { name: "Tongan pa'anga", abbr: 'TOP' },
    { name: 'Trinidad and Tobago dollar', abbr: 'TTD' },
    { name: 'Tunisian dinar', abbr: 'TND' },
    { name: 'Turkish lira', abbr: 'TRY' },
    { name: 'Turkmenistan manat', abbr: 'TMT' },
    { name: 'Ugandan shilling', abbr: 'UGX' },
    { name: 'Ukrainian hryvnia', abbr: 'UAH' },
    { name: 'United Arab Emirates dirham', abbr: 'AED' },
    { name: 'Uruguayan peso', abbr: 'UYU' },
    { name: 'Uzbekistani som', abbr: 'UZS' },
    { name: 'Vanuatu vatu', abbr: 'VUV' },
    { name: 'Venezuelan bolívar', abbr: 'VEF' },
    { name: 'Vietnamese dong', abbr: 'VND' },
    { name: 'West African CFA franc', abbr: 'XOF' },
    { name: 'Yemeni rial', abbr: 'YER' },
    { name: 'Zambian kwacha', abbr: 'ZMW' },
];

export interface CreatedDto {
    type: ActivityType;
    by: RegisterUsersResCreatedByDto;
    at: number;
    updates?: Array<_UpdatedField>;
    message?: string;
}

export enum SourceType {
    DIRECT = 'direct',
    REFERRAL = 'referral',
}
