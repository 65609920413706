/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UpdateAssetsEventsReqItemDto } from './UpdateAssetsEventsReqItemDto';

export type UpdateAssetsEventsReqDto = {
    /**
     * Assets
     */
    items?: Array<UpdateAssetsEventsReqItemDto>;
    type: UpdateAssetsEventsReqDto.type;
};

export namespace UpdateAssetsEventsReqDto {

    export enum type {
        TOUR = 'tour',
    }


}
