import React, { useCallback, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { SearchSelect, useForm } from '@travelity/form';
import { Button } from '@travelity/ui';
import { array, object, string } from 'yup';
import { convertEnumToList } from '@travelity/utils';
import { Language } from '@travelity/api';

const schema = object().shape({
    languages: array()
        .of(string())
        .min(1, 'At least 1 language required')
        .required(),
});

export interface LanguageStepProps {
    languages?: string[];
    back: () => void;
    next: (v: string[]) => void;
}

export const LanguageStep: React.FC<LanguageStepProps> = props => {
    const { languages, next, back } = props;

    const [showError, setShowErrors] = React.useState(false);

    const { Form, watch } = useForm({
        schema,
        onSubmit: useCallback(
            (data: { languages: string[] }) => {
                next(data.languages);
            },
            [next]
        ),
        defaultValues: { languages },
        mode: 'onChange',
        validateInitially: true,
    });
    const languageOptions = useMemo(
        () => convertEnumToList<Language>(Language),
        []
    );

    return (
        <Form>
            <Stack
                sx={{
                    py: 5,
                    height: 1,
                    minHeight: 'calc(100vh - 84px)',
                }}
                gap={2}
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    sx={{
                        color: '#C0C4CE',
                        fontSize: '24px',
                    }}
                >
                    What languages do you speak?
                </Typography>
                <SearchSelect
                    placeholder="Select Language"
                    name="languages"
                    label="Languages"
                    multiple
                    options={languageOptions}
                    showErrors={showError}
                />
                <Stack direction="row" spacing={1} sx={{ width: '388px' }}>
                    <Button
                        variant="contained"
                        color="neutral"
                        sx={{ flexGrow: 1 }}
                        onClick={back}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                            px: 10,
                            py: 1,
                        }}
                        doNotDisable
                        onIconClick={() => {
                            setShowErrors(true);
                        }}
                        requiredFields={
                            !watch('languages')?.length
                                ? ['Languages']
                                : undefined
                        }
                    >
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
};
