import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { Box } from '@mui/material';
import {
    ScheduleOptionTypes,
    ScheduleItem,
    scheduleDtoToScheduleItem,
    useSchedules,
} from '@travelity/api';
import { LoadingOverlay } from '@travelity/ui';
import { GetSchedulesResDto } from '@travelity/api/src/requests';
import { ProductScheduleForm } from '../../../components/product-schedule-form';
import schema from '../../../components/product-schedule-form/product-schedule-form.schema';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper';
import { AddProductFormState } from '../add-product.types';
import { useHelperContext } from '../../../contexts/helper-context';

export interface ScheduleStepProps {}

const ScheduleStep: React.FC<ScheduleStepProps> = () => {
    const { state, next, showErrors } =
        useStepperContext<AddProductFormState>();

    const onSubmit = useCallback((data: Record<string, any>) => {
        next({ ...state, schedule: data });
    }, []);

    // const onSubmit = useCallback(
    //     (data: Record<string, any>) => {
    //         const newSchedules = data.items.filter(
    //             ({ scheduleId }: ScheduleItem) => !scheduleId
    //         );
    //
    //         if (newSchedules.length) {
    //             addSchedules(
    //                 {
    //                     requestBody: {
    //                         items: newSchedules.map(scheduleItemToSchedule),
    //                     },
    //                 },
    //                 {
    //                     onSuccess: ({ items }) => {
    //                         const schedules = items ? [...items] : [];
    //
    //                         try {
    //                             scheduleDtoToScheduleItem(schedules[0]);
    //                         } catch (e) {
    //                             console.error(e);
    //                         }
    //                         next({
    //                             ...state,
    //                             schedule: {
    //                                 ...data,
    //                                 items: data.items.map((s: ScheduleItem) =>
    //                                     s.scheduleId || !schedules.length
    //                                         ? s
    //                                         : scheduleDtoToScheduleItem(
    //                                               schedules.shift() as GetSchedulesResItemDto
    //                                           )
    //                                 ),
    //                             },
    //                         });
    //                     },
    //                 }
    //             );
    //         } else {
    //             next({ ...state, schedule: data });
    //         }
    //     },
    //     [next]
    // );

    const { Form, formState, setValue, watch } = useForm({
        onSubmit,
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: state.schedule,
    });
    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo();
    }, [setInfo]);

    useEffect(() => {
        const callback = () => {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}> Supported types</Box>
                    Travelity supports two types of product schedules - seasonal
                    and custom.
                    <br />
                    These types can be specified/used in a mix.
                </>
            );
        };
        const el = document.querySelector('.add-button');
        el?.addEventListener('click', callback);
        return () => el?.removeEventListener('focus', callback);
    }, []);

    const items = watch('items');
    useEffect(() => {
        const lastItem = items?.length ? items[items.length - 1] : undefined;
        if (lastItem?.isNew) {
            if (lastItem.type === ScheduleOptionTypes.SEASON) {
                setInfo(
                    <>
                        <Box sx={{ fontWeight: 'bold' }}>
                            Seasonal scheduling
                        </Box>
                        Season is a period of arbitrary days, during which
                        product has a specific recurrence and starting hours.
                        <br />
                        You can have as many seasons as you like.
                    </>
                );
            } else {
                setInfo(
                    <>
                        <Box sx={{ fontWeight: 'bold' }}>Custom dates</Box>
                        You can also select specific dates for one-time
                        occurrences of your product.
                    </>
                );
            }
        }
    }, [items]);

    const partialSchedules =
        items
            ?.filter((s: ScheduleItem) => !s.type)
            .map((c: ScheduleItem) => c.scheduleId) || [];
    const { isFetching } = useSchedules(partialSchedules, {
        enabled: !!partialSchedules.length,
        onSuccess: ({ items: its }: GetSchedulesResDto) => {
            const oldSchedules = watch('items');
            const newSchedules = oldSchedules.map((s: ScheduleItem) => {
                if (s.times?.length) return s;
                // @ts-ignore
                const item = its?.find(i => i.id === s.scheduleId);
                if (item) return scheduleDtoToScheduleItem(item);
                return s;
            });
            setValue('items', newSchedules);
        },
    });

    return (
        <StepContent title="Schedule" errors={errors}>
            <Form id="step">
                {!partialSchedules.length && (
                    <ProductScheduleForm showErrors={showErrors} />
                )}
                {isFetching && <LoadingOverlay />}
            </Form>
        </StepContent>
    );
};

export default React.memo(ScheduleStep);
