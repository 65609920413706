import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import {
    computeAvailabilityDtoToAvailability,
    // filterToRequestBodyConverter,
} from './availability.converters';
import { ComputeAvailabilityResMatrixObjItem0Dto } from '../../other/ComputeAvailabilityResMatrixObjItem0Dto';

import { AvailabilityFilterValues } from './availability.types';

export const useAvailability = (params: {
    requestBody: AvailabilityFilterValues;
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data, ...other } = useQuery({
        queryKey: [params?.requestBody],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return {};
            // return AvailabilityService.computeAvailability(
            //     authorization,
            //     filterToRequestBodyConverter(params?.requestBody)
            // );
        },
    });

    const parsedData = useMemo(() => {
        const allProducts = data?.[0]?.matrix?.products;
        return data?.[0]?.matrix
            ? Object.keys(data?.[0]?.matrix.events).map(key => {
                  const events = data?.[0]?.matrix.events?.[key];
                  const products: Record<string, any> = {};
                  events.forEach((event: any) => {
                      if (!products[event.product.id]) {
                          products[event.product.id] = {
                              ...allProducts[event.product.id],
                              events: [],
                          };
                      }
                      products[event.product.id].events.push(event);
                  });

                  return computeAvailabilityDtoToAvailability(
                      key,
                      Object.values(
                          products
                      ) as ComputeAvailabilityResMatrixObjItem0Dto[]
                  );
              })
            : undefined;
    }, [data]);
    // const parsedData: Availability[] = useMemo(
    //     () => generateAvailability(),
    //     []
    // );

    return {
        data: parsedData,
        ...other,
    };
};
