import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { Box } from '@mui/material';
import {
    CapacityItem,
    getCapacityDtoToCapacity,
    ProductCapacityItem,
    useCapacities,
} from '@travelity/api';
import { type GetCapacitiesResDto } from '@travelity/api/src/requests';
import { LoadingOverlay } from '@travelity/ui';
import { ProductCapacityForm } from '../../../components/product-capacity-form';
import schema from '../../../components/product-capacity-form/product-capacity-form.schema';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper';
import { AddProductFormState } from '../add-product.types';
import { useHelperContext } from '../../../contexts/helper-context';

export interface ProductCapacityStepProps {}

const ProductCapacityStep: React.FC<ProductCapacityStepProps> = () => {
    const { state, next, showErrors } =
        useStepperContext<AddProductFormState>();

    const onSubmit = useCallback((data: Record<string, any>) => {
        next({ ...state, capacity: data });
    }, []);

    const { Form, formState, setValue, watch } = useForm({
        onSubmit,
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: state.capacity,
    });
    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);

    const partialCapacities =
        watch('capacities')
            ?.filter((c: CapacityItem) => !c.type)
            .map((c: ProductCapacityItem) => c.capacityId) || [];
    const { isFetching } = useCapacities(partialCapacities, {
        enabled: !!partialCapacities.length,
        onSuccess: ({ items }: GetCapacitiesResDto) => {
            const oldCapacities = watch('capacities');
            const newCapacities = oldCapacities.map((c: CapacityItem) => {
                if (c.type) return c;
                const item = items?.find(i => i.id === c.capacityId);
                if (item) return getCapacityDtoToCapacity(item);
                return c;
            });
            setValue('capacities', newCapacities);
        },
    });

    // Handle helper texts
    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo(
            <>
                <Box sx={{ fontWeight: 'bold' }}>What is Capacity?</Box>
                A product can be booked by a limited number of people.
                <br />
                The upper limit is imposed by the maximum capacity of the car,
                yacht or the helicopter used to provide the service.
                <br />
                The lower limit is optionally set to prevent occurrence of
                non-profitable bookings.
                <br />
                Travelity provides a set of configurations to control the
                bookings of your vehicles.
            </>
        );
    }, [setInfo]);

    // const onInfoClick = () => {
    //     setInfo(
    //         <>
    //             <Box sx={{ fontWeight: 'bold' }}>Concurrency</Box>
    //             By enabling the Concurrency setting you let Travelity know, that
    //             you will be able to serve the product to multiple groups at the
    //             same time.
    //             <br />
    //             If the Auto setting is also enabled, Travelity will
    //             automatically schedule concurrent occurrences. It will ask for
    //             your confirmation otherwise.
    //             <Box sx={{ fontWeight: 'bold' }}>Upgrade</Box>
    //             When you provide more than one capacity option and enable
    //             Upgrade feature, Travelity learns that your Group Tour may be
    //             upgraded to a bigger vehicle when more bookings arrive. Min and
    //             Max capacity values will be utilized to make that decision.
    //             <br />
    //             Whether Travelity upgrades automatically or asks for
    //             confirmation, depends on the Auto setting.
    //             <Box sx={{ fontWeight: 'bold' }}>Combo controls</Box>
    //             When you set both Concurrency and the upgrade, Travelity always
    //             tries to make upgrades, when the upgrade of the vehicle’s
    //             capacity is not possible anymore, Travelity exercises the
    //             possibility of concurrency.
    //         </>
    //     );
    // };

    useEffect(() => {
        const callback = () => {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Capacity options</Box>
                    Travelity supports to types of capacity options - flat
                    counts and ageband counts.
                    <br />
                    The difference is that Ageband-based capacity sets the
                    limits on specific age groups.
                </>
            );
        };
        const el = document.querySelector('.add-button');
        el?.addEventListener('click', callback);
        return () => el?.removeEventListener('focus', callback);
    }, []);

    return (
        <StepContent title="Capacity" errors={errors}>
            <Form id="step">
                {!partialCapacities.length && (
                    <ProductCapacityForm showErrors={showErrors} />
                )}

                {isFetching && <LoadingOverlay />}
            </Form>
        </StepContent>
    );
};

export default React.memo(ProductCapacityStep);
