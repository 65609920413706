import React, { useMemo } from 'react';
import { MemberRole, Team } from '@travelity/api';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card, Heading } from '@travelity/ui';
import { format } from 'date-fns';
import TeamItemMinimalSkeleton from './team-item-minimal-skeleton';
import { useUserContext } from '../../../contexts/user';

export interface TeamItemProps {
    team: Team;
    isSelected: boolean;
    isDisabled: boolean;
    onSelect: () => void;
}

const TeamItemMinimal: React.FC<TeamItemProps> = ({
    team,
    isSelected,
    isDisabled,
    onSelect,
}) => {
    const { t } = useTranslation('product');
    const { user } = useUserContext();
    const me = useMemo(
        () => team.members.find(m => user?.id === m.id),
        [team, user]
    );
    const joinedDate = me?.joinedAt || team.createdAt;

    const leadMembers = team.members.filter(
        m => m.role === MemberRole.ADMINISTRATOR
    );
    const contributorMembers = team.members.filter(
        m => m.role === MemberRole.CONTRIBUTOR
    );
    const members = [];
    if (leadMembers.length > 0) {
        members.push(
            `${leadMembers.length} lead${leadMembers.length > 1 ? 's' : ''}`
        );
    }
    if (contributorMembers.length > 0) {
        members.push(
            `${contributorMembers.length} contributor${
                contributorMembers.length > 1 ? 's' : ''
            }`
        );
    }

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    mt: 1,
                    mb: 0.5,
                    ml: 4,
                    minWidth: 0,
                }}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    sx={{
                        minWidth: 0,
                    }}
                >
                    <Heading
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            pr: 1,
                            pl: 1,
                        }}
                        alpha={0.7}
                        ellipsis
                    >
                        {team.name}
                    </Heading>
                </Stack>
            </Stack>
            <Card
                flexGrow={2}
                sx={{
                    px: 2,
                    py: 1,
                }}
                direction="column"
                justifyContent="space-evenly"
                parentProps={{ alignItems: 'center' }}
                leftAdornment={
                    <Stack alignItems="center">
                        <Box
                            onClick={isDisabled ? undefined : onSelect}
                            sx={{
                                mr: '14px',
                                cursor: 'pointer',
                                width: '18px',
                                height: '18px',
                                borderStyle: 'solid',
                                borderWidth: isSelected ? '6px' : '2px',
                                borderColor: isSelected ? '#6B748C' : '#B5B9C5',
                                borderRadius: '4px',
                                ...(isDisabled && { opacity: 0.5 }),
                            }}
                        />
                    </Stack>
                }
            >
                {joinedDate && (
                    <Stack justifyContent="space-between" direction="row">
                        <Box
                            component="span"
                            sx={{
                                fontSize: 12,
                                color: '#989EAF',
                                pr: 1,
                            }}
                        >
                            {t('Joined at')}
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                fontSize: 12,
                                color: '#2B395B',
                                pr: 1,
                            }}
                        >
                            {format(joinedDate, 'd MMM, y')}
                        </Box>
                    </Stack>
                )}
                <Stack justifyContent="space-between" direction="row">
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#989EAF',
                            pr: 1,
                        }}
                    >
                        {t('Members')}
                    </Box>
                    <Box
                        component="span"
                        sx={{
                            fontSize: 12,
                            color: '#2B395B',
                            pr: 1,
                        }}
                    >
                        {members.join(' | ')}
                    </Box>
                </Stack>
            </Card>
        </Box>
    );
};

export { TeamItemMinimalSkeleton };

export default TeamItemMinimal;
