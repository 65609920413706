import React, { useMemo, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    Box,
    ClickAwayListener,
    Divider,
    InputAdornment,
    List,
    MenuItem as MuiMenuItem,
    Paper,
    Popper,
    SelectProps as MuiSelectProps,
    Stack,
} from '@mui/material';
import { get } from 'lodash';
import MuiTextField from '@mui/material/TextField';
import { useDebouncedStaff, Staff } from '@travelity/api';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { TagListItem } from '@travelity/ui';
import Transitions from '../transitions/transitions';

// import CreateStaffModal from './components/create-asset-modal';

export interface SelectStaffOption {
    value: string;
    label: string;

    [key: string]: any;
}

export interface SelectStaffProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    showErrors?: boolean;
    MenuItem?: React.FC<any>;
    teamId: string;
    existingEmails: string[];
}

const DefaultMenuItem: React.FC<Staff> = props => {
    const { fullName, email } = props;
    return (
        <Stack
            sx={{
                lineHeight: '12px',
                px: '8px',
                width: 1,
                flexWrap: 'nowrap',
            }}
            direction="row"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    py: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {fullName || '-'}
            </Box>
            <TagListItem
                sx={{ m: 0, cursor: 'pointer' }}
                label={email || '-'}
            />
        </Stack>
    );
};

// const CreateNewMenuItem: React.FC = () => {
//     return (
//         <Box
//             sx={{
//                 width: '100%',
//                 color: '#36869C',
//                 fontSize: '14px',
//                 lineHeight: '14px',
//                 textAlign: 'center',
//                 textDecorationLine: 'underline',
//             }}
//         >
//             Add Staff
//         </Box>
//     );
// };

const StyledSpinner = styled(RefreshIcon)(() => ({
    animation: `nfLoaderSpin infinite 700ms linear`,
    transformBox: 'fill-box',
    color: '#B2B9CD',

    '@keyframes nfLoaderSpin': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

const SelectStaff: React.FC<SelectStaffProps> = ({
    width,
    size,
    name,
    required,
    label,
    placeholder,
    showErrors,
    MenuItem = DefaultMenuItem,
    teamId,
    existingEmails,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: undefined,
    });

    // const [createNew, setCreateNew] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const { data, isLoading } = useDebouncedStaff(searchText, teamId);
    const staff = data?.filter(d => !existingEmails.includes(d.email));
    // console.log(assets, isLoading);

    // const onClose = useCallback((e: SyntheticEvent<Element, Event>) => {
    //     if ((e.target as Element).id === 'search') return;
    //     setOpen(false);
    //     setSearchText('');
    // }, []);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if (
            inputRef.current &&
            inputRef.current.contains(event.target as Node)
        ) {
            // setOpen(false);
        }
        // setOpen(false);
    };

    // const onChange = useCallback(
    //     (value: string) => {
    //         if (multiple) {
    //             field.onChange(xor(field.value, [value]));
    //         } else {
    //             field.onChange(value);
    //             setOpen(false);
    //             setSearchText('');
    //         }
    //     },
    //     [field, multiple]
    // );

    // const { mutate: createStaff } = useCreateStaff();
    // useEffect(() => {
    //     const t = setTimeout(() => {}, 1000);
    //
    //     return () => clearTimeout(t);
    // }, [createStaff]);

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);

    const selectedStaff = useMemo(() => {
        return value ? `${value.fullName || value.email}` : undefined;
    }, [value]);

    // const isSelected = useMemo(() => {
    //     return (
    //         searchText.match(/^\+?[0-9 ]+$/) ||
    //         (searchText.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/) &&
    //             assets?.length === 1)
    //     );
    // }, [searchText, assets]);

    const isOpen = open && !!searchText && !!staff;

    return (
        <>
            <MuiTextField
                margin="dense"
                size={size}
                InputLabelProps={{
                    required: !!required,
                    shrink: !!placeholder,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading && <StyledSpinner />}
                            {!!value && (
                                <CheckCircleIcon sx={{ color: '#37C16E' }} />
                            )}
                        </InputAdornment>
                    ),
                }}
                ref={inputRef}
                label={label}
                placeholder={placeholder}
                value={selectedStaff || searchText}
                onChange={e => {
                    onChange(undefined);
                    setSearchText(e.target.value);
                }}
                type="text"
                error={!!error}
                helperText={error}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                sx={{
                    m: 0,
                    width: width || '388px',
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&& .MuiOutlinedInput-notchedOutline': {
                        ...(isOpen && {
                            borderBottom: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                    },
                }}
            />

            <Popper
                placement="bottom-end"
                open={isOpen}
                anchorEl={inputRef.current}
                role={undefined}
                transition
                disablePortal
                sx={{ zIndex: 100 }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                borderRadius: '24px',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                border: '1px solid #DFE1ED',
                                borderTop: 0,
                                background: '#FFF',
                                minHeight: '80px',
                                width: inputRef.current?.clientWidth,
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        borderRadius: '24px',
                                        py: 0.5,
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5,
                                        },
                                    }}
                                >
                                    <Divider sx={{ mx: 2 }} />
                                    {staff?.map((item, i) => (
                                        <>
                                            {i !== 0 && <Divider />}
                                            <MuiMenuItem
                                                sx={{
                                                    fontSize: '12px',
                                                    color: '#2B395B',
                                                    borderRadius: '8px',
                                                    py: '4px',
                                                    px: '4px',
                                                    mx: 2,
                                                    my: 1,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#EAF2F4',
                                                    },
                                                }}
                                                key={item.id}
                                                onClick={() => onChange(item)}
                                            >
                                                <MenuItem {...item} />
                                            </MuiMenuItem>
                                        </>
                                    ))}
                                    {staff && !staff.length && (
                                        <Box
                                            sx={{
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: '12px',
                                                color: '#C0C4CE',
                                                m: 2,
                                                px: 1,
                                            }}
                                        >
                                            No staff found
                                        </Box>
                                    )}
                                    {/* <MuiMenuItem */}
                                    {/*    sx={{ */}
                                    {/*        fontSize: '12px', */}
                                    {/*        color: '#2B395B', */}
                                    {/*        borderRadius: '8px', */}
                                    {/*        textAlign: 'center', */}
                                    {/*        py: '4px', */}
                                    {/*        px: '4px', */}
                                    {/*        mx: 2, */}
                                    {/*        my: 1, */}
                                    {/*        '&:hover': { */}
                                    {/*            backgroundColor: 'transparent', */}
                                    {/*        }, */}
                                    {/*    }} */}
                                    {/*    onClick={() => setCreateNew(true)} */}
                                    {/* > */}
                                    {/*    <CreateNewMenuItem /> */}
                                    {/* </MuiMenuItem> */}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            {/* <CreateStaffModal */}
            {/*    handleCancel={() => setCreateNew(false)} */}
            {/*    handleConfirm={(asset: Staff) => { */}
            {/*        onChange(asset); */}
            {/*        setCreateNew(false); */}
            {/*    }} */}
            {/*    searchText={searchText} */}
            {/*    open={createNew} */}
            {/* /> */}
        </>
    );
};

export default SelectStaff;
