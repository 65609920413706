import { useController, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    InputProps as MuiInputProps,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select as MuiSelect,
    SelectChangeEvent,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import { minutesToTime } from '@travelity/utils';

export interface SelectTimesProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    InputProps?: MuiInputProps;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const SelectTimes: React.FC<SelectTimesProps> = ({
    width,
    size,
    name,
    required,
    label,
    placeholder,
    InputProps = {},
    ...selectProps
}) => {
    const {
        control,
        // formState: { errors },
    } = useFormContext();
    const {
        field: { value: values, onChange },
    } = useController({
        name,
        control,
        defaultValue: [],
    });

    const options = Array.from(Array(4 * 24)).map((v, i) => {
        const minutes = i * 15;
        return {
            label: minutesToTime(minutes),
            value: minutes,
        };
    });

    const handleChange = (event: SelectChangeEvent<typeof values>) => {
        const {
            target: { value },
        } = event;
        const values =
            // On auto-fill we get a string value.
            typeof value === 'string' ? value.split(',') : value;
        values.sort();
        onChange(values);
    };

    return (
        <FormControl sx={{ width: 1 }}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                multiple
                value={values}
                name={name}
                onChange={handleChange}
                input={<OutlinedInput label={label} {...InputProps} />}
                displayEmpty
                renderValue={selected => {
                    if (selected.length === 0 && placeholder) {
                        return (
                            <Box
                                component="span"
                                sx={{
                                    color: '#949BAC',
                                }}
                            >
                                {placeholder}
                            </Box>
                        );
                    }
                    return selected
                        .map((m: number) => minutesToTime(m))
                        .join(', ');
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                        },
                    },
                    disableAutoFocusItem: true,
                }}
                {...selectProps}
                sx={{
                    ...(selectProps.sx || {}),
                    '&& .MuiSelect-select': { whiteSpace: 'wrap' },
                }}
            >
                {options.map(({ label: l, value }) => (
                    <MenuItem key={value} value={value}>
                        <Checkbox checked={values.indexOf(value) > -1} />
                        <ListItemText primary={l} />
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

export default SelectTimes;
