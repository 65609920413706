import _ from 'lodash';
import { getTimezoneOffset } from 'date-fns-tz';
import {
    type GetTeamsResItemCreatedDto,
    ListItemAction,
    PeriodData,
    PhoneNumberData,
    TimeOffset,
    UpdateUserResLastUpdatedDto,
} from '../requests';
import { Activity, DurationValue, Period } from './common.types';
import { capitalizeFirstLetter } from '../../generator/common';

// Duration
export const convertDurationToDurationDto = (d: DurationValue): TimeOffset => ({
    days: d.day || 0,
    hours: d.hour || 0,
    minutes: d.minute || 0,
});

export const convertDurationDtoToDuration = (
    d: Partial<TimeOffset>
): DurationValue => ({
    day: d.days || 0,
    hour: d.hours || 0,
    minute: d.minutes || 0,
});

export const getStatusChangeReason = (
    lifecycle: GetTeamsResItemCreatedDto[] = [],
    status: string = ''
): string => {
    return (
        lifecycle?.findLast(l =>
            l.updates?.some(
                ({ name, value }) => name === 'status' && value === status
            )
        )?.message || ''
    );
};

export const isOrderChanged = (oldIds: string[], newIds: string[]) => {
    const hasNewInMiddle = newIds.reduce(
        (prevNew, id, i) =>
            prevNew ||
            (!!i && oldIds.includes(id) && !oldIds.includes(newIds[i - 1])),
        false
    );
    if (hasNewInMiddle) return true;

    const commonItems = oldIds.filter(id => newIds.includes(id));
    const commonItemsSameOrder = commonItems.length
        ? commonItems.reduce(
              (prevIndex, id) =>
                  newIds.indexOf(id) > prevIndex
                      ? newIds.indexOf(id)
                      : newIds.length,
              -1
          ) < newIds.length
        : true;

    return !commonItemsSameOrder;
};

export const convertTimeOffsetToMinutes = (timeOffset: TimeOffset): number => {
    return timeOffset.hours * 60 + (timeOffset.minutes || 0);
};

export const convertItemsToActionItemsDto = <T extends Record<string, any>>(
    oldItems: T[],
    newItems: T[],
    idPath: string,
    isUpdated: (oldItem: T, newItem: T) => boolean = () => false
): ({ action: ListItemAction } & T)[] => {
    const orderChanged = isOrderChanged(
        oldItems.map(item => _.get(item, idPath)),
        newItems.map(item => _.get(item, idPath))
    );

    if (orderChanged) {
        return newItems.map(item => ({
            ...item,
            action: ListItemAction.REORDER,
        }));
    }

    const addedItems = newItems.filter(
        newItem =>
            !oldItems.find(
                oldItem => _.get(oldItem, idPath) === _.get(newItem, idPath)
            )
    );

    const removedItems = oldItems.filter(
        oldItem =>
            !newItems.find(
                newItem => _.get(newItem, idPath) === _.get(oldItem, idPath)
            )
    );

    const updatedItems = newItems.filter(newItem =>
        oldItems.find(
            oldItem =>
                _.get(newItem, idPath) === _.get(oldItem, idPath) &&
                isUpdated(oldItem, newItem)
        )
    );

    return [
        ...addedItems.map(item => ({
            ...item,
            action: ListItemAction.ADD,
        })),
        ...removedItems.map(item => ({
            ...item,
            action: ListItemAction.REMOVE,
        })),
        ...updatedItems.map(item => ({
            ...item,
            action: ListItemAction.UPDATE,
        })),
    ];
};

const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const offset = getTimezoneOffset(localTz);
export const convertDateToTimestamp = (d: Date): number => {
    return Math.round((d.getTime() + offset) / 1000);
};

export const convertTimestampToDate = (d: number): Date => {
    return new Date(d * 1000);
};

export const convertPeriodDataToPeriod = (data: PeriodData): Period => ({
    timezone: data.timezone?.name,
    duration: convertDurationDtoToDuration(data.duration || {}),
    start: convertTimestampToDate(data.start),
    end: data.end ? convertTimestampToDate(data.end) : undefined,
});

export const convertPeriodToPeriodData = (period: Period): PeriodData => ({
    duration: convertDurationToDurationDto(period.duration),
    start: convertDateToTimestamp(period.start),
    end: period.end ? convertDateToTimestamp(period.end) : undefined,
});

export const convertActivityDtoToActivity = (
    activity: GetTeamsResItemCreatedDto | UpdateUserResLastUpdatedDto
): Activity => ({
    type: activity.type,
    name: activity.by.name,
    date: convertTimestampToDate(activity.at),
    updates:
        activity.updates
            ?.filter(({ name }) => !!name)
            .map(({ name }) =>
                name
                    .split('.')
                    .map(n => capitalizeFirstLetter(n.replaceAll('_', ' ')))
                    .join('➝')
            ) || [],
    message: activity.message,
});

export const phoneNumberDataToPhoneNumber = (
    phoneNumber: PhoneNumberData
): string => {
    return `+${phoneNumber.calling_code} ${phoneNumber.number}`;
};
