import React, { useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    Booking,
    bookingFiltersToRequestBodyConverter,
    useBookingsLazy,
} from '@travelity/api';
import { BookingListItem } from './components/booking-list-item';

import { BookingPreview } from '../../components/booking-preview';
import { BookingListItemSkeleton } from './components/booking-list-item-skeleton';
import { Filters } from '../../components/filters';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
    ListTitle,
} from '../../components/list-layout';
import { useLoadOnScroll } from '../../hooks';
import { SidebarContent } from '../../components/sidebar-content';

const bookingFilters: FilterOption[] = [
    {
        name: 'customerName',
        label: 'Customer',
        type: FilterTypes.SEARCH,
        selectText: 'Please type customer name to search',
    },
];

function Bookings() {
    const { bookingId } = useParams();

    const [filters, setFilters] = useState({});

    const {
        data: bookings,
        isLoading,
        refetch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useBookingsLazy(
        useMemo(() => bookingFiltersToRequestBodyConverter(filters), [filters])
    );

    const booking = useMemo(() => {
        return bookings && bookingId
            ? bookings?.find((b: Booking) => b.id === bookingId)
            : undefined;
    }, [bookings, bookingId]);

    const { onScroll } = useLoadOnScroll({
        hasNextPage: !!hasNextPage && !isFetchingNextPage,
        fetchNextPage,
    });

    return (
        <List>
            <ListMain
                isLoading={isLoading}
                SkeletonComponent={BookingListItemSkeleton}
            >
                <ListTitle title="All Bookings" />
                <Divider sx={{ mb: 0.25 }} />
                <Filters
                    values={filters}
                    setValues={setFilters}
                    options={bookingFilters}
                />
                <ListItems
                    noItemsText="Filter results will be here"
                    scrollbarProps={{
                        onScrollY: onScroll,
                    }}
                    items={bookings?.length}
                    isLoading={isLoading}
                    entityName="booking"
                    entityNamePlural="bookings"
                    subtractHeight={72 + 1 + 60}
                >
                    {bookings?.map(item => (
                        <BookingListItem
                            key={item.id}
                            booking={item}
                            refetch={refetch}
                            isSelected={item.id === bookingId}
                        />
                    ))}
                    {hasNextPage && <BookingListItemSkeleton />}
                </ListItems>
            </ListMain>
            <ListSidebar
                isEmpty={!booking}
                placeholderText="No booking is selected for preview"
            >
                <SidebarContent title="Booking Preview" isLoading={isLoading}>
                    {booking && (
                        <BookingPreview
                            booking={booking}
                            siblingBookings={[]}
                        />
                    )}
                </SidebarContent>
            </ListSidebar>
        </List>
    );
}

export default Bookings;
