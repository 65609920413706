import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, useForm } from '@travelity/form';
import { Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import {
    convertFullNameToNameDto,
    Customer,
    getCustomerDtoToCustomer,
    PhoneNumberData,
} from '@travelity/api';
import { useCreateCustomer } from '@travelity/api/src/queries';

import { useSnackbar } from 'notistack';
import schema from './create-customer.schema';
import { useIsChanged } from '../../../hooks';

const formId = 'create-customer-modal';

export interface CreateCustomerModalProps {
    handleCancel: () => void;
    handleConfirm: (v: Customer) => void;
    open: boolean;
    searchText?: string;
}

interface CreateCustomer {
    fullName: string;
    phoneNumber: string;
    email: string;
}

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
    handleCancel,
    handleConfirm,
    open,
    searchText,
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: createCustomer } = useCreateCustomer({
        onError: () => {
            enqueueSnackbar('Failed to create the customer', {
                variant: 'error',
            });
        },
    });
    const {
        Form,
        reset,
        watch,
        trigger,
        formState: { errors },
    } = useForm<CreateCustomer>({
        schema,
        isModal: true,
        mode: 'onChange',
        validateInitially: true,
        onSubmit: useCallback(
            (data: CreateCustomer) => {
                const phoneNumbers: PhoneNumberData[] = [];
                if (data.phoneNumber) {
                    const [code, number] = data.phoneNumber.split(' ');
                    phoneNumbers.push({
                        calling_code: parseInt(code.replace('+', ''), 10) || 0,
                        number: parseInt(number.replace(/ /g, ''), 10) || 0,
                    });
                }
                const emails: string[] = [];
                if (data.email) {
                    emails.push(data.email);
                }

                createCustomer(
                    {
                        requestBody: {
                            contact_details: {
                                emails,
                                phone_numbers: phoneNumbers,
                            },
                            name: convertFullNameToNameDto(data.fullName),
                        },
                    },
                    {
                        onSuccess: customer => {
                            handleConfirm(getCustomerDtoToCustomer(customer));
                        },
                    }
                );
            },
            [navigate]
        ),
    });

    const openChange = useIsChanged(open);
    useEffect(() => {
        if (openChange && open) {
            if (searchText?.match(/^[^@]+@[^@.]+\.[a-zA-Z]+$/))
                reset({ email: searchText });
            else if (searchText?.match(/ /)) {
                reset({
                    fullName: searchText,
                });
            } else {
                reset({});
            }
            trigger();
        } else if (openChange && !open) {
            reset({});
        }
    }, [searchText, open, reset, trigger, openChange]);

    const phoneNumberChange = useIsChanged(watch('phoneNumber'));
    useEffect(() => {
        if (phoneNumberChange) {
            trigger('email');
        }
    }, [phoneNumberChange]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            sx={{
                '& .MuiDialog-container': { justifyContent: 'end' },
            }}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff', maxWidth: '388px' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                Add Customer
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    '& > div': {
                        pr: 2,
                        maxHeight: 'calc(100vh - 212px)',
                    },
                }}
            >
                <PerfectScrollbar>
                    <Form id={formId}>
                        <Stack
                            direction="column"
                            gap={2}
                            sx={{ pt: 2, width: '340px' }}
                        >
                            <TextField
                                placeholder="Type Full Name"
                                name="fullName"
                                label="Full Name"
                                fullWidth
                            />
                            <TextField
                                placeholder="Type Phone Number"
                                name="phoneNumber"
                                label="Phone Number"
                                regExp={/^\+?([0-9 ]+)?$/}
                                showErrors
                                fullWidth
                            />
                            <TextField
                                placeholder="Type email"
                                name="email"
                                label="Email"
                                showErrors
                                fullWidth
                            />
                        </Stack>
                    </Form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Discard
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    disabled={!!errorFields.length}
                    sx={{
                        py: '10px',
                        '&&&': { pointerEvents: 'initial' },
                    }}
                >
                    Add Customer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateCustomerModal;
