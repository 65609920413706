import React, { useMemo, useState } from 'react';
import { xorBy } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MemberRole, UserGroupType, useTeams } from '@travelity/api';

import { Button, LoadingOverlay } from '@travelity/ui';
import TeamItemMinimal from './components/team-item-minimal';
import { TeamModal } from '../team-modal';
import { useUserContext } from '../../contexts/user';

export interface TeamsModalProps {
    handleCancel: () => void;
    handleConfirm: (v: { id: string; type: UserGroupType }[]) => void;
    open: boolean;
    selectedTeams: { id: string; type: UserGroupType }[];
    isLoading?: boolean;
}

const TeamsModal: React.FC<TeamsModalProps> = ({
    handleCancel,
    handleConfirm,
    open,
    selectedTeams,
    isLoading,
}) => {
    const [selected, setSelected] =
        React.useState<{ id: string; type: UserGroupType }[]>(selectedTeams);
    const { user } = useUserContext();

    const [teamOpen, setTeamOpen] = useState(false);

    const { data: teams, isFetching, refetch } = useTeams();
    const isChanged = useMemo(() => {
        return (
            selected
                .map(({ id }) => id)
                .sort()
                .join('-') !==
            selectedTeams
                .map(({ id }) => id)
                .sort()
                .join('-')
        );
    }, [selected, selectedTeams]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                PaperProps={{ sx: { py: 1, bgcolor: '#fff', width: '482px' } }}
            >
                <DialogTitle
                    sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        fontWeight: 600,
                        color: '#2B395B',
                    }}
                    id="alert-dialog-title"
                >
                    <Typography sx={{ fontWeight: 600 }}>
                        {teams && teams.length === 0
                            ? 'No teams found to share the product with'
                            : 'Select teams to share the product with'}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        overflow: 'hidden',
                        pr: 1,
                    }}
                >
                    <Box
                        sx={{
                            pr: 2,
                            height: 'calc(100% - 76px)',
                            '& > div': {
                                maxHeight: 'calc(100vh - 284px)',
                            },
                        }}
                    >
                        <PerfectScrollbar>
                            <Stack
                                sx={{
                                    pr: 0.5,
                                    pb: 1,
                                }}
                                gap={1}
                            >
                                {teams?.map(team => (
                                    <TeamItemMinimal
                                        key={team.id}
                                        team={team}
                                        isSelected={
                                            !!selected.find(
                                                s => s.id === team.id
                                            )
                                        }
                                        onSelect={() =>
                                            setSelected(
                                                xorBy(
                                                    selected,
                                                    [
                                                        {
                                                            id: team.id,
                                                            type: UserGroupType.TEAM,
                                                        },
                                                    ],
                                                    'id'
                                                )
                                            )
                                        }
                                        isDisabled={
                                            team.members.find(
                                                m => m.id === user?.id
                                            )?.role === MemberRole.CONTRIBUTOR
                                        }
                                    />
                                ))}
                            </Stack>
                        </PerfectScrollbar>
                    </Box>
                    {(isFetching || isLoading) && (
                        <LoadingOverlay
                            title="Please wait..."
                            subTitle="It’ll just take a moment."
                        />
                    )}
                </DialogContent>
                <DialogActions
                    sx={{ px: 3, py: 2, justifyContent: 'space-between' }}
                >
                    <Button
                        sx={{
                            bgcolor: '#FFFFFF',
                            py: '10px',
                        }}
                        variant="outlined"
                        onClick={() => setTeamOpen(true)}
                    >
                        New Team
                    </Button>
                    <Stack direction="row" gap={1}>
                        <Button
                            color="neutral"
                            variant="contained"
                            onClick={handleCancel}
                            sx={{
                                px: '26px',
                                py: '10px',
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={isFetching || isLoading || !isChanged}
                            onClick={() => {
                                handleConfirm(selected);
                            }}
                            sx={{
                                py: '10px',
                                px: 4,
                            }}
                        >
                            Confirm
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            {teamOpen && (
                <TeamModal
                    handleCancel={created => {
                        setTeamOpen(false);
                        if (created) refetch();
                    }}
                    open={teamOpen}
                />
            )}
        </>
    );
};

export default TeamsModal;
