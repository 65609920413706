/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnterpriseSubscriptionData } from './EnterpriseSubscriptionData';
import type { FreeSubscriptionData } from './FreeSubscriptionData';
import type { ProfessionalSubscriptionData } from './ProfessionalSubscriptionData';
import type { RegisterUsersResAccessDto } from './RegisterUsersResAccessDto';
import type { RegisterUsersResAccountDto } from './RegisterUsersResAccountDto';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';
import type { RegisterUsersResProfileDto } from './RegisterUsersResProfileDto';
import type { ResourceType } from './ResourceType';
import type { TeamSubscriptionData } from './TeamSubscriptionData';

export type RegisterUsersResDto = {
    /**
     * Type of the resource
     */
    resource_type: ResourceType;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * Type of the user
     */
    type: RegisterUsersResDto.type;
    /**
     * Account details of the user
     */
    account: RegisterUsersResAccountDto;
    /**
     * Access to the shared product
     */
    access: RegisterUsersResAccessDto;
    /**
     * Profile details of the user
     */
    profile: RegisterUsersResProfileDto;
    /**
     * Memberships of the user
     */
    subscription: (FreeSubscriptionData | ProfessionalSubscriptionData | TeamSubscriptionData | EnterpriseSubscriptionData);
};

export namespace RegisterUsersResDto {

    /**
     * Type of the user
     */
    export enum type {
        HUMAN = 'human',
    }


}
