/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ComputePriceBookingsReqSource1ReferenceOrganizationContactDetailsDto } from './ComputePriceBookingsReqSource1ReferenceOrganizationContactDetailsDto';

export type ComputePriceBookingsReqSource1ReferenceOrganizationDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type: ComputePriceBookingsReqSource1ReferenceOrganizationDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: ComputePriceBookingsReqSource1ReferenceOrganizationContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: string;
};

export namespace ComputePriceBookingsReqSource1ReferenceOrganizationDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        ORGANIZATION = 'organization',
    }


}
