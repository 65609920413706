/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetEventResBookingsItemSource1ReferenceDto } from './GetEventResBookingsItemSource1ReferenceDto';

export type GetEventResBookingsItemSource1Dto = {
    /**
     * type of the source
     */
    type: GetEventResBookingsItemSource1Dto.type;
    /**
     * List of customers
     */
    reference: GetEventResBookingsItemSource1ReferenceDto;
};

export namespace GetEventResBookingsItemSource1Dto {

    /**
     * type of the source
     */
    export enum type {
        REFERRAL = 'referral',
    }


}
