import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { User } from '@travelity/api';

export interface IUserContext {
    user?: User;
}

export interface HelperProviderProps {
    user?: User;
    children: ReactNode;
}

const UserContext = createContext<IUserContext>({});

export function useUserContext() {
    return useContext(UserContext) as IUserContext;
}

export function useResourceType(resourceType: string) {
    const { user } = useContext(UserContext) as IUserContext;
    return useMemo(() => {
        return !!user?.visibleResources?.includes(resourceType);
    }, [user?.visibleResources]);
}

export function useResources() {
    const { user } = useContext(UserContext) as IUserContext;
    return user?.visibleResources || [];
}

function UserProvider({ children, user }: HelperProviderProps) {
    const context = useMemo(
        () => ({
            user,
        }),
        [user]
    );

    return (
        <UserContext.Provider value={context}>{children}</UserContext.Provider>
    );
}

export default UserProvider;
