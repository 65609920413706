/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ComputePriceBookingsReqAccessGroupsItemReference0Dto = {
    /**
     * Type of the user group
     */
    type: ComputePriceBookingsReqAccessGroupsItemReference0Dto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
};

export namespace ComputePriceBookingsReqAccessGroupsItemReference0Dto {

    /**
     * Type of the user group
     */
    export enum type {
        INDIVIDUAL = 'individual',
    }


}
